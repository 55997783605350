import _ from "lodash";
import { notEmpty } from "../util";

const DEFAULT_CUSTOMIZATION_NAME = 'default';
export const FREE_TEXT_ENTRY_LABEL = '(Custom, free text entry)';
export const MVSEG_MODEL = 'mvseg';


export type PredictionModel = {
    bodyPart: string;
    label: string;
    id: string;
    modelName: string;
    customizationName: string;
    type: string;
    tags: string[];
    isDeprecated: boolean;
    isAvailable: boolean; // as in is available in user's license. Currently only available models are being sent to IEUI so this is always true
    isHidden: boolean;
    isAlwaysShown: boolean;
    isOnline: boolean;
    isFreeTextEntryModel: boolean;
    modelDescription?: string;
    customizationDescription?: string;
    includedRois: string[];
}

export type AvailableModels = {
    allowTextEntry: boolean;
    models: PredictionModel[];
}

/** A "virtual"/"fake" model entry that's used to select the actual model based on DICOM attribute rules */
export const MVSEG_PREDICTION_MODEL: PredictionModel = {
    id: MVSEG_MODEL,
    modelName: MVSEG_MODEL,
    customizationName: DEFAULT_CUSTOMIZATION_NAME,
    type: 'segmentation',
    bodyPart: '',
    label: MVSEG_MODEL,
    tags: [],
    isOnline: true,
    isFreeTextEntryModel: false,
    isDeprecated: false,
    isAvailable: true,
    isHidden: false,
    isAlwaysShown: false,
    modelDescription: undefined,
    customizationDescription: undefined,
    includedRois: [],
}

/** A model entry that allows user to freely enter in the model (and customization) name to use */
export const getFreeTextEntryModel = (entry: string): PredictionModel => ({
    id: FREE_TEXT_ENTRY_LABEL,
    modelName: entry,
    customizationName: DEFAULT_CUSTOMIZATION_NAME,
    type: 'segmentation',
    bodyPart: '',
    label: entry,
    tags: [],
    isOnline: true,
    isFreeTextEntryModel: true,
    isDeprecated: false,
    isAvailable: true,
    isHidden: false,
    isAlwaysShown: false,
    modelDescription: undefined,
    customizationDescription: undefined,
    includedRois: [],
});

export function convertPredictionModelDtoToViewModel(src: any, onlineModelNames?: string[]): PredictionModel[] | undefined {
    if (_.has(src, 'body_part')
        && _.has(src, 'model_label')
        && _.has(src, 'model_name')
        && _.has(src, 'model_type')
        && _.has(src, 'model_tags')
        && _.has(src, 'customization_names')
        && _.isArray(src['model_tags'])
        && _.isArray(src['customization_names'])
    ) {
        const modelName = src['model_name'];
        const isOnline = onlineModelNames ? onlineModelNames.includes(modelName) : false;
        const models: PredictionModel[] = [];
        for (const customizationName of src['customization_names']) {

            models.push({
                id: `${modelName}${customizationName === DEFAULT_CUSTOMIZATION_NAME ? '' : `.${customizationName}`}`,
                modelName: modelName,
                customizationName: customizationName,
                bodyPart: src['body_part'],
                label: `${src['model_label']}${customizationName === DEFAULT_CUSTOMIZATION_NAME ? '' : ` (${customizationName})`}`,
                type: src['model_type'],
                tags: src['model_type'],
                isOnline: isOnline,
                isFreeTextEntryModel: false,
                isDeprecated: _.get(src, 'deprecated', false),
                isAvailable: true,
                isHidden: _.get(src, 'visibility', '') === 'always_hide',
                isAlwaysShown: _.get(src, 'visibility', '') === 'always_show',
                modelDescription: undefined,
                customizationDescription: undefined,
                includedRois: [],
            });
        }
        models.sort((a, b) => a.label.localeCompare(b.label));
        return models;
    } else {
        return undefined;
    }
}

export function convertAvailableModelsDtoToViewModel(src: any, allowTextEntry?: boolean): AvailableModels | undefined {
    if (_.has(src, 'user_model_details')
        && _.has(src, 'online_model_names')
        && _.isArray(src['user_model_details'])
        && _.isArray(src['online_model_names'])
    ) {
        const models = src['user_model_details'].map(m =>
            convertPredictionModelDtoToViewModel(m, src['online_model_names']))
            .filter(notEmpty).flat();
        return {
            allowTextEntry: allowTextEntry || false,
            models: models,
        }
    } else {
        return undefined;
    }
}

export function updateAvailableModelsWithCustomizationData(originalModels: AvailableModels, customizationJson: any[]) {

    // fail silently if a proper customization json wasn't given
    if (!customizationJson || !_.isArray(customizationJson)) {
        return originalModels;
    }

    const models = _.cloneDeep(originalModels);

    for (const model of models.models) {
        const roiNamesSet: Set<string> = new Set();
        const modelEntry = customizationJson.find(c => _.has(c, 'model_name') && c['model_name'] === model.modelName);

        // get model description
        if (modelEntry) {
            model.modelDescription = _.get(modelEntry, 'description', undefined);
            if (_.isArray(modelEntry['customizations'])) {

                // get customization description
                const customizationEntry = modelEntry['customizations'].find(c => _.has(c, 'customization_name') && c['customization_name'] === model.customizationName);
                if (customizationEntry) {
                    model.customizationDescription = _.get(customizationEntry, 'description', undefined);
                }

                // get rois
                if (_.isArray(customizationEntry['files'])) {
                    for (const file of customizationEntry['files']) {
                        if (_.isArray(file['rois'])) {
                            const roiNames = file['rois'].filter(r => _.has(r, 'included') && _.has(r, 'name') && r['included'] === true).map(r => r['name']);
                            roiNames.forEach(n => roiNamesSet.add(n));
                        }
                    }

                    if (roiNamesSet.size > 0) {
                        model.includedRois = Array.from(roiNamesSet).sort();
                    }
                }
            }
        }
    }

    return models;
}
