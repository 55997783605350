/* 

A toolbar with some buttons that are supposed to have only one instance in the UI. Despite the name this can be placed anywhere, multiple times, although you
should make sure not to actually show more than once at a time to user to avoid confusion.

Usually this sort of functionality would be handled with a single main toolbar in the root layout file. This implementation allows us to also have this
global toolbar in RT Viewer without it taking an extra row of vertical space like a more traditional main toolbar would.

*/

import React from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, Button, Dropdown } from 'react-bootstrap';
import { IoMdSettings, IoMdHelpCircleOutline, IoMdApps } from 'react-icons/io';
import { IconContext } from 'react-icons';

import './GlobalToolbar.css';
import { StoreState } from '../../store/store';
import * as sagas from '../../store/sagas';
import { User } from '../../store/user';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { MdApps } from 'react-icons/md';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';

type OwnProps = {
    // if the toolbar is NOT embedded, render some extra styles over it to position it correctly. Otherwise assume the containing component handles it.
    isEmbedded?: boolean
};

type DispatchProps = {
    setBatchJobPaneVisibility(value: boolean): void,
    setUserSettingsDialogVisibility(value: boolean): void,
    setHelpDialogVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class GlobalToolbar extends React.PureComponent<AllProps>{
    handleViewBatchJobsClick = () => {
        this.props.setBatchJobPaneVisibility(true);
    }

    handleViewSettingsClick = () => {
        this.props.setUserSettingsDialogVisibility(true);
    }

    handleViewHelpClick = () => {
        this.props.setHelpDialogVisibility(true);
    }

    handleViewDashboardClick = (evt: any) => {
        // open dashboard on a new tab
        if (this.props.deploymentConfigInfo) {
            window.open(this.props.deploymentConfigInfo.dashboardUrl, '_blank');
            evt.preventDefault();
        }
    }

    handleViewConfigUIClick = (evt: any) => {
        // open config UI on a new tab
        if (this.props.deploymentConfigInfo) {
            window.open(this.props.deploymentConfigInfo.configUIUrl, '_blank');
            evt.preventDefault();
        }
    }

    render() {
        const { deploymentConfigInfo } = this.props;

        // TODO: when the value of rtViewerApiClient.isMVisionUser() changes, React won't trigger an update (because it doesn't know anything about it)
        // --> this value should be read from store
        const isEmbedded = this.props.isEmbedded !== undefined ? this.props.isEmbedded : false;
        const user = this.props.user as User;

        const toolbarButtons = (
            <div className="global-toolbar-buttons">
                <ButtonGroup>
                    <IconContext.Provider value={{ size: '18px' }}>
                        <Button variant="outline-primary" className='global-toolbar-button' size="sm" title="View help" onClick={this.handleViewHelpClick}>
                            <IoMdHelpCircleOutline />
                        </Button>
                        {user.allowConfiguration() && (
                            <Button variant="outline-primary" className='global-toolbar-button' size="sm" title="Open settings" onClick={this.handleViewSettingsClick}>
                                <IoMdSettings />
                            </Button>)}
                        {deploymentConfigInfo && (deploymentConfigInfo.dashboardUrl || deploymentConfigInfo.configUIUrl) &&
                            <Dropdown className='global-toolbar-button' id="dropdown-button-apps">
                                <Dropdown.Toggle size="sm" variant='outline-primary' id="dropdown-basic">
                                    <MdApps />
                                </Dropdown.Toggle>
                                <DropdownMenu alignRight>
                                    {deploymentConfigInfo.dashboardUrl && <DropdownItem className="url-option" onClick={this.handleViewDashboardClick} href={deploymentConfigInfo.dashboardUrl}>
                                        Open Dashboard <span className="external-link-icon"><FaExternalLinkSquareAlt /></span>
                                    </DropdownItem>}
                                    {deploymentConfigInfo.configUIUrl && <DropdownItem className="url-option" onClick={this.handleViewConfigUIClick} href={deploymentConfigInfo.configUIUrl}>
                                        Open Config UI <span className="external-link-icon"><FaExternalLinkSquareAlt /></span>
                                    </DropdownItem>}
                                </DropdownMenu>
                            </Dropdown>}

                    </IconContext.Provider>
                </ButtonGroup>
            </div>
        );

        return (
            <div className="toolbar-main-container">
                {isEmbedded ? toolbarButtons : (<div className="global-main-toolbar"><div className="toolbar-container">{toolbarButtons}</div></div>)}
            </div>
        );
    }
}

export default connect(state => Object.assign({}, state), sagas.mapDispatchToProps)(GlobalToolbar);
