import React from 'react';
import { Row, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import { WindowLevel, getWindowLevelPresets, setWindowLevelPreset, deleteWindowLevelPreset } from '../../../rtviewer-core/window-level';

import './WindowLevelToolbar.css';

type OwnProps = {
    viewerState: ViewerState,
}

type AllProps = OwnProps;

type OwnState = {
    presetName: string,
    ww: string,
    wc: string,
}

export class WindowLevelToolbar extends React.Component<AllProps, OwnState> {
    displayName = WindowLevelToolbar.name

    constructor(props: AllProps) {
        super(props);
        this.render = this.render.bind(this);

        this.state = {presetName: "", ww: "", wc: ""};
    }

    componentDidMount() {
        this.props.viewerState.addListener(this.updateView);
        this.updateView();
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
    }

    updateView = () => {
        let vs = this.props.viewerState;
        let presetName = this.state.presetName;
        if(presetName === "") {
            let presets = getWindowLevelPresets();
            let presetNames = Object.keys(presets).sort();
            for(let i = 0; i < presetNames.length; ++i) {
                let p = presets[presetNames[i]];
                if(p.ww === vs.windowLevel.ww && p.wc === vs.windowLevel.wc) {
                    presetName = presetNames[i];
                    break;
                }
            }
        }

        this.setState({presetName: presetName, ww: vs.windowLevel.ww.toString(), wc: vs.windowLevel.wc.toString()});
    }

    handleWindowWidthChanged = (event: any) => {
        let vs = this.props.viewerState;
        this.setState({ww: event.target.value});
        let val = parseInt(event.target.value);
        if(!isNaN(val)) vs.setWindowLevel(new WindowLevel(Math.max(val, 1), vs.windowLevel.wc));
    }

    handleWindowCenterChanged = (event: any) => {
        let vs = this.props.viewerState;
        this.setState({wc: event.target.value});
        let val = parseInt(event.target.value);
        if(!isNaN(val)) vs.setWindowLevel(new WindowLevel(vs.windowLevel.ww, val));
    }

    handlePresetNameChanged = (event: any) => {
        let val = event.target.value
        this.setState({presetName: val});
    }

    handleSelectPreset = (name: string) => {
        let vs = this.props.viewerState;
        let windowLevel = getWindowLevelPresets()[name];
        if(windowLevel) {
            vs.setWindowLevel(windowLevel);
            this.setState({ww: windowLevel.ww.toString(), wc: windowLevel.wc.toString()});
        }
        this.setState({presetName: name});
    }

    handleSavePresetClick = () => {
        let vs = this.props.viewerState;
        if(this.state.presetName) {
            setWindowLevelPreset(this.state.presetName, vs.windowLevel);
        }
        this.updateView()
    }

    handleDeletePresetClick = (presetName: string) => {
        if (window.confirm('Are you sure you want to delete preset ' + presetName +'?')) {
            deleteWindowLevelPreset(presetName);
        }
        this.updateView()
    }

    render() {
        function isEmptyOrSpaces(str: string){
            return !str || str.match(/^ *$/) !== null;
        }

        let vs = this.props.viewerState;
        let presets = getWindowLevelPresets();
        let presetNames = Object.keys(presets).sort();
        let presetName = this.state.presetName;

        let presetSelected = !isEmptyOrSpaces(presetName)
                && presetNames.includes(presetName)
                && presets[presetName].ww === vs.windowLevel.ww
                && presets[presetName].wc === vs.windowLevel.wc;
        
        let dropdownTitle = presetSelected ? presetName : "Select preset";
        
        let showSave = !isEmptyOrSpaces(presetName) && !presetSelected;

        return (
            <Row className="window-level-edits"> 
                <div className="wwwc-label" title="Window width/Window center">ww/wc:</div>
                <input className="wwwc-value" title="Window width" type="text" value={this.state.ww} onChange={this.handleWindowWidthChanged} />
                <input className="wwwc-value" title="Window center" type="text" value={this.state.wc} onChange={this.handleWindowCenterChanged} />
                
                <DropdownButton id="wwwc-presets" className="preset-dropdown" title={dropdownTitle} variant="outline-info"  size="sm">
                    {
                        presetNames.map((name, key) =>
                        <Dropdown.Item as="button" key={key} onClick={() => this.handleSelectPreset(name)}>
                            {name + ": " + presets[name].ww + "/" + presets[name].wc}
                        </Dropdown.Item>
                    )}
                </DropdownButton>
                <input title="Preset name" className="preset-name" type="text" value={presetName} onChange={this.handlePresetNameChanged} />
                {presetSelected ? <Button  className="btn btn-default btn-sm" onClick={() => this.handleDeletePresetClick(presetName)}>Delete</Button> : null }
                {showSave ? <Button  className="btn btn-default btn-sm" onClick={this.handleSavePresetClick}>Save</Button> : null}
            </Row>
        );
    }
}
