import React from 'react';



type OwnProps = {
    handleError: () => void,
}

type AllProps = OwnProps;

type OwnState = {
    failed: boolean,
}


export class ErrorBoundary extends React.Component<AllProps, OwnState> {
    displayName = ErrorBoundary.name

    constructor(props: AllProps) {
        super(props);

        this.state = {
            failed: false
        };
    }

    componentDidCatch(error: any, info: any) {
        console.log(error);
        alert("Error: " + error.message);
        this.props.handleError();
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { failed: true };
      }

    render() {
        return this.state.failed ? (<h1>Failed</h1>) : this.props.children;
    }
}



