import React from "react";
import { Button, Form, Alert, Col } from "react-bootstrap";
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';

import { getNonAnonymizedDicomAttributesFromLocal, saveNonAnonymizedDicomAttributesToLocal } from '../../local-storage';

const dicomAttributesOptions = [
    'StudyID',
    'StudyDescription',
    'SeriesDescription',
    'StationName',
    'BodyPartExamined',
    'ImageComments',
    'ProtocolName',
    'PatientSex',
];

type OwnState = {
    isDicomAttributesPanelOpen: boolean,
    nonAnonymisedDicomAttributesState: string[],
}

class RetainedAttributesSelector extends React.Component<{}, OwnState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            isDicomAttributesPanelOpen: false,
            nonAnonymisedDicomAttributesState: getNonAnonymizedDicomAttributesFromLocal(),
        };
    }

    handleShowAnonymizationSettings = () => {
        this.setState({ isDicomAttributesPanelOpen: !this.state.isDicomAttributesPanelOpen });
    }

    handleIsNonAnonymisedDicomAttributeSelected = (attribute: string): boolean | undefined => {
        const nonAnonymizedDicomAttributes = getNonAnonymizedDicomAttributesFromLocal();
        return nonAnonymizedDicomAttributes.includes(attribute);
    }

    handleNonAnonymisedDicomAttributesChange = (selectedAttribute: string) => {
        let attributesMap = getNonAnonymizedDicomAttributesFromLocal();

        if (attributesMap.includes(selectedAttribute)) {
            attributesMap = attributesMap.filter(attribute => attribute !== selectedAttribute);
        } else {
            attributesMap = [...attributesMap, selectedAttribute];
        }
        saveNonAnonymizedDicomAttributesToLocal(attributesMap);
        this.setState({ nonAnonymisedDicomAttributesState: attributesMap });
    };

    render() {
        return (
            <div className="retained-attributes-container">
                <Button onClick={this.handleShowAnonymizationSettings} className="anonymization-settings-button">
                    Retained DICOM attributes: {"  "}
                    {this.state.isDicomAttributesPanelOpen ? <BsCaretUpFill /> : <BsCaretDownFill />}
                </Button>
                {
                    this.state.nonAnonymisedDicomAttributesState && this.state.nonAnonymisedDicomAttributesState.length > 0 && !this.state.isDicomAttributesPanelOpen && (
                        <Alert variant="warning">
                            <b>Notice:</b> The following DICOM attributes have been configured to not be anonymized: {this.state.nonAnonymisedDicomAttributesState.sort().join(", ")}.
                        </Alert>
                    )
                }
                {
                    this.state.isDicomAttributesPanelOpen && (
                        <Form.Group className='anonymization-settings-panel'>
                            <Form.Row>
                                <div>
                                    <Form.Label title={`Select which DICOM attributes will be retained without anonymization.`}>
                                        DICOM attributes to retain without anonymization:
                                    </Form.Label>
                                </div>
                                <Col className="value-row-input">
                                    <Form.Group controlId="formCheckboxGroup" >
                                        <div>
                                            {dicomAttributesOptions.map((attribute) => (
                                                <Form.Check
                                                    key={attribute}
                                                    type="checkbox"
                                                    id={`checkbox-${attribute}`}
                                                    label={attribute}
                                                    checked={this.handleIsNonAnonymisedDicomAttributeSelected(attribute)}
                                                    onChange={() => this.handleNonAnonymisedDicomAttributesChange(attribute)}
                                                />
                                            ))}</div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Form.Group>)
                }
            </div>
        );
    }
}

export default RetainedAttributesSelector;
