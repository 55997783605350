import React from 'react';
import { Item, Separator, Submenu, ItemParams } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { MdCheck } from 'react-icons/md';

import './RoiTypeMenu.css';
import { Roi } from '../../dicom/structure-set';

type OwnProps = {
    roi: Roi | null,
    onChangeRoiType: (roi: Roi, roiType: string) => void,
    canEdit: boolean,
}

type RoiType = {
    /** DICOM-compatible name of this ROI type. */
    dicomValue: string;
    /** human-readable name of this ROI type for GUI -- often, but not always, the same */
    guiValue: string;
    /** whether this ROI type is available in UI or not. Can still be used if imported from DICOM
     * or coming from a structure template. */
    isEnabled: boolean;
}

/** ROI Interpreted Type values that are directly supported by GUI.
 */
export const supportedRoiTypes: RoiType[] = [
    { dicomValue: 'ORGAN', guiValue: 'ORGAN', isEnabled: true },
    { dicomValue: 'MARKER', guiValue: 'MARKER', isEnabled: true },
    { dicomValue: 'POINT', guiValue: 'POINT', isEnabled: true },
    { dicomValue: '', guiValue: '<empty>', isEnabled: true },
    // the rest of the values are disabled in UI for now, can still be used from templates
    // see also: https://dicom.innolitics.com/ciods/rt-structure-set/rt-roi-observations/30060080/300600a4
    { dicomValue: 'TREATED_VOLUME', guiValue: 'TREATED_VOLUME', isEnabled: false },
    { dicomValue: 'IRRAD_VOLUME', guiValue: 'IRRAD_VOLUME', isEnabled: false },
    { dicomValue: 'BOLUS', guiValue: 'BOLUS', isEnabled: false },
    { dicomValue: 'AVOIDANCE', guiValue: 'AVOIDANCE', isEnabled: false },
    { dicomValue: 'REGISTRATION', guiValue: 'REGISTRATION', isEnabled: false },
    { dicomValue: 'ISOCENTER', guiValue: 'ISOCENTER', isEnabled: false },
    { dicomValue: 'CONTRAST_AGENT', guiValue: 'CONTRAST_AGENT', isEnabled: false },
    { dicomValue: 'CAVITY', guiValue: 'CAVITY', isEnabled: false },
    { dicomValue: 'SUPPORT', guiValue: 'SUPPORT', isEnabled: false },
    { dicomValue: 'FIXATION', guiValue: 'FIXATION', isEnabled: false },
    { dicomValue: 'DOSE_REGION', guiValue: 'DOSE_REGION', isEnabled: false },
    { dicomValue: 'CONTROL', guiValue: 'CONTROL', isEnabled: false },
    { dicomValue: 'DOSE_MEASUREMENT', guiValue: 'DOSE_MEASUREMENT', isEnabled: false },
    { dicomValue: 'BRACHY_CHANNEL', guiValue: 'BRACHY_CHANNEL', isEnabled: false },
    { dicomValue: 'BRACHY_ACCESSORY', guiValue: 'BRACHY_ACCESSORY', isEnabled: false },
    { dicomValue: 'BRACHY_SRC_APP', guiValue: 'BRACHY_SRC_APP', isEnabled: false },
    { dicomValue: 'BRACHY_CHNL_SHLD', guiValue: 'BRACHY_CHNL_SHLD', isEnabled: false },
];

/**
 * Sub menu for displaying and changing a structure's ROIInterpretedType value.
 * Must be used within a react-contexify menu.
 */
export default class RoiTypeMenu extends React.PureComponent<OwnProps> {

    handleRoiTypeClicked = ({ data }: ItemParams<unknown, { roiType: RoiType }>) => {
        const { roi } = this.props;
        if (roi && data) {
            this.props.onChangeRoiType(roi, data.roiType.dicomValue);
        }
    }

    render() {

        const { roi, canEdit } = this.props;

        if (!roi) { return null; }

        const { interpretedType } = roi;
        const selectedRoiType = supportedRoiTypes.find(r => r.dicomValue === interpretedType) || { dicomValue: interpretedType, guiValue: interpretedType, isEnabled: true };

        // allow user to view current ROI type even if they can't change it
        if (!canEdit) {
            return (<Item disabled={true}><span>ROI type: {selectedRoiType.guiValue}</span></Item>);
        }

        // 1. only show enabled ROI types & whatever the current selection in the original DICOM is
        // 2. add current interpreted ROI type from DICOM to roi types list if it's not one of the pre-defined values
        const enabledRoiTypes: RoiType[] = supportedRoiTypes.filter(r => r.isEnabled);
        if (!enabledRoiTypes.includes(selectedRoiType)) {
             enabledRoiTypes.unshift(selectedRoiType);
        }

        return (
            <Submenu label={(<span>ROI type: {selectedRoiType.guiValue}</span>)}>
                {enabledRoiTypes.map(r => (
                    <Item key={r.dicomValue} data={{ roiType: r }} onClick={this.handleRoiTypeClicked}>
                        <span className="roi-type-selected">{r === selectedRoiType && (<MdCheck />)}</span>
                        <span>{r.guiValue}</span>
                    </Item>))}
            </Submenu>
        );
    }

}
