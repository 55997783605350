import { sleep } from '../util';
import BackendClient from './backend-client';
import { AvailableModels, convertAvailableModelsDtoToViewModel } from './contouring-options';
import _ from 'lodash';
import { parse, ParseResult } from 'content-disposition-attachment';

const API_RESULT_IDS_KEY = 'result_ids';
const API_ERROR_IDS_KEY = 'error_ids';

const RETRY_SLEEP_TIME_MS = 10 * 1000; // 10 seconds
const MAX_RETRIES = 20;     // with the 10 second sleep times this is a bit more than 3 minutes

export class ContouringClient {

    private backendClient: BackendClient;

    private buildSendImageUrl = () => "/contouring";
    private buildPollUrl = () => "/contouring/poll";
    private buildDownloadErrorUrl = (resultId: string) => "/contouring/error-message?resultId=" + resultId;
    private buildDownloadStructureSetUrl = (resultId: string) => "/contouring/structure-set?resultId=" + resultId;
    private buildLabelingUrl = () => "/labeling";
    private buildAvailableModelsUrl = () => "/contouring/list-active-models";


    constructor(backendClient: BackendClient) {
        this.backendClient = backendClient;
    }

    public async getAvailableModels(): Promise<AvailableModels | undefined> {
        const url = this.buildAvailableModelsUrl();
        const response = await this.backendClient.get(url);
        if (response.status !== 200) {
            // maybe we're dealing with an older backend that doesn't support this API
            return undefined;
        }

        const allowTextEntry = true;
        const optionsDto = await response.json();
        const options = convertAvailableModelsDtoToViewModel(optionsDto, allowTextEntry);
        return options;
    }

    public sendImageFile(blob: Blob, filename: string, contouringAction: string, sliceCountTotal: number): Promise<void> {
        const url = this.buildSendImageUrl();

        let data = new FormData();
        data.append('slice', blob, filename);

        const options = {
            headers: {
                'action': contouringAction,
                'skip_dicom_validation': true,
                "slice_count_total": sliceCountTotal,
            },
            config: {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
            body: data,
        };

        return new Promise<void>(async (resolve, reject) => {
            let i = 0;
            let wasSuccessful = false;
            let errorMessage = "";

            // retry up to MAX_RETRIES times in case there's a problem with the dicom upload
            while (i < MAX_RETRIES && wasSuccessful === false) {
                if (i > 0) {
                    // wait a moment before each retry
                    await sleep(RETRY_SLEEP_TIME_MS);
                }

                i++;

                try {
                    const response = await this.backendClient.post(url, options);
                    if (response.status === 200) {
                        wasSuccessful = true;
                    } else {
                        // Parse the response body as JSON
                        const responseBody = await response.json();
                        if ('detail' in responseBody) {
                            // Check if the 'detail' field is an array or string and extract the error message
                            const detail = responseBody.detail;

                            if (_.isArray(detail) && detail.length > 0) {
                                // Join all error messages if 'detail' is an array
                                errorMessage = detail.map(error => _.get(error, 'msg', 'An error occurred when uploading image file')).join('\n');
                            } else {
                                // Use detail as error message if 'detail' is NOT an array 
                                errorMessage = detail;
                            }
                        } else {
                            // If 'detail' field is not present, use the entire response body as error message
                            errorMessage = JSON.stringify(responseBody);
                        }

                        // Check if response status is one of the specified codes where retrying is unnecessary
                        if ([401, 402, 404, 415, 422].includes(response.status)) {
                            break;
                        }
                    }
                }
                catch (error) {
                    if (_.isError(error)) {
                        errorMessage = error.message;
                    } else {
                        errorMessage = error.toString();
                    }
                }


                if (!wasSuccessful && errorMessage) {
                    // don't retry for known errors
                    if (this.isKnownError(errorMessage)) { break; }
                }
            }

            if (wasSuccessful) {
                resolve();
            } else {
                if (i >= MAX_RETRIES) {
                    console.log(`Timed out when trying to retry sending slice ${filename} to segmentation backend.`);
                }
                reject(errorMessage);
            }

        });
    }

    /** Known errors are not retried. */
    private isKnownError(error: any): boolean {
        const errorMessage: string = _.isString(error) ? error : _.has(error, 'detail') ? error.detail : '';

        // known errors
        // TODO: more errors can be added to this clause. It's not critical, it will just make contouring fail faster without
        // doing pointless retries
        return errorMessage.includes('Could not determine which model to run based on DICOM tags');
    }

    public async poll(scanId: string, handleResult: (arrayBuffer: ArrayBuffer, filename: string | undefined) => void, handleError: (error: string) => void, handleNoResults: () => void) {
        const url = this.buildPollUrl();
        const response = await this.backendClient.post(url);
        if (response.status === 304) {
            handleNoResults();
        }
        else if (response.status === 200) {
            response.json().then((data: any) => {
                const resultIds: string[] = data[API_RESULT_IDS_KEY];
                const errorIds: string[] = data[API_ERROR_IDS_KEY];

                resultIds.forEach(resultId => {
                    this.downloadStructureSet(resultId, handleResult, handleError);
                });
                errorIds.forEach(errorId => {
                    this.downloadErrorMessage(errorId, handleError);
                });
            });
        }
    }

    // Product labeling for the backend and worker components.
    public getLabeling() {
        return new Promise<any>((resolve, reject) => {
            this.backendClient.fetch(this.buildLabelingUrl())
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.json());
                    }
                    else {
                        reject();
                    }
                });
        });
    }


    private downloadStructureSet(resultId: string, handleResult: (arrayBuffer: ArrayBuffer, filename: string | undefined) => void, handleError: (error: string) => void): Promise<void> {
        const url = this.buildDownloadStructureSetUrl(resultId);

        return new Promise<void>((resolve, reject) => {
            this.backendClient.get(url)
                .then(response => {
                    if (response.status === 200) {

                        // try to get the filename of the incoming output file. requires that CORS setting 'Access-Control-Expose-Headers:Content-Disposition' is set on the server.
                        let parsedContentDisposition: ParseResult | undefined = undefined;
                        const contentDisposition = response.headers.get('content-disposition') || '';
                        try {
                            parsedContentDisposition = parse(contentDisposition);
                        } catch (err) {
                            // try adding in double quotes for filename and see if that works
                            const splitContentDisposition = contentDisposition.split('filename=');
                            if (splitContentDisposition.length !== 2) {
                                throw new Error('Invalid content disposition with no filename set');
                            }
                            const fixedContentDisposition = `${splitContentDisposition[0]}filename="${splitContentDisposition[1]}"`;
                            parsedContentDisposition = parse(fixedContentDisposition);
                        }
                        const parsedFilename = parsedContentDisposition && parsedContentDisposition.attachment ? parsedContentDisposition.filename : undefined;

                        response.arrayBuffer().then((data: ArrayBuffer) => {
                            try {
                                handleResult(data, parsedFilename);
                            }
                            catch (error) {
                                console.log('An error occurred when trying to handle fetched structure set result:');
                                console.log(error);
                                handleError(`An error occurred when trying to handle fetched structure set result: ${error}`);
                            }
                        });
                    } else {
                        response.text().then((text: string) => {
                            console.error(`Unexpected status code from API response (${response.status}) ${text}`);
                            console.error(response);
                            handleError(`An error occurred when trying to handle fetched structure set result: ${text}`);
                        });
                    }
                });
        });
    }

    private downloadErrorMessage(errorId: string, handleError: (error: string) => void) {
        const url = this.buildDownloadErrorUrl(errorId);

        return new Promise<void>((resolve, reject) => {
            this.backendClient.get(url)
                .then(response => {
                    if (response.status === 200) {
                        response.text().then((text: string) => {
                            handleError(text);
                        });
                    } else {
                        response.text().then((text: string) => {
                            console.error(`Wasn't able to fetch error message for result ${errorId}: ${text}`);
                            console.error(response);
                            handleError(`An error occurred when trying to handle fetched structure set result: ${text}`);
                        });
                    }
                });
        });
    }


}
