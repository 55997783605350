import { channel } from 'redux-saga'
import { put, all, take, fork, call, delay, select, spawn } from 'redux-saga/effects'
import * as Store from './store';
import { Dispatch } from 'redux'

import { StructureSet, dicomToStructureSet, structureSetToDicom, StructureSetModalMessages } from '../dicom/structure-set';
import { dicomToImageSlice, Image, ImageSlice } from '../dicom/image';
import { sleep, convertToFileSafe } from '../util';
import { ContouringClient } from '../web-apis/contouring-client';
import { ContouringTaskState } from './contouring-task';
import * as selectors from './selectors';
import { compressGZip, decompressGZip } from './gzip'
import { anonymizeSlice, DicomMapAnonReal } from '../dicom/image_anonymization';
import { defaultAuths, updateAppName } from '../environments';
import { RTViewerWebClient } from '../web-apis/rtviewer-web-client';
import { AppVersionInfo } from './app-version-info';
import { getAppAuthByName, getAppAuthByTier, getBackendClient, getOptionalAuths, setDefaultBackend, getDefaultBackend } from '../web-apis/auth';
import { AppAuthState, LogInProcessState } from './auth-state';
import { Backend, BackendDefinition } from '../web-apis/backends';
import { LiveReviewClient } from '../web-apis/livereview-client';
import AppAuth from '../web-apis/app-auth';
import { AzureDownloadError } from './errors';
import { AzureFileInfo, AzureShareInfo } from '../web-apis/azure-files';
import { AzureFileClient } from '../web-apis/azure-file-client';
import { AzureFileRequest, AzureFileShareRequest, LiveReviewV2FileRequest } from './requests';
import { ReceivedAzureFile } from './received-files';
import { getAreConcurrentUploadsEnabled, getNonAnonymizedDicomAttributesFromLocal } from '../local-storage';
import { downloadDatasetInfo, downloadDatasetLocks, LockAction, ScanLocks } from '../datasets/dataset-files';
import { Dataset } from '../datasets/dataset';
import { DatasetImage } from '../datasets/dataset-image';
import * as datasetFiles from '../datasets/dataset-files';
import WorkState from './work-state';
import { StructureSetGrading, convertWorkflowStateToText } from '../datasets/roi-grading';
import AnnotationQuery from '../components/annotation-page/models/AnnotationQuery';
import { NotificationType, SessionNotification } from '../components/common/models/SessionNotification';
import { ViewerState } from '../rtviewer-core/viewer-state';
import { DeploymentConfigInfo, isFeatureDisabled, DisabledFeature } from './deployment-config-info';
import { convertJsonToLabeling } from './labeling-info';
import _ from 'lodash';
import { createApplicationPermissions } from './application-permissions';
import { fixMissingMetaheader, copyArrayBuffer } from '../dicom/utils';
import { AccessRights, getInitialUserAccessRights, bypassedAccessRights } from '../web-apis/access-rights';
import { ConfigClient } from '../web-apis/config-client';


const MAX_DOWNLOAD_RETRY_ATTEMPTS = 5;


export const mapDispatchToProps = (dispatch: Dispatch) => ({

    initializeApp() {
        dispatch({ type: Store.initializeApp });
    },

    storeFullImage(img: Image) {
        dispatch({ type: Store.receiveFullImageType, image: img });
    },

    storeLocalScan(files: File[], cbReturnId: (scanId: string) => Promise<void>, allowMissingMetaheaderFix: boolean = false) {
        if (!files || files.length === 0) {
            return;
        }
        let len = files.length;
        let seriesId = "";
        function storeFile(file: File, arrayBuffer: ArrayBuffer, progress: number, isLastSlice: boolean) {

            dispatch({ type: Store.setLocalFileProgress, progress: progress, total: len });

            try {
                if (file.name.toLowerCase().endsWith('.gz')) { arrayBuffer = decompressGZip(arrayBuffer); }

                let slice: ImageSlice | null = null;

                // keep original array buffers for structure sets in case we need to fix headers in them
                let originalArrayBuffer: ArrayBuffer | undefined = undefined;

                try {
                    slice = dicomToImageSlice(arrayBuffer);
                }
                catch (err) {
                    // catch the headerless dicom file error from dcmjs and try to fix it
                    // regarding the dcmjs error message that we're catching here... sic
                    // (it's since been changed in a later dcmjs version -- the code supports catching both of them for now)
                    if ((err.message === 'Invalid a dicom file' || err.message === 'Invalid DICOM file, expected header is missing') && allowMissingMetaheaderFix) {
                        // try fixing the dicom file manually
                        originalArrayBuffer = copyArrayBuffer(arrayBuffer);
                        arrayBuffer = fixMissingMetaheader(arrayBuffer);
                        slice = dicomToImageSlice(arrayBuffer);
                    } else {
                        throw err;
                    }
                }
                if (slice) {
                    seriesId = slice.seriesInstanceUID;
                    dispatch({ type: Store.receiveSliceType, scanId: slice.seriesInstanceUID, arrayBuffer: arrayBuffer, imageSlice: slice });
                } else {
                    // Allow loading a structure set together with the image slices
                    let ss = dicomToStructureSet(arrayBuffer);
                    if (ss) {
                        const filename = file.name.toLowerCase().endsWith('.gz') ? file.name.slice(0, -3) : file.name;
                        dispatch({ type: Store.storeOriginalStructureSet, structureSetId: ss.structureSetId, file: originalArrayBuffer ? originalArrayBuffer : arrayBuffer, filename: filename, });
                        dispatch({ type: Store.receiveStructureSetType, structureSet: ss, filename: file.name });
                    } else {
                        throw new Error("Unsupported file");
                    }
                }
                if (isLastSlice) {
                    cbReturnId(seriesId);
                }
            } catch (error) {
                console.error(error);
                const message: string = _.has(error, 'message') ? error.message : error;
                dispatch({ type: Store.fileLoadError, errorMessage: message, fileName: file.name });
            }
        }
        function loopFiles(i: number) {
            let reader = new FileReader();
            reader.onload = function (e) {
                let res: any = reader.result;
                storeFile(files[i], res, i + 1, i === len - 1);
                if (++i < len) {
                    loopFiles(i);
                }
            }
            reader.readAsArrayBuffer(files[i]);
        }

        dispatch({ type: Store.resetLocalFileProgress });

        loopFiles(0);
    },

    unloadScan(scanId: string) {
        dispatch({ type: Store.unloadScanType, scanId: scanId });
    },

    unloadStructureSet(scanId: string, structureSetId: string) {
        dispatch({ type: Store.unloadStructureSetType, scanId: scanId, structureSetId: structureSetId });
    },

    unloadAllScans() {
        dispatch({ type: Store.unloadAllScansType });
    },

    storeStructureSet(arrayBuffer: ArrayBuffer, currentScanId: string, filename: string | null, isAutoContoured: boolean,
        generateNewAzureFileInfo: (seriesId: string, sopId: string) => AzureFileInfo | null,
        cbReturnId: (scanId: string, ssId: string) => void,
        storeOriginalStructureSet = true) {

        if (!arrayBuffer) {
            return;
        }

        let structureSet: StructureSet | null = null;

        try {
            structureSet = dicomToStructureSet(arrayBuffer);
        }
        catch (error) {
            console.error(error);
            const message: string = _.has(error, 'message') ? error.message : error;
            dispatch({ type: Store.fileLoadError, errorMessage: message, fileName: filename || '' });
        }

        if (structureSet) {
            if (structureSet.scanId !== currentScanId) {
                dispatch({ type: Store.fileLoadError, errorMessage: "Structure set belongs to different image. Scan: " + currentScanId + ', RTSTRUCT: ' + structureSet.scanId + '', fileName: filename || '' });
                return;
            }

            let extraOptions: any = {};
            if (filename !== null) {
                extraOptions['filename'] = filename;
            }

            if (isAutoContoured) {
                structureSet.existsInAzure = false;
                structureSet.unsaved = true;
                structureSet.azureFileInfo = generateNewAzureFileInfo(structureSet.seriesUid, structureSet.structureSetId);
            }

            if (storeOriginalStructureSet) {
                // this method should only ever receive uncompressed arraybuffers, so treat the default file extension as so
                const originalFilename = filename || `structureset_${convertToFileSafe(structureSet.label)}.dcm`;
                dispatch({ type: Store.storeOriginalStructureSet, structureSetId: structureSet.structureSetId, file: arrayBuffer, filename: originalFilename });
            }

            dispatch({ type: Store.receiveStructureSetType, arrayBuffer: arrayBuffer, structureSet: structureSet, ...extraOptions });
            cbReturnId(structureSet.scanId, structureSet.structureSetId);
        }
        else {
            dispatch({ type: Store.fileLoadError, errorMessage: "Invalid DICOM structure set", fileName: filename || '' });
        }
    },

    storeLocalStructureSet(file: File, currentScanId: string, cbReturnId: (scanId: string, ssId: string) => void) {
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            let arrayBuffer = reader.result as ArrayBuffer;
            if (file.name.toLowerCase().endsWith('.gz')) arrayBuffer = decompressGZip(arrayBuffer)
            this.storeStructureSet(arrayBuffer, currentScanId, file.name, false, () => null, cbReturnId);
        }
        reader.readAsArrayBuffer(file);
    },

    clearFileLoadErrors() {
        dispatch({ type: Store.clearFileLoadErrors });
    },

    unloadImageAndStructureSets(datasetImage: DatasetImage) {
        dispatch({ type: Store.unloadScanType, scanId: datasetImage.seriesId });
        for (let i = 0; i < datasetImage.structureSets.length; ++i) {
            const ss = datasetImage.structureSets[i];
            dispatch({ type: Store.unloadStructureSetType, scanId: datasetImage.seriesId, structureSetId: ss.sopId });
        }
        dispatch({ type: Store.deleteDownloadType, downloadKey: datasetImage.downloadKey });
    },

    async deleteStructureSet(structureSet: StructureSet): Promise<void> {

        // if structure set has been saved to azure, those external files must be removed
        // (if not, it's enough to handle the structure sets internally in-memory)
        if (structureSet.existsInAzure) {
            if (!structureSet.azureFileInfo) {
                throw new Error("azureFileInfo is null");
            }

            const accountName = structureSet.azureFileInfo.storageAccountName;
            const azureFileClient = new AzureFileClient(accountName);
            const path = azureFileClient.getPath(structureSet.azureFileInfo.fileShareName, structureSet.azureFileInfo.path);

            const directoryExists = await azureFileClient.doesDirectoryExist(path);
            if (directoryExists) {
                await azureFileClient.deleteDirectoryRecursive(path);
            }
        }

        dispatch({ type: Store.deleteStructureSetType, structureSet: structureSet });
    },

    storeNewStructureSet(structureSet: StructureSet) {
        dispatch({ type: Store.receiveStructureSetType, structureSet: structureSet });
    },

    undoStructureSetChanges(ss: StructureSet): StructureSet | null {
        if (ss.existsInAzure) {
            let ssNew = dicomToStructureSet(ss.arrayBuffer);
            if (ssNew) {
                ssNew.azureFileInfo = ss.azureFileInfo;
                dispatch({ type: Store.receiveStructureSetType, structureSet: ssNew });
                return ssNew;
            }
        }
        else {
            ss.deleted = true;
        }
        dispatch({ type: Store.deleteStructureSetType, structureSet: ss });
        return null;
    },

    async createDownloadTask(datasetImage: DatasetImage) {
        dispatch({ type: Store.receiveDownloadCreated, downloadKey: datasetImage.downloadKey });
    },


    sendImageForContouring(arrayBuffers: ArrayBuffer[], scanId: string, contouringAction: string, backend: Backend, dicomMapAnonReal: DicomMapAnonReal) {
        dispatch({ type: Store.receiveUploadCreated, scanId: scanId });
        let cnt = 0;
        const sliceCountTotal = arrayBuffers.length;
        arrayBuffers.forEach(ab => {
            const filename = (++cnt) + ".dcm";
            dispatch({ type: Store.receiveUploadAddFile, arrayBuffer: ab, dicomMapAnonReal: dicomMapAnonReal, filename: filename, scanId: scanId, contouringAction: contouringAction, backend: backend, sliceCountTotal: sliceCountTotal });
        });
    },

    clearAllContouringRequests: () => {
        dispatch({ type: Store.unloadAllContouringTasksType });
    },

    seenStructureSet: (structureSetId: string) => {
        dispatch({ type: Store.seenStructureSetType, structureSetId: structureSetId });
    },

    setContouringTaskState: (scanId: string, newContouringState: ContouringTaskState, errorMessage: string) => {
        dispatch({ type: Store.updateContouringTaskType, scanId, newContouringState, errorMessage });
    },

    dismissContouringTask: (scanId: string) => {
        dispatch({ type: Store.dismissContouringTaskType, scanId });
    },

    setBatchJobPaneVisibility(value: boolean) {
        dispatch({ type: Store.setBatchJobPaneVisibilityType, value });
    },

    setUserSettingsDialogVisibility(value: boolean) {
        dispatch({ type: Store.setUserSettingsDialogVisibilityType, value });
    },

    setHelpDialogVisibility(value: boolean) {
        dispatch({ type: Store.setHelpDialogVisibility, value });
    },

    setUserSettingBackend(backend: Backend | null) {
        dispatch({ type: Store.setUserSettingBackend, backend });

        // set the new backend immediately as being required so UI will prompt user to log
        // into it if necessary
        if (backend !== null) {
            const appAuth = getAppAuthByTier(backend.tier);
            dispatch({ type: Store.setAuthStateAsRequired, appAuthName: appAuth.appName });
        }
    },

    startBatchJobRequest: () => {
        throw new Error('Not supported in Import/Export UI');
    },

    finishBatchJobRequest: (wasSuccessful: boolean) => {
        throw new Error('Not supported in Import/Export UI');
    },

    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => {
        // they delay can be used to e.g. wait for a modal dialog to fade out before showing the notification
        const delay = delayInMilliseconds ? delayInMilliseconds : 0;
        setTimeout(() => dispatch({ type: Store.addNotificationType, notification }), delay);
    },

    dismissNotification: (notificationId: string) => {
        dispatch({ type: Store.removeNotificationType, notificationId });
    },

    snoozeNewVersionAlert: () => {
        dispatch({ type: Store.snoozeNewVersionAlert });
    },

    setLiveReviewQueries: (urlSearchParams: URLSearchParams): boolean => {
        throw new Error('Not supported in Import/Export UI');
    },

    logIntoAppAuth: (appAuth: AppAuth) => {
        dispatch({ type: Store.setAuthStateAsRequired, appAuthName: appAuth.appName });
    },

    requestLogOut: () => {
        dispatch({ type: Store.requestLogOut });
    },

    /**
     * Downloads a Dataset from Azure and stores it into Redux Store. Any existing store entry with the same dataset ID
     * will be replaced.
     * @param azureShare Azure location of the dataset.
     * @param reloadMetaFiles If true, metadata files (gradings, allowed roi names, editor info) are loaded and replaced. 
     * If false, metadata file entries in the dataset are left as null.
     * @param datasetId ID which will be given to the downloaded dataset. This usually matches the azure share location (but it doesn't have to).
     */
    downloadDataset: (azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string) => {
        throw new Error('Not supported in Import/Export UI');
    },

    lockDatasetImage: async (datasetImage: DatasetImage, dataset: Dataset, lockAction: LockAction) => {
        throw new Error('Not supported in Import/Export UI');
    },

    downloadDatasetImage(dataset: Dataset, datasetImage: DatasetImage) {
        throw new Error('Not supported in Import/Export UI');
    },

    /** Sets an entirely new work state as current work state. */
    setCurrentWorkState: (workState: WorkState | null) => {
        const dispatchedWorkState = workState !== null ? workState : new WorkState(null, null, false, false)
        dispatch({ type: Store.setCurrentWorkState, workState: dispatchedWorkState });

        // clear modified dataset gradings from memory if we're resetting current work state
        if (workState === null) {
            dispatch({ type: Store.clearModifiedDatasetGradings });
        }
    },

    /** Updates selected properties only of the current work state. */
    updateCurrentWorkState: (updatedWorkStateProps: Partial<WorkState>) => {
        dispatch({ type: Store.updateCurrentWorkState, ...updatedWorkStateProps });
    },

    /**
     * Synchronizes a structure set's matching grading sheet, if any, to match
     * the current ROI set in the structure set.
     */
    syncStructureSetGrading: (structureSet: StructureSet, dataset: Dataset) => {
        dispatch({ type: Store.syncStructureSetGrading, structureSet, dataset });
    },

    /**
     * Sets a specific structure set grading sheet into local redux store WITHOUT saving the changes into
     * azure. The redux store will then be out of sync with the data in azure. This function should only 
     * be used for temporary operations that will be reverted or finalized immediately after.
     */
    setGradingWithoutSaving: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => {
        const gradingsToSave: datasetFiles.GradingToSave[] = [{ ssId: structureSetId, ssGrading }];
        dispatch({ type: Store.setStructureSetGrading, gradingsToSave, dataset });
    },


    /**
     * Saves selected structure set grading sheet, new or updated, to disk (to azure).
     */
    saveGrading: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => {
        dispatch({ type: Store.saveStructureSetGrading, structureSetId, ssGrading, dataset });
    },

    /**
     * Saves all supplied dataset gradings to matching grading sheet file on disk (azure).
     * This needs to be called directly in only very rare cases, usually SaveGrading will suffice.
     */
    saveDatasetGradings: (gradingsToSave: datasetFiles.GradingToSave[], dataset: Dataset) => {
        dispatch({ type: Store.saveDatasetGradings, gradingsToSave, dataset });
    },

    /**
     * Reloads dataset gradings for the specified dataset. It's important to do this after a context change
     * (such as changing the scan that's currently active) as saving grading sheets for an individual image
     * may leave the rest of the dataset gradings out of sync.
     */
    reloadDatasetGradings: (dataset: Dataset) => {
        dispatch({ type: Store.reloadDatasetGradings, dataset: dataset });
    },

    /**
     * Load a work state from an annotation query item.
     */
    loadAnnotationQuery: (annotationQuery: AnnotationQuery) => {
        dispatch({ type: Store.loadAnnotationQuery, annotationQuery });
    },

    /**
     * Convert a structure set into DICOM and export it to a backend API.
     */
    async exportStructureSet(structureSet: StructureSet, img: Image, grading: StructureSetGrading | undefined, vs: ViewerState, task: datasetFiles.ExportTask | null, backend: Backend,
        originalDicomFile?: { file: ArrayBuffer, filename: string }) {
        structureSet.modalMessage = StructureSetModalMessages.Exporting;
        vs.notifyListeners();

        await sleep(500); // This must be really long sleep, otherwise the modal dialog shows as almost transparent (slow fade-in animation)

        try {
            const useOriginalDicom = originalDicomFile !== undefined;

            if (!structureSet.azureFileInfo && !useOriginalDicom) {
                throw new Error("No source file specified for given structure set");
            }

            const useGzip = useOriginalDicom ? false : structureSet.azureFileInfo!.filename.toLowerCase().endsWith(".gz");

            // write workflow state (empty, approved, unapproved) into the DICOM's StructureSetDescription field
            // (we shouldn't use the actual ApprovalStatus field for this)
            const postOps = (dataset: any) => {
                if (grading) {
                    dataset.StructureSetDescription = convertWorkflowStateToText(grading.workflowState);
                }
            };

            const ab = useOriginalDicom ? originalDicomFile!.file : structureSetToDicom(structureSet, img, postOps);
            const dicomBlob = new Blob([useGzip ? compressGZip(ab) : ab], { type: "" });
            const dicomFilename = useOriginalDicom ? originalDicomFile!.filename : `${structureSet.dataset.StructureSetLabel || structureSet.structureSetId}${useGzip ? '.dcm.gz' : '.dcm'}`

            const defaultTask: datasetFiles.ExportTask = { 'app_id': 'n/a', 'app_name': 'n/a', 'client_id': 'n/a' };
            const taskBlob = new Blob([JSON.stringify(task !== null ? task : defaultTask)], { type: 'application/json' });
            const taskFilename = 'task';

            const liveReviewClient = new LiveReviewClient(getBackendClient(backend));
            const wasSuccessful = await liveReviewClient.exportStructureSet(dicomBlob, dicomFilename, taskBlob, taskFilename);

            let notification: SessionNotification;
            if (wasSuccessful) {
                notification = new SessionNotification(`export-ok-${Date.now().toString()}`, `Structure set ${structureSet.dataset.StructureSetLabel || ''} was exported successfully!`, NotificationType.Success);
            } else {
                notification = new SessionNotification(`export-failed-${Date.now().toString()}`, `Something went wrong with export of structure set ${structureSet.dataset.StructureSetLabel || ''}`, NotificationType.Failure);
            }
            dispatch({ type: Store.addNotificationType, notification });
        }
        finally {
            structureSet.modalMessage = null;
            vs.notifyListeners();
        }
    },

    setAreDeprecatedModelsVisible: (areVisible: boolean) => {
        dispatch({ type: Store.setAreDeprecatedModelsVisible, areVisible: areVisible });
    },

    setAreHiddenModelsVisible: (areVisible: boolean) => {
        dispatch({ type: Store.setAreHiddenModelsVisible, areVisible: areVisible });
    },

});

function* watchVersion() {
    yield delay(1000);
    while (true) {
        const appVersionInfo: AppVersionInfo | undefined = yield RTViewerWebClient.getAppVersionInfo();
        yield put({ type: Store.receiveAppVersionInfo, appVersionInfo });

        // wait for 1 hour before doing another version check
        yield delay(1 * 60 * 60 * 1000);
    }
}

export function* downloadDicomAsync(action: any) {
    // const actions: any = [];
    const request = action.request as AzureFileShareRequest;

    const fileInfo = request.fileInfo;
    const isGZip: boolean = fileInfo.filename.toLowerCase().endsWith(".gz");
    const downloadKey = action.request.downloadKey;

    const azureClient = new AzureFileClient(fileInfo);

    let file: ArrayBuffer | null = null;
    let downloadAttempt = 0;
    let downloadSucceeded = false;
    let azureDownloadError: AzureDownloadError | null = null;

    // download the dicom file, retry a couple of times in case of minor network glitches with azure
    while (!downloadSucceeded && downloadAttempt < MAX_DOWNLOAD_RETRY_ATTEMPTS) {
        downloadAttempt++;

        try {
            file = yield call(() => azureClient.downloadArrayBuffer(fileInfo));
        }
        catch (error) {
            console.error(`An error occurred when trying to download DICOM file ${fileInfo.toString()}`);
            console.log(error);
            azureDownloadError = new AzureDownloadError(fileInfo, downloadKey, error);
            continue;
        }

        downloadSucceeded = true;
    }

    if (!downloadSucceeded && azureDownloadError !== null) {
        yield put({ type: Store.receiveDownloadErrorType, error: azureDownloadError });
        return;
    }

    if (file === null || !downloadSucceeded) {
        throw new Error(`An unspecified error occurred when trying to download ${fileInfo.toString()}`);
    }

    if (isGZip) {
        try {
            file = decompressGZip(file);
        }
        catch (error) {
            console.error(error);
            console.log(`Error when trying to decompress ${fileInfo.toString()} -- will try to read it as an uncompressed DICOM file despite the file extension.`);
        }
    }

    try {

        // try to convert DICOM file into either an image slice or a structure set

        const imageSlice = dicomToImageSlice(file);
        if (imageSlice) {
            yield put({ type: Store.receiveSliceType, scanId: imageSlice.seriesInstanceUID, arrayBuffer: file, imageSlice: imageSlice });
            yield put({ type: Store.receiveFileDownloadedType, receivedFile: new ReceivedAzureFile(fileInfo, downloadKey, imageSlice.seriesInstanceUID) });
        }
        else {
            const structureSet = dicomToStructureSet(file);
            if (structureSet) {
                structureSet.azureFileInfo = fileInfo;
                const filename = fileInfo.filename.toLowerCase().endsWith('.gz') ? fileInfo.filename.slice(0, -3) : fileInfo.filename;
                yield put({ type: Store.storeOriginalStructureSet, structureSetId: structureSet.structureSetId, file: file, filename: filename });
                yield put({ type: Store.receiveStructureSetType, structureSet: structureSet });
                yield put({ type: Store.receiveFileDownloadedType, receivedFile: new ReceivedAzureFile(fileInfo, downloadKey, structureSet.scanId, structureSet.structureSetId) });
            }
            else {
                const azureDownloadError = new AzureDownloadError(fileInfo, downloadKey, "Invalid or unsupported DICOM file");
                yield put({ type: Store.receiveDownloadErrorType, error: azureDownloadError });
            }
        }
    }
    catch (error) {
        console.error(error);
        console.log(`Invalid dicom file (${fileInfo.toString()})`);
    }
}

// Saga function to fetch access rights
export function* fetchAccessRightsSaga() {
    // ignore access rights if the matching feature has been disabled
    const config: DeploymentConfigInfo | undefined = yield select(selectors.getDeploymentConfig());
    if (!config) {
        throw new Error('No deployment config file found');
    }
    if (isFeatureDisabled(config, DisabledFeature.AccessRights)) {
        yield put({ type: Store.setAccessRights, accessRights: bypassedAccessRights });
        return;
    }

    const backend = getDefaultBackend();

    const client = new ConfigClient(getBackendClient(backend));
    let accessRights: AccessRights | null = null;
    let errorMessage: string | null = null;

    try {
        // Call the API to fetch access rights
        accessRights = yield call(() => client.fetchAndParseAccessRights());
    } catch (err) {
        // Handle any errors during API call
        console.error(err);
        errorMessage = err.toString();
        yield put({ type: Store.setAccessRights, accessRights: getInitialUserAccessRights(), error: errorMessage });
    }
    if (!errorMessage) {
        // If no error occurred during API call
        yield put({ type: Store.setAccessRights, accessRights: accessRights });
    }

    return errorMessage === null;
}


export function* downloadLiveReviewV2DicomFromMVBackendAsync(action: any) {
    throw new Error('Not suppored in Import/Export UI');
}

export function* uploadDicomAsync(action: any) {
    const backend = action.backend as Backend;
    const client = new ContouringClient(getBackendClient(backend));

    const scanId = action.scanId;
    const arrayBuffer = action.arrayBuffer;
    const filename = action.filename;
    const contouringAction = action.contouringAction;
    const sliceCountTotal = action.sliceCountTotal;

    const hasThisUploadAlreadyFailed: boolean = yield select(selectors.getHasUploadFailed(action.scanId));
    const nonAnonymisedDicomAttributesMap: string[] = getNonAnonymizedDicomAttributesFromLocal();
    if (!hasThisUploadAlreadyFailed) {
        const ab2 = anonymizeSlice(arrayBuffer, action.dicomMapAnonReal, nonAnonymisedDicomAttributesMap)

        const blob = new Blob([ab2], { type: "" });
        try {
            yield call([client, 'sendImageFile'], blob, filename, contouringAction, sliceCountTotal);
            yield put({ type: Store.receiveFileUploadedType, filename: filename, scanId: scanId });
        }
        catch (error) {
            yield put({ type: Store.receiveUploadErrorType, error: error || "Invalid DICOM file", scanId: scanId });
        }
    }
}

function initializeDeploymentAuth(config: DeploymentConfigInfo) {
    const { backendTier, backendUrl, backendName } = config;

    // change app name as appropriate
    updateAppName(config);

    // generate new backend definition. we don't need to store this anywhere -- it's not needed after the default backend has been created and set
    const backendDefinition = new BackendDefinition(backendName || 'Default backend', backendUrl);

    // add appauth matching the backend tier to default auths if needed
    const appAuthName = getAppAuthByTier(backendTier).appName;
    if (!defaultAuths.includes(appAuthName)) {
        defaultAuths.push(appAuthName);
    }

    // set the default backend
    setDefaultBackend(backendDefinition, backendTier);
}


/** Initialize app by loading deployment config & initializing authentication. */
function* initialize() {
    // STEP 1: Initialize deployment config
    const configInfo: DeploymentConfigInfo = yield call([RTViewerWebClient, 'getDeploymentConfigInfo']);
    const applicationPermissions = createApplicationPermissions(configInfo);
    yield put({ type: Store.setDeploymentConfigInfo, deploymentConfigInfo: configInfo });
    yield put({ type: Store.setApplicationPermissions, applicationPermissions });
    initializeDeploymentAuth(configInfo)
    yield initializeAuth();
}

function* initializeAuth() {
    // set default auths
    let firstAuthApp: string | null = null;
    for (const authApp of defaultAuths) {
        if (!firstAuthApp) { firstAuthApp = authApp; }
        yield put({ type: Store.addAuthState, authState: new AppAuthState(authApp, true) });
    }

    // add in all the optional auths -- they're logged into later as needed
    for (const authApp of getOptionalAuths()) {
        yield put({ type: Store.addAuthState, authState: new AppAuthState(authApp, false) });
    }

    // log in the first auth app
    if (firstAuthApp) {
        const firstAuth: AppAuthState = yield select(selectors.getAuthState(firstAuthApp));
        if (!firstAuth) { throw new Error(`Could not retrieve auth app ${firstAuthApp} from store!`); }
        yield put({ type: Store.startLogin, appAuthName: firstAuth.appAuthName });
        const appAuth = getAppAuthByName(firstAuth.appAuthName);

        try {
            yield call([appAuth, 'logIn']);
        } catch (err) {
            yield put({ type: Store.setLoginError, loginError: _.isError(err) ? err : new Error('An error occurred during login.') });
            // stop application progress here
            throw new Error('Log-in failed -- stopping application');
        }

        yield put({ type: Store.setAuthStateAsLoggedIn, appAuthName: firstAuth.appAuthName });
        const loggedInUser = appAuth.getLoggedInUserDetails();
        if (loggedInUser) {
            yield put({ type: Store.setUserDetails, username: loggedInUser.name, email: loggedInUser.email, userId: loggedInUser.userId });
        }
    }

    // do rest of the required logins one by one
    yield authLoginLogoutOnRequest(false);

    // do we have a login error? if so, stop here
    const wasLoginSuccessful: boolean = yield select(selectors.getWasLoginSuccessful());
    if (!wasLoginSuccessful) {
        console.error('Login has failed:');
        console.log(yield select(selectors.getLoginError()));
        return;
    }

    // perform some post-login activities
    try {
        // get access rights
        const gettingAccessRightsSucceeded = yield fetchAccessRightsSaga();
        if (!gettingAccessRightsSucceeded) {
            throw new Error('Unable to load access rights');
        }

        // get labeling
        const backend = getDefaultBackend();
        const contouringClient = new ContouringClient(getBackendClient(backend));
        const labelJson = yield call([contouringClient, 'getLabeling']);
        const labeling = convertJsonToLabeling(labelJson);
        yield put({ type: Store.setLabeling, labeling: labeling });
    }
    catch (err) {
        console.error(err);
        yield put({ type: Store.setLoginError, loginError: _.isError(err) ? err : new Error('An error occurred during login.') });
        return;
    }

    // wait for any new login/logout requests
    yield spawn(authLoginLogoutOnRequest, true);
}

function* authLoginLogoutOnRequest(loopForever: boolean) {
    const authsNeedingLogin: AppAuthState[] = [];
    const authsNeedingLogout: AppAuthState[] = [];

    let keepLooping = true;

    do {
        // begin by checking we haven't missed any auths that need login;
        authsNeedingLogin.push(...(yield select(selectors.getAuthStatesThatNeedLogin())) as AppAuthState[]);

        // start logging into app auths one by one
        while (authsNeedingLogin.length > 0) {
            const authState = authsNeedingLogin.pop() as AppAuthState;
            yield put({ type: Store.startLogin, appAuthName: authState.appAuthName });
            const appAuth = getAppAuthByName(authState.appAuthName);

            try {
                yield call([appAuth, 'logIn']);
            } catch (err) {
                yield put({ type: Store.setLoginError, loginError: _.isError(err) ? err : new Error('An error occurred during login.') });

                // stop application progress here
                throw new Error('Log-in failed -- stopping application');
            }

            yield put({ type: Store.setAuthStateAsLoggedIn, appAuthName: authState.appAuthName });
        }

        // start logging out
        while (authsNeedingLogout.length > 0) {
            const authState = authsNeedingLogout.pop() as AppAuthState;
            yield put({ type: Store.startLogout, appAuthName: authState.appAuthName });
            const appAuth = getAppAuthByName(authState.appAuthName);

            try {
                yield call([appAuth, 'logOut']);
            } catch (err) {
                yield put({ type: Store.setLoginError, loginError: _.isError(err) ? err : new Error('An error occurred during logout.') });

                // stop application progress here
                throw new Error('Log-out failed -- stopping application');
            }

            // a successful logout will automatically redirect the user somewhere else
        }

        // stop the loop here if this isn't supposed to be an infinite loop
        if (!loopForever) {
            break;
        }

        // wait for a new login request by waiting for an existing app auth state to be set as required
        const loginOrLogoutRequest: { appAuthName: string, type: string } = yield take([Store.setAuthStateAsRequired, Store.requestLogOut]);
        if (loginOrLogoutRequest.type === Store.setAuthStateAsRequired) {
            const appAuthState: AppAuthState = yield select(selectors.getAuthState(loginOrLogoutRequest.appAuthName));
            if (appAuthState.logInProcessState === LogInProcessState.NotLoggedIn) {
                authsNeedingLogin.push(appAuthState);
            }
        } else if (loginOrLogoutRequest.type === Store.requestLogOut) {
            // log out of the (first) default app auth -- we only ever allow one user to log in at once so that's enough
            // to log out of everything
            const appAuthState: AppAuthState = yield select(selectors.getAuthState(defaultAuths[0]));
            authsNeedingLogout.push(appAuthState);
        }
    } while (keepLooping);
}

function* waitForLoginsToFinish() {
    let isWaitingForLoginsToFinish: boolean = yield select(selectors.getAuthStatesAreLoginsNeeded());
    while (isWaitingForLoginsToFinish) {
        yield take(Store.setAuthStateAsLoggedIn);
        isWaitingForLoginsToFinish = yield select(selectors.getAuthStatesAreLoginsNeeded());
    }
}


function* loadDataset(azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string) {

    if (!datasetId) {
        throw new Error(`Invalid Dataset ID: must not be falsy ('${datasetId}').`);
    }

    // mark download as started
    yield put({ type: Store.startDatasetDownload, datasetId: datasetId });

    // download the dataset
    try {
        // add or replace dataset in store, mark download as finished
        const downloadInfoFile = function* () {
            const dataset: Dataset = yield call(downloadDatasetInfo, azureShare, reloadMetaFiles, datasetId);
            yield put({ type: Store.addDataset, dataset: dataset, overrideGradings: reloadMetaFiles });
        }

        // download and replace current dataset locks info from latest from azure
        const downloadLockFile = function* () {
            const datasetLocks: ScanLocks = yield call(downloadDatasetLocks, azureShare);
            yield put({ type: Store.setDatasetLock, datasetId: datasetId, scanLocks: datasetLocks });
        }

        yield all([call(downloadInfoFile), call(downloadLockFile)]);
    }
    catch (err) {
        console.error(err);
        throw err;
    }
    finally {
        yield put({ type: Store.finishDatasetDownload, datasetId: datasetId });
    }
}

function* downloadImageAndStructureSet(datasetImage: DatasetImage, dataset: Dataset, reloadDataset: boolean = false) {
    const datasetId = dataset.getDatasetId();
    let updatedDataset: Dataset | undefined = dataset;

    // first, reload the dataset if so requested
    if (reloadDataset) {
        // just always reload meta files, the performance hit is not significant
        const reloadMetafiles = true;
        // const existingDataset: Dataset | undefined = yield select(getDataset(datasetId));
        // const reloadMetafiles = existingDataset === undefined;

        yield loadDataset(dataset.datasetFile.getShare(), reloadMetafiles, datasetId);

        updatedDataset = yield select(selectors.getDataset(datasetId));
        if (updatedDataset === undefined) {
            throw new Error(`Was not able to properly reload dataset ${datasetId}`);
        }
    }

    const downloadKey = datasetImage.downloadKey;
    const shareInfo = updatedDataset.datasetFile.getShare();
    const azureFileClient = new AzureFileClient(shareInfo);

    try {
        // step 1 -- initialize downloads of image slices
        const imageFiles: AzureFileInfo[] = yield call(() => azureFileClient.listFiles(shareInfo, datasetImage.path));
        for (const imageFile of imageFiles) {
            const fileInfo = shareInfo.getFile(datasetImage.path, imageFile.filename);
            try {
                const lower = imageFile.filename.toLowerCase();
                if (lower.endsWith(".dcm") || lower.endsWith(".gz")) {
                    const request = new AzureFileRequest(fileInfo, downloadKey);
                    yield put({ type: Store.receiveDownloadAddFile, request: request });
                }
            }
            catch (error) {
                console.error(`Error when trying to download image file ${fileInfo.toString()}`);
                console.log(error);
                yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(fileInfo, downloadKey, error) });
            }
        }

        // step 2 -- initialize downloads of structure sets
        for (let s = 0; s < datasetImage.structureSets.length; ++s) {
            const structureSet = datasetImage.structureSets[s];

            // get a "best guess file" that's about in the correct place
            const bestGuessFile = datasetFiles.getStructureSetFileInfo(shareInfo, datasetImage.patientId, datasetImage.frameOfReferenceUid,
                structureSet.seriesId, structureSet.sopId);

            // now get the actual file(s) from the same folder
            const structureSetFiles: AzureFileInfo[] = yield call(() => azureFileClient.listFiles(bestGuessFile.getPath()));
            for (const structureSetFile of structureSetFiles) {
                try {
                    const lower = structureSetFile.filename.toLowerCase();
                    if (lower.endsWith(".gz") || lower.endsWith(".dcm")) {
                        const request = new AzureFileRequest(structureSetFile, downloadKey);
                        yield put({ type: Store.receiveDownloadAddFile, request: request });
                    }
                }
                catch (error) {
                    console.error(`Error when trying to download structure set file ${structureSetFile.toString()}`);
                    console.log(error);
                    yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(structureSetFile, downloadKey, error) });
                }
            }

        }
    }
    catch (error) {
        console.error(`Error when trying to download image and structure set files from ${shareInfo.toString()} for download key "${downloadKey}"`);
        console.log(error);
        yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(shareInfo, downloadKey, error) });
    }
}

function* watchImageAndStructureSetDownloads() {
    while (true) {
        const action: { datasetImage: DatasetImage, dataset: Dataset, reloadDataset: boolean | undefined } = yield take(Store.requestImageAndStructureSetDownload);
        yield spawn(downloadImageAndStructureSet, action.datasetImage, action.dataset, action.reloadDataset);
    }
}

function* watchDicomDownloads() {
    // @ts-ignore: the return object from the yield call function is a complex redux saga object
    const queue = yield call(channel);

    // create 5 'worker threads'
    for (let i = 0; i < 5; i++) {
        yield fork(handleDownload, queue);
    }
    while (true) {
        // @ts-ignore: TODO: strongly type expected action payload from this yield take call
        const action = yield take(Store.receiveDownloadAddFile);
        yield put(queue, action);
    }
}

function* handleDownload(chan: any) {
    while (true) {
        // @ts-ignore: the return object from the yield take function is a complex redux saga object
        const action = yield take(chan);
        if (action.request && action.request instanceof LiveReviewV2FileRequest) {
            yield downloadLiveReviewV2DicomFromMVBackendAsync(action);
        }
        else {
            yield downloadDicomAsync(action);
        }
    }
}

function* watchDicomUploads() {
    // @ts-ignore: the return object from the yield call function is a complex redux saga object
    const queue = yield call(channel);

    // create 5 'worker threads'
    const workerThreadCount = getAreConcurrentUploadsEnabled() ? 5 : 1;
    for (let i = 0; i < workerThreadCount; i++) {
        yield fork(handleUpload, queue);
    }
    while (true) {
        // @ts-ignore: TODO: strongly type expected action payload from this yield take call
        const action = yield take(Store.receiveUploadAddFile);
        yield put(queue, action);
    }
}

function* handleUpload(chan: any) {
    while (true) {
        // @ts-ignore: the return object from the yield take function is a complex redux saga object
        const action = yield take(chan);
        yield uploadDicomAsync(action);
    }
}

export default function* rootSaga() {
    // don't actually run root saga until a component tells to initialize the full application
    yield take(Store.initializeApp);

    yield all([
        initialize(),
        watchImageAndStructureSetDownloads(),
        watchDicomDownloads(),
        watchDicomUploads(),
        watchVersion(),
    ])
}
