import React from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import './NewStructureSetDialog.css';
import { PredictionModel } from '../../../web-apis/contouring-options';
import ModalDialog from '../../common/ModalDialog';
import { StoreState } from '../../../store/store';
import * as sagas from '../../../store/sagas';
import { SessionNotification } from '../../common/models/SessionNotification';
import { getDefaultBackend, getBackendClient } from '../../../web-apis/auth';
import { ConfigClient } from '../../../web-apis/config-client';
import { User } from '../../../store/user';
import PredictionModelSelect from '../PredictionModelSelect';


const NO_LICENSE_FOUND_WARNING = 'No active license found. Please contact MVision support or your local sales representative.';


type OwnProps = {
    isVisible: boolean,
    isAutoContouringInProgress: boolean,
    onNewStructureCreation: (selectedNewStructureSetOption: NewStructureSetOption, contouringAction: string) => void,
    onClose: () => void,
}

type DispatchProps = {
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
}

type AllProps = OwnProps & DispatchProps & StoreState;

type OwnState = {
    selectedNewStructureSetOption: NewStructureSetOption,
    selectedPredictionModel: PredictionModel | undefined,
    isModelOptionValid: boolean,
    isAutoContourOptionInProgressValid: boolean,
    isConfirmationDialogVisible: boolean,
    warningMessage: string | undefined,
}

export enum NewStructureSetOption {
    NoSelection, FromScratch, AutoContour
}

class NewStructureSetDialog extends React.Component<AllProps, OwnState> {
    constructor(props: AllProps) {
        super(props);
        const defaultNewStructureSetOption = NewStructureSetOption.AutoContour;
        this.state = {
            selectedNewStructureSetOption: defaultNewStructureSetOption,
            selectedPredictionModel: undefined,
            isModelOptionValid: true,
            isAutoContourOptionInProgressValid: true,
            isConfirmationDialogVisible: false,
            warningMessage: undefined,
        };
    }

    componentDidUpdate = (prevProps: AllProps) => {

        // check if user has a valid license whenever the structure set dialog is opened
        if (this.props.isVisible && this.props.isVisible !== prevProps.isVisible) {
            const backend = getDefaultBackend();

            const configClient = new ConfigClient(getBackendClient(backend));

            const user: User | undefined = this.props.user;
            if (user === undefined || user.userId === undefined) {
                throw new Error('Invalid login');
            }

            // check if current user has no license
            configClient.getLicenseQuotas(user.userId)
                .then(licenseQuotas => {
                    // show a warning to user that they have no valid licenses if there were no licenses returned
                    // or ALL the returned licenses have a quota, but they're all expired
                    if (licenseQuotas.length === 0 || licenseQuotas.every(q => q.totalQuota !== undefined && q.quotaLeft !== undefined && q.quotaLeft <= 0)) {
                        // we could send a notification, but currently this would be drawn under the modal so let's not do it. the code is still here if needed later:
                        // const noLicenseWarning = new SessionNotification(undefined, NO_LICENSE_FOUND_WARNING, NotificationType.Warning);
                        // this.props.addNotification(noLicenseWarning);

                        // instead just tag a warning block within this component
                        this.setState({ warningMessage: NO_LICENSE_FOUND_WARNING });
                    } else if (this.state.warningMessage === NO_LICENSE_FOUND_WARNING) {
                        this.setState({ warningMessage: undefined });
                    }
                });
        }
    }

    resetValidation = () => {
        this.setState({
            isModelOptionValid: true,
            isAutoContourOptionInProgressValid: true,
        });
    }

    handleCreateNewStructureSetClick = () => {
        if (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour && this.state.selectedPredictionModel) {
            this.setState({ isConfirmationDialogVisible: true });
        } else {
            this.handleClose();
        }
    }

    handleCreateNewStructureSetConfirmed = () => {
        // validate current options:
        const model = this.state.selectedPredictionModel;

        // validate that if auto-contouring is set, a model must be selected
        if (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour && !model) {
            this.setState({ isModelOptionValid: false });
            return;
        }

        // validate that if auto-contouring is already in-progress, we can't start another one
        if (this.props.isAutoContouringInProgress && this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour) {
            this.setState({ isAutoContourOptionInProgressValid: false });
            return;
        }

        this.resetValidation();

        // get the action name of selected model, or the entered label if this is a free text entry model
        const action = model ? model.isFreeTextEntryModel ? model.label : model.id : "";

        // proceed if everything looks good
        // delay the the activation a bit so the dialog has time to close before possible auto-contouring is enabled. this will avoid
        // a case where any "auto-contouring is already in-progress" warnings quickly flash on the dialog that's already closing
        setTimeout(() => { this.props.onNewStructureCreation(this.state.selectedNewStructureSetOption, action); }, 100);
        this.handleClose();
    }

    handleClose = () => {
        this.setState({ isConfirmationDialogVisible: false });
        this.props.onClose();
    }

    handleOptionChange = (selectedNewStructureSetOption: NewStructureSetOption) => {
        let removeModelValidation = {};
        if (selectedNewStructureSetOption !== NewStructureSetOption.AutoContour) {
            removeModelValidation = { isModelOptionValid: true, isAutoContourOptionInProgressValid: true };
        }
        this.setState({ selectedNewStructureSetOption, ...removeModelValidation });
    }

    handleModelChange = (model: PredictionModel | undefined) => {
        this.setState({ selectedPredictionModel: model, isModelOptionValid: true });
        this.selectAutoContouring();
    }

    selectAutoContouring = () => {
        this.setState({ selectedNewStructureSetOption: NewStructureSetOption.AutoContour });
    }

    render() {
        const { warningMessage } = this.state;

        let autoContouringInProgressWarningStyle = 'autocontour-in-progress';
        if (!this.state.isAutoContourOptionInProgressValid) { autoContouringInProgressWarningStyle += ' autocontour-not-available' }

        return (
            <>
                <ModalDialog
                    scrollable
                    size="lg"
                    show={this.props.isVisible && !this.state.isConfirmationDialogVisible}
                    onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New structure set</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="new-structure-set-modal-body">

                        {warningMessage && (
                            <div>
                                <Alert variant="warning">{warningMessage}</Alert>
                            </div>
                        )}

                        <Form>
                            <Form.Group className="new-structure-set-radio-options">
                                <PredictionModelSelect
                                    handleModelChange={this.handleModelChange}
                                    selectAutoContouring={this.selectAutoContouring}
                                    currentPredictionModel={this.state.selectedPredictionModel}
                                    isAutoContouringInProgress={this.props.isAutoContouringInProgress}
                                    isModelOptionValid={this.state.isModelOptionValid}
                                    autoContouringInProgressWarningStyle={autoContouringInProgressWarningStyle}
                                />
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-primary"
                            onClick={this.handleCreateNewStructureSetClick}
                            disabled={this.props.isAutoContouringInProgress ||
                                (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour &&
                                    (!this.state.selectedPredictionModel || !this.state.selectedPredictionModel.modelName))}
                        >
                            Create structure set
                        </Button>
                        <Button variant="outline-secondary" onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </ModalDialog>

                {this.state.selectedPredictionModel && (
                    <ModalDialog
                        show={this.state.isConfirmationDialogVisible}
                        backdrop="static"
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm prediction model</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>You are about to use the prediction model <b>{this.state.selectedPredictionModel.label}</b>. Do you wish to proceed?</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={this.handleCreateNewStructureSetConfirmed}>Create structure set with {this.state.selectedPredictionModel.label}</Button>
                            <Button variant="outline-secondary" onClick={this.handleClose}>Cancel</Button>
                        </Modal.Footer>
                    </ModalDialog>
                )}
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(NewStructureSetDialog);
