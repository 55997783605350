import { AppVersionInfo } from "../store/app-version-info";
import { DeploymentConfigInfo, generateDeploymentConfigInfo } from "../store/deployment-config-info";

// RTViewer functions that don't use a backend API
const versionFile = '/version.json';

// deployment-specific config file
const configFile = '/config.json';

export class RTViewerWebClient {

    public static async getAppVersionInfo(): Promise<AppVersionInfo | undefined> {
        const url = versionFile;

        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(url, { cache: 'no-store' });
                if (response.status === 200) {
                    const versionJson = await response.json();
                    if ('commit' in versionJson && 'date' in versionJson && 'buildEnv' in versionJson) {
                        resolve(new AppVersionInfo(versionJson.commit, versionJson.date, versionJson.buildEnv));
                    } else {
                        const error = "Received version.json has invalid properties";
                        console.log(error);
                        console.log(versionJson);
                        reject(error);
                    }
                }
                else {
                    const error = "Could not get version.json, HTTP error status: " + response.status;
                    console.log(error);
                    reject(error);
                }
            }
            catch (err) {
                // non-fatal error -- let the application continue
                console.warn('Failed to fetch version.json:');
                console.warn(err);
                resolve(undefined);
            }
        });
    }

    /** Gets deployment info from deployment's config.json file. CURRENTLY UNUSED! */
    public static async getDeploymentConfigInfo(): Promise<DeploymentConfigInfo> {
        const url = configFile;

        return new Promise(async (resolve, reject) => {
            const response = await fetch(url, { cache: 'no-store' });
            if (response.status === 200) {
                try {
                    const configJson = await response.json();
                    const configInfo = generateDeploymentConfigInfo(configJson);
                    if (configInfo !== undefined) {
                        resolve(configInfo);
                    } else {
                        const error = "Received config.json has invalid properties";
                        console.log(error);
                        console.log(configJson);
                        reject(error);
                    }
                }
                catch (err) {
                    console.log('An error occurred when trying to get deployment configuration file:')
                    console.error(err);
                    reject(err);
                }
            }
            else {
                const error = "Could not get config.json, HTTP error status: " + response.status;
                console.log(error);
                reject(error);
            }
        });
    }
}