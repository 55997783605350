/** Returns a new random GUID. Don't use these in DICOM -- use generateRandomUID instead.
 * TODO: unsure if this returns actually valid GUIDs in case that's relevant for the use case.
 */
export function newGuid(): string {
    function S4(): string {
        return (((1 + Math.random()) * 0x10000) | 0).toString(10).substring(1);
    }

    return S4() + S4() + "." + S4() + ".4" + S4().substr(0, 3) + "." + S4() + "." + S4() + S4() + S4();
}

/**
 * Generates a portion of a random UID for DICOM use. The leading digit will never be a 0 if n has been
 * set to something other than 1.
 * @param n Number of digits to generate into UID.
 * @param isRecursive Set to true when this function loops recursively. Don't set this parameter manually.
 */
export function generateRandomUID(n: number, isRecursive = false): string {
    // generate random UID (0-9) of a fixed length
    let add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.
    let uid: string;

    if (n > max) {
        uid = generateRandomUID(max, true) + generateRandomUID(n - max, true);
    } else {
        max = Math.pow(10, n + add);
        let min = max / 10;
        let number = Math.floor(Math.random() * (max - min + 1)) + min;
        uid = number.toString().substring(add);
    }

    if (!isRecursive && n !== 1) {
        // if we're at the root of the recursion, don't start UID with a leading zero (unless n == 1)
        return uid.charAt(0) !== '0' ? uid : getRandomInt(1, 9).toString() + uid.substring(1);
    } else {
        return uid;
    }
}

/** Generates a random UID for DICOM use. */
export function generateUid(): string {
    // http://dicom.nema.org/dicom/2013/output/chtml/part05/chapter_C.html
    return "2.25." + generateRandomUID(39)
}

/** Generates a random integer between min and max (inclusive). */
export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
