import { ViewerState } from "../viewer-state";
import { View, Plane } from "../view";
import { createImageTexture } from "./image-texture";
import * as twgl from 'twgl.js';
import * as imageShaders from './image-shaders';
import * as contourShaders from './contour-shaders';
import { newGuid } from "../../dicom/guid";
import {createVertexBuffers} from "./create-vertex-buffers";
import { mouseTools } from "../mouse-tools/mouse-tools";
import { Sdf } from "./sdf/sdf";
import { Roi, Point } from "../../dicom/structure-set";
import { flattenArray } from "../../util";

export class MainRenderer {
    viewerState: ViewerState;
    secondaryCanvas: HTMLCanvasElement;

    imageTexture: WebGLTexture;

    imageProgram: WebGLProgram;
    imageUniformLoc: any;

    contourProgram: WebGLProgram;
    contourUniformLoc: any;

    constructor(vs: ViewerState, gl: any, secondaryCanvas: HTMLCanvasElement) {
        this.viewerState = vs;
        this.secondaryCanvas = secondaryCanvas;
        
        this.imageTexture = createImageTexture(gl, vs.image);

        this.imageProgram = twgl.createProgramInfo((gl as any), [imageShaders.IMAGE_VS, imageShaders.IMAGE_FS]).program;
        this.imageUniformLoc = {
            textureMatrix: gl.getUniformLocation(this.imageProgram, 'orientation'),
            textureData: gl.getUniformLocation(this.imageProgram, 'textureData'),
            windowWidth: gl.getUniformLocation(this.imageProgram, 'ww'),
            windowCenter: gl.getUniformLocation(this.imageProgram, 'wc')
        }
        
        this.contourProgram = twgl.createProgramInfo((gl as any), [contourShaders.CONTOUR_VS, contourShaders.CONTOUR_FS]).program;
        this.contourUniformLoc = {
            textureMatrix: gl.getUniformLocation(this.contourProgram, 'orientation'),
            textureData: gl.getUniformLocation(this.contourProgram, 'textureData'),
            textureSize: gl.getUniformLocation(this.contourProgram, 'textureSize'),
            imageRect: gl.getUniformLocation(this.contourProgram, 'imageRect'),
            roiColor: gl.getUniformLocation(this.contourProgram, 'roiColor'),
            debugMode: gl.getUniformLocation(this.contourProgram, 'debugMode'),
            samplingDist: gl.getUniformLocation(this.contourProgram, 'samplingDist'),
            flip: gl.getUniformLocation(this.contourProgram, 'flip'),
            viewingPlane: gl.getUniformLocation(this.contourProgram, 'viewingPlane')
        }

        this.renderFrame();
    }

    renderFrame() {
        
        let that = (this as any);
        let delayMs = 5;

        const renderFunc = () => {
            try {
                let d = Date.now();
                that.clear();
                that.renderImage();
                that.renderAllRois();
                that.renderAllMarkers();
                that.renderMouseToolsWebGL(); // e.g. brush draw buffer
                that.renderMouseToolsCanvas(); // e.g. brush pointer
                that.lastRender = Date.now();
                //console.log(that.lastRender - d);
            }
            catch(error) {
                console.log(error);
            }
        }

        const delayedRenderFunc = () => {
            let id = newGuid();
            that.latestRenderQuery = id;
            setTimeout(() => {
                if(that.latestRenderQuery === id || that.lastRender + delayMs < Date.now()) {
                    renderFunc();
                }
            }, delayMs);
        }

        if(!that.lastRender || that.lastRender + delayMs < Date.now()) {
            renderFunc();
        }
        else {
            delayedRenderFunc();
        }
    }

    clear() {
        let vm = this.viewerState.viewManager;
        let gl = vm.getWebGlContext();
        let shade = 0.1;
        gl.clearColor(shade, shade, shade, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
    }

    renderImage() {
        let vs = this.viewerState;
        let vm = vs.viewManager;
        let gl = vm.getWebGlContext();
        gl.useProgram(this.imageProgram);
        gl.uniform1f(this.imageUniformLoc.windowWidth, vs.windowLevel.ww);
        gl.uniform1f(this.imageUniformLoc.windowCenter, vs.windowLevel.wc);
        gl.uniform1i(this.imageUniformLoc.textureData, 0);
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_3D, this.imageTexture);

        let imageVertexPositions =  flattenArray(vm.views.map((v: View) => v.getRectangle(null)));
        let imageTextureCoords = flattenArray( vm.views.map((v: View) => rectangleCoords) );
        gl.bindVertexArray(createVertexBuffers(gl, imageVertexPositions, imageTextureCoords));

        for (let i = 0; i < vm.views.length; ++i) {
            let view = vm.views[i];
            let vp = view.availableViewport;
            const left = Math.round(vp.left);
            const top = Math.round(vp.top);
            const width = Math.round(vp.width);
            const height = Math.round(vp.height);
            gl.viewport(left, top, width, height);
            gl.uniformMatrix4fv(this.imageUniformLoc.textureMatrix, false, view.getImageSamplingMatrix());
            gl.drawArraysInstanced(gl.TRIANGLES, i*6, 6, 1);
        }
    }

    renderSdf(sdf: Sdf, roi: Roi) {
        let vs = this.viewerState;
        let vm = vs.viewManager;
        let gl = vm.getWebGlContext();
        let img = vs.image;
        let ss = vs.selectedStructureSet;
        if(!ss || ( vs.hiddenRois[ss.structureSetId] && vs.hiddenRois[ss.structureSetId].has(roi.roiNumber) ) ) return;

        gl.useProgram(this.contourProgram);
        gl.enable(gl.BLEND);
        gl.blendEquation(gl.FUNC_ADD);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        gl.uniform1f(this.contourUniformLoc.debugMode, vs.debugMode ? 1 : 0);

        gl.uniform1i(this.contourUniformLoc.textureData, 0);
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_3D, sdf.data);

        gl.uniform3fv(this.contourUniformLoc.textureSize, sdf.size);
        for (let i = 0; i < vm.views.length; ++i) {
            let view = vm.views[i];
            let vp = view.availableViewport;
            
            // samplingMatrix is null when bouding box would not be in the drawn viewport
            let samplingMatrix = view.getRoiSamplingMatrix(sdf);
            if(samplingMatrix) {
                let roiRect = view.getRectangle(sdf);
                gl.bindVertexArray(createVertexBuffers(gl, roiRect, rectangleCoords));
                const left = Math.round(vp.left);
                const top = Math.round(vp.top);
                const width = Math.round(vp.width);
                const height = Math.round(vp.height);
                gl.viewport(left, top, width, height);
                gl.uniformMatrix4fv(this.contourUniformLoc.textureMatrix, false, samplingMatrix);

                // Get image rectangle. ROIs will not be drawn outside the image boundaries
                let r = view.getRectangle(null);
                let imageRect = [
                    r[5], r[2], r[1], r[0]
                ];
                gl.uniform4fv(this.contourUniformLoc.imageRect, imageRect);
                let c = roi.color;
                gl.uniform4fv(this.contourUniformLoc.roiColor, [c[0]/255, c[1]/255, c[2]/255, 1]);

                let edgeWidth = (( roi === vs.selectedRoi || roi === vs.hoveredRoi) ? vs.lineWidthSelected : vs.lineWidth);

                gl.uniform1f(this.contourUniformLoc.viewingPlane, view.plane);

                let w = 0, h = 0;
                const xInv = img.orientationMatrix[0] < 0;
                const yInv = img.orientationMatrix[4] < 0;
                if (view.plane === Plane.Transversal) {
                    w = sdf.boundingBox.getXSize();
                    h = sdf.boundingBox.getYSize();
                    gl.uniform2fv(this.contourUniformLoc.flip, [xInv ? 1 : 0, yInv ? 1 : 0]);
                }
                else if (view.plane === Plane.Coronal) {
                    w = sdf.boundingBox.getXSize();
                    h = sdf.boundingBox.getZSize();
                    gl.uniform2fv(this.contourUniformLoc.flip, [xInv ? 1 : 0, 0]);
                } 
                else if(view.plane === Plane.Sagittal) {
                    w = sdf.boundingBox.getYSize();
                    h = sdf.boundingBox.getZSize();
                    gl.uniform2fv(this.contourUniformLoc.flip, [yInv ? 1 : 0, 0]);
                }
                else {
                    throw new Error("Unknown plane");
                }
                gl.uniform2fv(this.contourUniformLoc.samplingDist, [
                    (edgeWidth / view.pixelsPerMm) / w,
                    (edgeWidth / view.pixelsPerMm) / h 
                 ]);

                gl.drawArrays(gl.TRIANGLES, 0, 6);
            }
        }
        
        gl.disable(gl.BLEND);
    }

    renderAllRois() {
        let vs = this.viewerState;
        let ss = vs.selectedStructureSet;
        if(!ss) return;
        ss.getRois().forEach(roi => {
            if(vs.activeMouseTools.includes(mouseTools.brush) && mouseTools.brush.roi === roi) {
                return; // Draw the brush buffer instead of the roi contour
            } 
            if(roi.sdf) this.renderSdf(roi.sdf, roi);
        });
    }

    renderAllMarkers() {
        const vs = this.viewerState;
        const vm = vs.viewManager;
        const ss = vs.selectedStructureSet;
        const img = vm.image;
        const canvas = this.secondaryCanvas;
        const ctx = canvas.getContext("2d");
        if(!ctx || !ss) return;
        const dist = (a: number, b: number) => {return Math.abs(a - b)};
        ctx.clearRect(-5000, -5000, 10000, 10000);
        
        const drawCross = (view: View, roi: Roi, point: Point) =>{
            const lengthMm = 4; 
            const length = lengthMm * view.pixelsPerMm
            const pt = view.getPointInCanvasCoord(canvas, point);
            const color = roi.rgb();
            ctx.strokeStyle = color;
            ctx.lineWidth = (roi === vs.selectedRoi || roi === vs.hoveredRoi) ? 2.5 : 1;
            ctx.beginPath();
            ctx.moveTo(pt[0] - length, pt[1]);
            ctx.lineTo(pt[0] + length, pt[1]);
            ctx.closePath();
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(pt[0], pt[1] - length);
            ctx.lineTo(pt[0], pt[1] + length);
            ctx.closePath();
            ctx.stroke();
        }
        for (let i = 0; i < vm.views.length; ++i) {
            const view = vm.views[i];
            const ssContours = ss.contourData;
            Object.keys(ssContours).forEach(roiNr => {
                const roiContours = ssContours[roiNr];
                const roi = ss.rois[roiNr];
                Object.keys(roiContours).forEach(sliceId => {
                    const points = roiContours[sliceId].points;
                    if(points) {
                        points.forEach(point => {
                            if(view.plane === Plane.Transversal) {
                                if( dist( point[2], vm.getScrollPositionsMm()[2] ) < img.kSpacing / 2) {
                                    drawCross(view, roi, point);
                                }
                            }
                            else if(view.plane === Plane.Coronal) {
                                if( dist( point[1], vm.getScrollPositionsMm()[1] ) < img.jSpacing / 2) {
                                    drawCross(view, roi, point);
                                }
                            }
                            else if (view.plane === Plane.Sagittal) {
                                if( dist( point[0], vm.getScrollPositionsMm()[0] ) < img.iSpacing / 2) {
                                    drawCross(view, roi, point);
                                }
                            }
                        })
                    }
                });
            });
        }
    }

    renderMouseToolsWebGL() {
        let vs = this.viewerState;
        if(vs.activeMouseTools.includes(mouseTools.brush)) {
            let roi = mouseTools.brush.roi;
            let brushBuffer = mouseTools.brush.brushBuffer;
            if(!roi || !brushBuffer) return;
            this.renderSdf(brushBuffer.sdf, roi);
        }
    }

    renderMouseToolsCanvas() {
        let vs = this.viewerState;
        let vm = vs.viewManager;
        let canvas = this.secondaryCanvas;
        let ctx = canvas.getContext("2d");
        if(!ctx) return;
        for (let i = 0; i < vm.views.length; ++i) {
            let view = vm.views[i];
            vs.activeMouseTools.forEach((tool: any) => tool.drawOnCanvas(canvas, view));
        }
    }
}

const rectangleCoords = [
    0.0, 1.0,
    1.0, 1.0,
    1.0, 0.0,
    1.0, 0.0,
    0.0, 0.0,
    0.0, 1.0
];