import _ from "lodash";

export type LabelingInfo = {
    clinical: boolean;
    description: string;
    manufacturer: string;
    manufacturerModelName: string;
    systemVersion: string;
    udi: string;
};

export const convertJsonToLabeling = (jsonObject: any): LabelingInfo | undefined => {
    if ('clinical' in jsonObject &&
        _.isBoolean(jsonObject['clinical']) &&
        'description' in jsonObject &&
        'manufacturer' in jsonObject &&
        'manufacturer_model_name' in jsonObject &&
        'system_version' in jsonObject &&
        'udi' in jsonObject) {
        return {
            clinical: jsonObject['clinical'],
            description: jsonObject['description'],
            manufacturer: jsonObject['manufacturer'],
            manufacturerModelName: jsonObject['manufacturer_model_name'],
            systemVersion: jsonObject['system_version'],
            udi: jsonObject['udi'],
        }
    }

    return undefined;
}
