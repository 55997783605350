import _ from "lodash";
import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { defaultAuths, getAppName, NOT_FOR_CLINICAL_USE_LABELING, DISPLAY_VERSION } from "../../../environments";
import { getAreConcurrentUploadsEnabled, setAreConcurrentUploadsEnabled, getIsMonacoDirFileSupportEnabled, setIsMonacoDirFileSupportEnabled, getIsDisplayingDicomMetadataOnMainViewEnabled, setIsDisplayingDicomMetadataOnMainViewEnabled } from "../../../local-storage";

import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import { User } from "../../../store/user";
import AppAuth from "../../../web-apis/app-auth";
import { Backend } from "../../../web-apis/backends";
import { Checkbox } from "../../misc-components";
import ModalDialog from "../ModalDialog";

import './UserSettingsDialog.css';
import { DirFileSupport } from "../../../store/deployment-config-info";
import { AppVersionInfo } from "../../../store/app-version-info";

type OwnProps = {}

type DispatchProps = {
    setUserSettingsDialogVisibility(value: boolean): void,
    setUserSettingBackend(backend: Backend | null): void,
    logIntoAppAuth: (appAuth: AppAuth) => void,
    requestLogOut: () => void,
}

type OwnState = {
    /** These state props are only used to get the UserSettings react component to self-update. 
     * The actual values are read from and written to localStorage. */
    areConcurrentUploadsEnabledHack: boolean,
    isMonacoDirFileSupportEnabledHack: boolean,
    isDisplayingDicomMetadataOnMainViewEnabledHack: boolean,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class UserSettingsDialog extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            areConcurrentUploadsEnabledHack: getAreConcurrentUploadsEnabled(),
            isMonacoDirFileSupportEnabledHack: getIsMonacoDirFileSupportEnabled(),
            isDisplayingDicomMetadataOnMainViewEnabledHack: getIsDisplayingDicomMetadataOnMainViewEnabled(),
        };
    }

    isDefaultAppAuth = (appAuth: AppAuth) => {
        return defaultAuths.includes(appAuth.appName);
    }

    handleClose = () => {
        this.props.setUserSettingsDialogVisibility(false);
    }

    handleLogOutClick = () => {
        this.props.requestLogOut();
    }

    handleLogInClick = (appAuth: AppAuth) => {
        this.props.logIntoAppAuth(appAuth);
    }

    handleUploadConcurrencyChange = () => {
        const newValue = !getAreConcurrentUploadsEnabled();
        setAreConcurrentUploadsEnabled(newValue);
        this.setState({ areConcurrentUploadsEnabledHack: newValue });
    }

    handleMonacoDirFileSupportChange = () => {
        const newValue = !getIsMonacoDirFileSupportEnabled();
        setIsMonacoDirFileSupportEnabled(newValue);
        this.setState({ isMonacoDirFileSupportEnabledHack: newValue });
    }

    handleDisplayDicomMetadataOnMainViewChange = () => {
        const newValue = !getIsDisplayingDicomMetadataOnMainViewEnabled();
        setIsDisplayingDicomMetadataOnMainViewEnabled(newValue);
        this.setState({ isDisplayingDicomMetadataOnMainViewEnabledHack: newValue });
    }

    handleViewDocumentClick = (link: string) => {
        if (this.props.deploymentConfigInfo) {
            window.open(link, '_blank');
        }
    }


    render() {
        const isVisible = this.props.isUserSettingsDialogVisible;

        const { labeling, deploymentConfigInfo } = this.props;

        const user = this.props.user as User;
        const version: AppVersionInfo | undefined = this.props.appVersion;

        const areConcurrentUploadsEnabled = getAreConcurrentUploadsEnabled();
        const isMonacoDirFileSupportEnabled = getIsMonacoDirFileSupportEnabled();
        const isDisplayingDicomMetadataOnMainViewEnabled = getIsDisplayingDicomMetadataOnMainViewEnabled();

        const isDirFileSupportVisible = deploymentConfigInfo && deploymentConfigInfo.dirFileSupport !== DirFileSupport.Disabled;
        const isDirFileSupportExperimental = deploymentConfigInfo && deploymentConfigInfo.dirFileSupport === DirFileSupport.Experimental;

        return (
            <ModalDialog
                show={isVisible}
                onHide={this.handleClose}
                size="lg">

                <Modal.Header closeButton>
                    <Modal.Title>User Session Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body className="user-settings">
                    <Form>
                        <Form.Group>
                            <h3>Local settings</h3>

                            <div className="notice">
                                These settings apply to every session/tab of {getAppName()} in this browser.
                                <br />
                                Note that some of these settings won't be applied until after you refresh this page/tab.
                            </div>

                            <Form.Row onClick={this.handleDisplayDicomMetadataOnMainViewChange} className="setting-item no-selection">
                                <Col lg={8}>
                                    <div className="setting-title">Display DICOM metadata information on main view</div>
                                    <div className="setting-description">Display DICOM metadata such as Patient ID, Series Description, etc. on main view.</div>
                                </Col>
                                <Col className="value-row-shift-checkbox">
                                    <Checkbox isSelected={isDisplayingDicomMetadataOnMainViewEnabled} />
                                </Col>
                            </Form.Row>

                            <Form.Row onClick={this.handleUploadConcurrencyChange} className="setting-item no-selection">
                                <Col lg={8}>
                                    <div className="setting-title">Enable concurrent uploads</div>
                                    <div className="setting-description">Disabling this option will limit file uploads to one at a time. Please reload the page for changes to this setting to take effect.</div>
                                </Col>
                                <Col className="value-row-shift-checkbox">
                                    <Checkbox isSelected={areConcurrentUploadsEnabled} />
                                </Col>
                            </Form.Row>

                            {isDirFileSupportVisible && (
                                <Form.Row onClick={this.handleMonacoDirFileSupportChange} className="setting-item no-selection">
                                    <Col lg={8}>
                                        <div className="setting-title">Enable RTDIR file support {isDirFileSupportExperimental ? '(experimental feature)' : ''}</div>
                                        <div className="setting-description">Enabling this option will allow downloading of RTDIR files for structure sets.</div>
                                    </Col>
                                    <Col className="value-row-shift-checkbox">
                                        <Checkbox isSelected={isMonacoDirFileSupportEnabled} />
                                    </Col>
                                </Form.Row>
                            )}

                        </Form.Group>

                        {labeling !== undefined && (
                            <>
                                <hr />

                                <Form.Group>
                                    <h3>Labeling</h3>

                                    <div className="notice"><span className="local-page-not-for-clinical">{NOT_FOR_CLINICAL_USE_LABELING}.</span></div>

                                    <Form.Row>
                                        <Col lg={6}><b>System version</b></Col><Col>{labeling.systemVersion}</Col>
                                        <Col lg={6}><b>Manufacturer</b></Col><Col>{labeling.manufacturer}</Col>
                                        <Col lg={6}><b>Manufacturer model name</b></Col><Col>{labeling.manufacturerModelName}</Col>
                                        <Col lg={6}><b>Description</b></Col><Col>{labeling.description}</Col>
                                        <Col lg={6}><b>Clinical</b></Col><Col>{labeling.clinical ? 'For clinical use.' : 'For non-clinical use.'}</Col>
                                        <Col lg={6}><b>UDI</b></Col><Col>{labeling.udi}</Col>
                                    </Form.Row>

                                </Form.Group>
                            </>
                        )}

                        <hr />

                        <Form.Group>
                            <Form.Row className="logout-button">
                                <Col lg={6}>
                                    <div>Logged in as <b><span title={user.email}>{user.username}</span></b></div>
                                </Col>
                                <Col className="value-row-shift-checkbox">
                                    <Button variant="danger" onClick={() => this.handleLogOutClick()}>Log out of {getAppName()}</Button>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    Version: {DISPLAY_VERSION}{!!version && (<span className="version-commit-id">({version.commit})</span>)}
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        {deploymentConfigInfo && deploymentConfigInfo.documentLinks && 
                        <Form.Group>
                            <Form.Row>
                                <Col>
                                    <h3>Documents</h3>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    {deploymentConfigInfo.documentLinks.map((link, index) => (
                                        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="terms-document-link">
                                            {link.label}
                                        </a>
                                    ))}
                                </Col>
                            </Form.Row>
                        </Form.Group>}

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-info" onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(UserSettingsDialog);
