import _ from "lodash";
import Immerable from "../store/immerable";

/* file info objects for keeping track of azure files and paths */

export interface AzureStorageAccountInfo {
    storageAccountName: string;

    toString(): string;
    equals(other: AzureStorageAccountInfo): boolean;
}

export function instanceOfAzureStorageAccountInfo(object: any): object is AzureStorageAccountInfo {
    return _.isObject(object) && 'storageAccountName' in object && 'toString' in object && 'equals' in object;
}

/**
 * Represents the location of a file in an azure file share.
 */
export class AzureFileInfo extends Immerable implements AzureStorageAccountInfo {
    public storageAccountName: string;
    public fileShareName: string;
    public path: string;
    public filename: string;

    constructor(path: AzurePathInfo, filename: string)
    constructor(share: AzureShareInfo, path: string, filename: string)
    constructor(storageAccountName: string, fileShareName: string, path: string, filename: string)
    constructor(azurePathOrAzureShareOrStorageAccount: AzurePathInfo | AzureShareInfo | string,
        filenameOrPathOrFileShareName: string, filenameOrPath?: string, filename?: string) {
        super();

        // detect which constructor overload we're using
        if (azurePathOrAzureShareOrStorageAccount instanceof AzurePathInfo) {
            const path = azurePathOrAzureShareOrStorageAccount;
            this.storageAccountName = path.storageAccountName;
            this.fileShareName = path.fileShareName;
            this.path = path.path;
            this.filename = filenameOrPathOrFileShareName;
        }
        else if (azurePathOrAzureShareOrStorageAccount instanceof AzureShareInfo && filenameOrPath !== undefined) {
            const share = azurePathOrAzureShareOrStorageAccount;
            this.storageAccountName = share.storageAccountName;
            this.fileShareName = share.fileShareName;
            this.path = filenameOrPathOrFileShareName;
            this.filename = filenameOrPath;
        }
        else if (_.isString(azurePathOrAzureShareOrStorageAccount) && filenameOrPath !== undefined && filename !== undefined) {
            this.storageAccountName = azurePathOrAzureShareOrStorageAccount;
            this.fileShareName = filenameOrPathOrFileShareName;
            this.path = filenameOrPath;
            this.filename = filename;
        }
        else {
            throw new Error('Unexpected arguments for AzureFileInfo constructor');
        }

    }

    toString(): string {
        return `${this.storageAccountName}/${this.fileShareName}/${this.path}/${this.filename}`;
    }

    equals(other: AzureStorageAccountInfo): boolean {
        if (other instanceof AzureFileInfo === false) {
            return false;
        }
        const otherFileInfo = other as AzureFileInfo;
        return this.storageAccountName === otherFileInfo.storageAccountName
            && this.fileShareName === otherFileInfo.fileShareName
            && this.path === otherFileInfo.path
            && this.filename === otherFileInfo.filename;
    }

    getPath(): AzurePathInfo {
        return new AzurePathInfo(this.storageAccountName, this.fileShareName, this.path);
    }

    getShare(): AzureShareInfo {
        return new AzureShareInfo(this.storageAccountName, this.fileShareName);
    }

    /** Creates a copy of this file info object but for a possibly different storage account */
    copy(storageAccountName?: string): AzureFileInfo {
        return new AzureFileInfo(storageAccountName !== undefined ? storageAccountName : this.storageAccountName, this.fileShareName, this.path, this.filename);
    }
}

/**
 * Represents a path location in an azure file share.
 */
export class AzurePathInfo extends Immerable implements AzureStorageAccountInfo {
    public storageAccountName: string;
    public fileShareName: string;
    public path: string;

    constructor(share: AzureShareInfo, path: string);
    constructor(storageAccountName: string, fileShareName: string, path: string);
    constructor(azureShareOrStorageAccount: AzureShareInfo | string, pathOrFileShareName: string, path?: string) {
        super();

        // detect which constructor overload we're using
        if (azureShareOrStorageAccount instanceof AzureShareInfo) {
            this.storageAccountName = azureShareOrStorageAccount.storageAccountName;
            this.fileShareName = azureShareOrStorageAccount.fileShareName;
            this.path = pathOrFileShareName;
        }
        else if (_.isString(azureShareOrStorageAccount) && path !== undefined) {
            this.storageAccountName = azureShareOrStorageAccount;
            this.fileShareName = pathOrFileShareName;
            this.path = path;
        }
        else {
            throw new Error('Unexpected arguments for AzurePathInfo constructor');
        }
    }

    toString(): string {
        return `${this.storageAccountName}/${this.fileShareName}/${this.path}`;
    }

    equals(other: AzureStorageAccountInfo): boolean {
        if (other instanceof AzurePathInfo === false) {
            return false;
        }
        const otherPathInfo = other as AzurePathInfo;
        return this.storageAccountName === otherPathInfo.storageAccountName
            && this.fileShareName === otherPathInfo.fileShareName
            && this.path === otherPathInfo.path;
    }

    getFile(filename: string): AzureFileInfo {
        return new AzureFileInfo(this, filename);
    }

    getShare(): AzureShareInfo {
        return new AzureShareInfo(this.storageAccountName, this.fileShareName);
    }

    /** Creates a copy of this path info object but for a possibly different storage account */
    copy(storageAccountName?: string): AzurePathInfo {
        return new AzurePathInfo(storageAccountName !== undefined ? storageAccountName : this.storageAccountName, this.fileShareName, this.path);
    }
}

/**
 * Represents an azure file share. These are often physical azure disk locations of Datasets.
 */
export class AzureShareInfo extends Immerable implements AzureStorageAccountInfo {
    public storageAccountName: string;
    public fileShareName: string;

    constructor(storageAccountName: string, fileShareName: string) {
        super();

        this.storageAccountName = storageAccountName;
        this.fileShareName = fileShareName;
    }

    toString(): string {
        return `${this.storageAccountName}/${this.fileShareName}`;
    }

    equals(other: AzureStorageAccountInfo): boolean {
        if (other instanceof AzureShareInfo === false) {
            return false;
        }
        const otherShareInfo = other as AzureShareInfo;
        return this.storageAccountName === otherShareInfo.storageAccountName
            && this.fileShareName === otherShareInfo.fileShareName;
    }

    getPath(path: string): AzurePathInfo {
        return new AzurePathInfo(this.storageAccountName, this.fileShareName, path);
    }

    getFile(path: string, filename: string): AzureFileInfo {
        return new AzureFileInfo(this.storageAccountName, this.fileShareName, path, filename);
    }

    /** Creates a copy of this share info object but for a possibly different storage account */
    copy(storageAccountName?: string): AzureShareInfo {
        return new AzureShareInfo(storageAccountName !== undefined ? storageAccountName : this.storageAccountName, this.fileShareName);
    }
}
