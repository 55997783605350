import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import remarkGfm from "remark-gfm";

import { getAppName, } from "../../../environments";
import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import { User } from "../../../store/user";
import ModalDialog from "../ModalDialog";


import './HelpDialog.css';

type OwnProps = {}

type DispatchProps = {
    setHelpDialogVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class HelpDialog extends React.PureComponent<AllProps> {

    handleClose = () => {
        this.props.setHelpDialogVisibility(false);
    }

    render() {
        const isVisible = this.props.isHelpDialogVisible;

        const user = this.props.user as User;

        const markdown = `
## Supported hotkeys for ${getAppName()}

### All scan views

| Hotkey             | Explanation |
| ------------------ | ----------- |
| Mouse wheel        | Scroll the image stack up or down. |
| Up or down arrow   | Scroll the image stack up or down. |
| Ctrl/Cmd + mouse wheel | Zoom the view in or out. |
| Ctrl/Cmd + Up or down arrow | Zoom the view in or out. |


### Pan/Select tool

| Hotkey                       | Explanation |
| ---------------------------- | ----------- |
| Click and drag               | Pan the view. |
| Click on any contour outline | Select the structure. |

### Windowing (WW/WC) tool

| Hotkey                       | Explanation |
| ---------------------------- | ----------- |
| Click and drag left or right | Adjust the window width value. |
| Click and drag up or down    | Adjust the window center value. |

`;

        return (
            <ModalDialog
                show={isVisible}
                onHide={this.handleClose}
                size="lg">

                <Modal.Header closeButton>
                    <Modal.Title>{getAppName()} help</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={markdown} className="help-dialog-markdown" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-info" onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(HelpDialog);
