import React from 'react';

type OwnProps = {
    toolbar?: React.ReactNode | undefined,
    className?: string | undefined,
}


class LandingPageLayout extends React.Component<OwnProps> {
    render() {

        return (
            <div className={`local-page-container home-page ${this.props.className || ''}`}>
                <div className="background">
                </div>

                {this.props.toolbar}

                <div className="panels">

                    <div className="home-panel">

                        <div className="home-logo">
                            <img src="/img/contour-logo.svg" alt='contour+ logo' className="home-logo-image" />
                        </div>
                        <div>
                            {this.props.children}
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default LandingPageLayout;
