// a component for displaying a structure set that's currently being generated in the API

import React from 'react';
import { differenceInSeconds } from 'date-fns';
import { getDurationString } from '../../util';
import { Spinner, ProgressBar, Modal, Table, Button } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io';

import './ContouringRequest.css';
import { ContouringTaskState, ContouringTask } from '../../store/contouring-task';
import ModalDialog from '../common/ModalDialog';
import { getSessionId } from '../../environments';

type OwnProps = {
  contouringTask: ContouringTask,
  dismissContouringTask: (scanId: string) => void,
}

type OwnState = {
  timerId: number | null,
  elapsedTimeInSeconds: number,
  showErrorModal: boolean,
}

class ContouringRequest extends React.Component<OwnProps, OwnState> {

  private static inProgressTaskStates: ContouringTaskState[] = [
    ContouringTaskState.PollingForResults,
    ContouringTaskState.DownloadingFiles,
  ];

  private static taskFinishedStates: ContouringTaskState[] = [
    ContouringTaskState.Success,
    ContouringTaskState.Failed,
    ContouringTaskState.Error,
  ];

  constructor(props: OwnProps) {
    super(props);
    this.state = {
      timerId: null,
      elapsedTimeInSeconds: 0,
      showErrorModal: false,
    };
  }

  componentDidMount() {
    const timerId = window.setInterval(() => {
      const { contouringTask } = this.props;
      const elapsedTimeInSeconds = (this.isContouringTaskInProgress() && contouringTask.uploadFinishTime) ? differenceInSeconds(new Date(), contouringTask.uploadFinishTime) : 0;
      this.setState({ elapsedTimeInSeconds });
    }, 1000);
    this.setState({ timerId });
  }

  componentWillUnmount() {
    if (this.state.timerId) {
      window.clearInterval(this.state.timerId);
    }
  }

  isContouringTaskInProgress = () => {
    return ContouringRequest.inProgressTaskStates.includes(this.props.contouringTask.contouringState);
  }

  isContouringTaskFinished = () => {
    return ContouringRequest.taskFinishedStates.includes(this.props.contouringTask.contouringState);
  }

  handleErrorMessageInformationClick = () => {
    this.setState({ showErrorModal: true });
    const task = this.props.contouringTask;
    console.log(task);
    if (task.errorMessage) {
      console.log('Specific error message:');
      console.log(task.errorMessage);
    } else {
      console.log('No further specific error message.');
    }
  }

  handleErrorMessageDismissClick = () => {
    this.props.dismissContouringTask(this.props.contouringTask.scanId);
  }

  handleHideErrorModal = () => {
    this.setState({ showErrorModal: false });
  }

  renderStateString = (): string => {
    const task = this.props.contouringTask;

    switch (task.contouringState) {
      case ContouringTaskState.UploadingFiles:
        return `Uploading image ${task.imagesUploaded}/${task.totalImagesToUpload}`;
      case ContouringTaskState.Error:
      case ContouringTaskState.Failed:
        return `Segmentation failed`;
      default:
        return `Generating... (${getDurationString(this.state.elapsedTimeInSeconds)})`;
    }
  }

  render() {
    const task = this.props.contouringTask;

    if (!this.isContouringTaskFinished()) {
      return (
        <tr>
          <td className="spinner-container"><Spinner animation="border" size="sm" className="spinner-resize" /></td>
          <td colSpan={2}>
            <ProgressBar className="uploading-progress-bar"
              min={0}
              max={task.totalImagesToUpload}
              now={task.contouringState !== ContouringTaskState.NotStarted ? task.imagesUploaded : 0}
              label={this.renderStateString()}
              color='#016f83'
            />
          </td>
        </tr>
      );
    } else if (task.contouringState === ContouringTaskState.Failed || task.contouringState === ContouringTaskState.Error) {
      return (
        <tr className="contouring-failed">
          <td className="spinner-container"></td>
          <td colSpan={2}>
            <div className="error-message">
              <span>Segmentation failed</span>
              <span className="error-message-buttons">
                <span className="clickable-glyph" title="See more information regarding this error" onClick={this.handleErrorMessageInformationClick}><IoMdInformationCircleOutline /></span>
                <span className="clickable-glyph" title="Dismiss this error message" onClick={this.handleErrorMessageDismissClick}><IoMdCloseCircleOutline /></span>
              </span>
            </div>
            {task.contouringState === ContouringTaskState.Error && (
              <ModalDialog show={this.state.showErrorModal} size="lg" onHide={this.handleHideErrorModal}>
                <Modal.Header><h2>Segmentation failed</h2></Modal.Header>
                <Modal.Body>
                  <Table size="sm" className='table-color'>
                    <tbody>
                      <tr>
                        <th>Error:</th>
                        <th>{task.errorMessage.toString() || 'No detailed error message is available.'}</th>
                      </tr>
                      <tr>
                        <th>Session ID:</th>
                        <th>{getSessionId()}</th>
                      </tr>
                      <tr>
                        <th>Scan ID:</th>
                        <th>{task.scanId}</th>
                      </tr>
                      <tr>
                        <th>Images uploaded:</th>
                        <th>{task.imagesUploaded}</th>
                      </tr>
                      <tr>
                        <th>Total images to upload:</th>
                        <th>{task.totalImagesToUpload}</th>
                      </tr>
                      <tr>
                        <th>Upload start time:</th>
                        <th>{task.uploadStartTime ? task.uploadStartTime.toString() : 'N/A'}</th>
                      </tr>
                      <tr>
                        <th>Upload finish time:</th>
                        <th>{task.uploadFinishTime ? task.uploadFinishTime.toString() : 'N/A'}</th>
                      </tr>
                      <tr>
                        <th>Task finish time:</th>
                        <th>{task.taskFinishTime ? task.taskFinishTime.toString() : 'N/A'}</th>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-secondary" onClick={this.handleHideErrorModal}>Close</Button>
                </Modal.Footer>
              </ModalDialog>
            )}
          </td>
        </tr>
      );
    }
  }
}

export default ContouringRequest;