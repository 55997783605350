// ROI list component 

import React from 'react';
import 'react-contexify/dist/ReactContexify.min.css';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Checkbox } from '../misc-components';
import { roiCompare } from '../../helpers/compare';
import * as sagas from '../../store/sagas';
import * as structureSet from '../../dicom/structure-set';
import { StructureSetGrading, DatasetGradings } from '../../datasets/roi-grading';

import { ViewerState } from '../../rtviewer-core/viewer-state';
import { StoreState } from '../../store/store';


import './ROITable.css';

type OwnProps = {
    viewerState: ViewerState,
    structureSet: structureSet.StructureSet | null,
    structureSets: structureSet.StructureSet[],
    grading: StructureSetGrading | null,
    allDatasetGradings: DatasetGradings | null,
    openAddStructuresFromTemplateDialog: () => void,
    onAddRoiClick: (ss: structureSet.StructureSet) => void,
    doGradingSync: (ss: structureSet.StructureSet) => void,
}

type DispatchProps = {
}

type AllProps = StoreState & OwnProps & DispatchProps;

type OwnState = {
    refreshSwitch?: any,
}


class ROITable extends React.Component<AllProps, OwnState> {
    displayName = ROITable.name

    roiContextMenuId = "roi-context-menu";

    constructor(props: AllProps) {
        super(props);
        this.render = this.render.bind(this);
        this.state = { refreshSwitch: true };
    }

    componentDidMount() {
        let vs = this.props.viewerState;
        vs.addListener(this.updateView);
        this.updateView();
    }

    componentWillUnmount() {
        let vs = this.props.viewerState;
        vs.removeListener(this.updateView);
    }

    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    handleSelectRoiClick = (roi: structureSet.Roi, evt: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => {
        const vs = this.props.viewerState;
        vs.setSelectedRoi(roi);
    }

    handleFocusToRoiClick = (roi: structureSet.Roi) => {
        let ss = this.props.structureSet as structureSet.StructureSet;
        let vs = this.props.viewerState;
        let vm = vs.viewManager;
        this.handleSelectRoiClick(roi, null);
        if(roi.sdf) {
            vm.focusToRoi(roi.sdf.boundingBox);
        }
        else { // Maybe there are marker points?
            if( ss.contourData[roi.roiNumber] ) {
                const sliceIds = Object.keys(ss.contourData[roi.roiNumber]);
                if(sliceIds && sliceIds.length) vm.scrollToSlice(sliceIds[0]);
            }
        }
    }

    handleRoiVisibleChange = (event: any, roi: structureSet.Roi) => {
        let val = event.target.checked;
        let vs = this.props.viewerState;
        vs.setRoiHidden(roi, !val);
    }

    handleAllRoisVisibleChange = () => {
        const vs = this.props.viewerState;
        const shouldAllRoisBeHidden = vs.getAreAllRoisVisible();
        vs.setAllRoisHidden(shouldAllRoisBeHidden);
    }

    /** Returns a sorted list of structures (ROIs) from either the given structure set or from current
     *  viewer-state selected structure set.
     */
    getRoiList = (structureSet: structureSet.StructureSet | undefined = undefined): structureSet.Roi[] | null => {
        let ss: structureSet.StructureSet;
        if (structureSet) { 
            ss = structureSet;
        }
        else {
            const vs = this.props.viewerState;
            const ssOrNull = vs.selectedStructureSet;
            if (!ssOrNull) { return null; }
            ss = ssOrNull;
        }
        return Object.keys(ss.rois).map(roiNr => ss.rois[roiNr]).sort(roiCompare);
    }

    selectedRoiToViewport = () => {
        const selectedRoiItem = document.getElementsByClassName("selected-roi")[0];
        const roiPanel = document.getElementsByClassName("left-bottom-panel")[0];
        if (!selectedRoiItem) { return; }
        const selectionRect = selectedRoiItem.getBoundingClientRect();
        const roiPanelRect = roiPanel.getBoundingClientRect();

        if (selectionRect.top < roiPanelRect.top ) {
            roiPanel.scrollTop -= (roiPanelRect.top - selectionRect.top);
        }
        else if (selectionRect.bottom > document.documentElement.clientHeight) {
            roiPanel.scrollTop += (selectionRect.bottom - document.documentElement.clientHeight);
        }
    }

    render() {
        const vs = this.props.viewerState;
        const ssOrNull = vs.selectedStructureSet;
        if (!ssOrNull) { return null; }
        const ss = ssOrNull as structureSet.StructureSet;

        const roiList = this.getRoiList(ss)!;

        return (
            <>
            <div className="vertical-scrollable">
                <table className="roi-table">
                    <thead>
                        <tr>
                            <th className="visibility-column"><Checkbox
                                label={""}
                                isSelected={vs.getAreAllRoisVisible()}
                                onCheckboxChange={this.handleAllRoisVisibleChange}
                                />
                            </th>
                            <th className="color-column"/>
                            <th className="roi-name-column"/>
                        </tr>
                    </thead>
                    <tbody>
                    { roiList.map((roi, index, rois) => ( 
                            <tr className={`roi-item ${vs.selectedRoi && vs.selectedRoi.roiNumber === roi.roiNumber ? "selected-roi" : ""} ${index + 1 === rois.length && "last-roi-row"}`} 
                                    key={roi.roiNumber} >
                                <td className="visibility-column">
                                    <Checkbox
                                        label={""}
                                        isSelected={!( vs.hiddenRois[ss.structureSetId] && vs.hiddenRois[ss.structureSetId].has(roi.roiNumber)) }
                                        onCheckboxChange={(evt) => { this.handleRoiVisibleChange(evt, roi)}}/>
                                </td>
                                <td className="color-column"><div className="color-square" style={{backgroundColor: ss.rois[roi.roiNumber].rgb()}}
                                        title="Click to focus on structure"
                                        onClick={() => this.handleFocusToRoiClick(roi) }/></td>
                                <td className="roi-name-column" title={ss.rois[roi.roiNumber].name}
                                        onClick={(evt) => { this.handleSelectRoiClick(roi, evt)}}>{ss.rois[roi.roiNumber].name}</td>
                            </tr>
                    )) }
                    </tbody>
                </table>
            </div>

            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ROITable);
