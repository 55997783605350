import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { isBodyPart, getBodyPartImageLink } from '../../helpers/getBodyPartIcon';

type OwnProps = {
    option: any,
    onSelect: (option: any) => void,
    scrollToModelName: string | undefined;
    handleScrollToModel: (ref: HTMLDivElement) => void,
    isActive?: boolean,
}

class ModelItem extends React.Component<OwnProps> {

    private ref: React.RefObject<HTMLDivElement>;

    constructor(props: OwnProps) {
        super(props);
        this.ref = React.createRef<HTMLDivElement>();
    }

    componentDidUpdate() {
        const { scrollToModelName, handleScrollToModel, option } = this.props;
        if (this.ref.current && handleScrollToModel && scrollToModelName && option.value === scrollToModelName) {
            handleScrollToModel(this.ref.current);
        }

    }

    render() {
        const { option, onSelect, isActive } = this.props;

        return (

            <ListGroup.Item
                key={option.value}
                onClick={() => onSelect(option)}
                active={isActive}
            >
                <div className="model-select-item" ref={this.ref}>
                    <span>{isBodyPart(option.action) && <img src={getBodyPartImageLink(option.action)} alt={option.action} className='output-icon' />}</span>
                    <span>{option.label}</span>
                </div>
            </ListGroup.Item>
        );
    }
}

export default ModelItem;
