export const CONTOUR_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1
precision highp float;
precision highp int;
layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

uniform vec2 samplingDist;
uniform vec2 flip; // Flip horizontally, flip vertically. 1.0 == True, 0.0 == False

uniform mat4 orientation;
uniform float viewingPlane; // 0.0 = transversal, 1.0 = coronal, 2.0 = sagittal
out vec3 v_texcoord;
out vec2 vPos;

out float plane;

out vec3 edgeDetectSample0;
out vec3 edgeDetectSample1;
out vec3 edgeDetectSample2;
out vec3 edgeDetectSample3;
out vec3 edgeDetectSample4;
out vec3 edgeDetectSample5;
out vec3 edgeDetectSample6;
out vec3 edgeDetectSample7;

void main()
{
    // Multiply the texture coordinate by the transformation
    // matrix to place it into 3D space

    vec2 texcoord = vec2( flip.x > 0.5 ? 1.0 - in_texcoord.x : in_texcoord.x, flip.y > 0.5 ? 1.0 - in_texcoord.y : in_texcoord.y);
    v_texcoord = (orientation * vec4(texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
    vPos = position;

    float dx = samplingDist.x;
    float dy = samplingDist.y;
    edgeDetectSample0 = ( orientation * vec4(texcoord.s + dx, texcoord.t, 0.0, 1.0) ).stp;
    edgeDetectSample1 = ( orientation * vec4(texcoord.s - dx, texcoord.t, 0.0, 1.0) ).stp;
    edgeDetectSample2 = ( orientation * vec4(texcoord.s, texcoord.t + dy, 0.0, 1.0) ).stp;
    edgeDetectSample3 = ( orientation * vec4(texcoord.s, texcoord.t - dy, 0.0, 1.0) ).stp;
    dx *= 0.8509; // sin 45
    dy *= 0.8509; // sin 45
    edgeDetectSample4 = ( orientation * vec4(texcoord.s + dx, texcoord.t + dy, 0.0, 1.0) ).stp;
    edgeDetectSample5 = ( orientation * vec4(texcoord.s - dx, texcoord.t + dy, 0.0, 1.0) ).stp;
    edgeDetectSample6 = ( orientation * vec4(texcoord.s + dx, texcoord.t - dy, 0.0, 1.0) ).stp;
    edgeDetectSample7 = ( orientation * vec4(texcoord.s - dx, texcoord.t - dy, 0.0, 1.0) ).stp;
  
    plane = viewingPlane;
}
`;

export const CONTOUR_FS = `#version 300 es
precision mediump float;
precision mediump int;
precision mediump sampler3D;
uniform sampler3D textureData;
uniform vec4 imageRect; // top, right, bottom, left
uniform vec4 roiColor; // R,G,B,A
uniform vec3 textureSize;

uniform float debugMode;
in float plane; // 0.0 = transversal, 1.0 = coronal, 2.0 = sagittal

in vec3 v_texcoord;
in vec2 vPos;

in vec3 edgeDetectSample0;
in vec3 edgeDetectSample1;
in vec3 edgeDetectSample2;
in vec3 edgeDetectSample3;
in vec3 edgeDetectSample4;
in vec3 edgeDetectSample5;
in vec3 edgeDetectSample6;
in vec3 edgeDetectSample7;

out vec4 color;



vec4 Sample(vec3 pixel) {
  return texture(textureData, pixel);
}

void main()
{

  // Don't draw contours outside of the image (possibly added margin to the ROI bounding box)
  if(vPos.y > imageRect.x || vPos.x > imageRect.y || vPos.y < imageRect.z || vPos.x < imageRect.w)
  {
    color = vec4(0.0, 0.0, 0.0, 0.0);
  }
  else if(debugMode > 0.5) {
    float r = Sample(v_texcoord).r;
    color = vec4(r, r, r, 1.0);
  }
  else {

    vec4 col = Sample(v_texcoord);//texture(textureData, v_texcoord);

    bool inside = col.r <= 0.5;

    if( ( (Sample(edgeDetectSample0).r <= 0.5) != inside ) 
      || ( (Sample(edgeDetectSample1).r <= 0.5) != inside )
      || ( (Sample(edgeDetectSample2).r <= 0.5) != inside )
      || ( (Sample(edgeDetectSample3).r <= 0.5) != inside )
      || ( (Sample(edgeDetectSample4).r <= 0.5) != inside )
      || ( (Sample(edgeDetectSample5).r <= 0.5) != inside )
      || ( (Sample(edgeDetectSample6).r <= 0.5) != inside )
      || ( (Sample(edgeDetectSample7).r <= 0.5) != inside )
      ) {
        color = roiColor;
      }
    //  else if(inside) {
    //   // Area inside ROI
    //   color = roiColor;
    //   color.a = 0.3;
    // }
      else {
        color = vec4(0.0, 0.0, 0.0, 0.0);
      }
    
  }
}
`;