import React from 'react';
import { Container, Row /*, Navbar, Nav*/ } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import HelpDialog from './common/dialogs/HelpDialog';
import UserSettingsDialog from './common/dialogs/UserSettingsDialog';
import NewVersionAlert from './common/NewVersionAlert';
import NotificationsList from './common/Notifications';
import UtilityToolsToolbar from './common/UtilityToolsToolbar';
import Footer from './Footer';
import ServiceAgreementModal from './common/ServiceAgreement';

interface IProps extends RouteComponentProps<any> { }

class Layout extends React.Component<IProps> {
  render() {
    const isToolsPage = this.props.location.pathname.includes('/tools/');

    return (
      <Container fluid className="home-page">
        {/* <Navbar bg="light"  fluid="true" collapseOnSelect>
          <Nav className="">
            <Nav.Link href="/local">Local files</Nav.Link>
            <Nav.Link href="/annotation">Azure files</Nav.Link>
          </Nav>
      </Navbar>  */}

        <ServiceAgreementModal />

        <NewVersionAlert />

        {isToolsPage && <UtilityToolsToolbar />}

        <NotificationsList />

        <Row>
          {this.props.children}
        </Row>
        <UserSettingsDialog />
        <HelpDialog />

        <Footer />
      </Container>);
  }
}

export default withRouter<IProps, any>(Layout);

