export function getDICOMDate(): string {
    // get date in DICOM format: YYYYMMDD -> 20210323
    let today = new Date();
    let y_str = today.getFullYear();
    let m = today.getMonth()+1;
    let d = today.getDate();

    let d_str = ''
    if(d<10)
    {
        d_str='0'+d.toString();
    } else {
        d_str = d.toString();
    }

    let m_str = ''
    if(m<10)
    {
        m_str='0'+m.toString();
    } else {
        m_str=m.toString();
    }
    return y_str + m_str + d_str
}


export function getDICOMTime(): string {
    // get time in DICOM format: HHMMSS -> 225739
    let d = new Date(); // current time
    let h = d.getHours();
    let m = d.getMinutes();
    let s = d.getSeconds();

    let h_str = ''
    if(h<10)
    {
        h_str='0'+h.toString();
    } else {
        h_str = h.toString();
    }

    let m_str = ''
    if(m<10)
    {
        m_str='0'+m.toString();
    } else {
        m_str=m.toString();
    }

    let s_str = ''
    if(s<10)
    {
        s_str='0'+s.toString();
    } else {
        s_str = s.toString();
    }
    return h_str + m_str + s_str
}

/** Insert a hardcoded preset header into a DICOM file. TODO: replace with a dynamic implementation. */
export function fixMissingMetaheader(arrayBuffer: ArrayBuffer): ArrayBuffer {

    console.warn('NOTICE: Trying to load a DICOM file that doesn\'t have a metaheader. Inserting a preset header to get it to load.');

    const originalFile = new Uint8Array(arrayBuffer);

    // Required header elements:
    // * Empty preamble
    // * "DICM" prefix
    // * (0002,0000) *File Meta Information Group Length*, UL, 4
    // * (0002,0001) *File Meta Information Version*, OB, 2
    // * (0002,0002) *Media Storage SOP Class UID*, UI, N
    // * (0002,0003) *Media Storage SOP Instance UID*, UI, N
    // * (0002,0010) *Transfer Syntax UID*, UI, N
    // * (0002,0012) *Implementation Class UID*, UI, N

    const emptyPreamble = new Uint8Array(128);

    // NOTE: this metaheader is copied from an existing DICOM file. It WON'T match whatever file it is
    // being added to, but is usually enough to get dcmjs to load the file correctly.
    // NOTE 2: this array includes the DICM prefix
    const metaheader = new Uint8Array([
        0x44, 0x49, 0x43, 0x4D, 0x02, 0x00, 0x00, 0x00, 0x55, 0x4C, 0x04, 0x00, 0xD8, 0x00, 0x00, 0x00,
        0x02, 0x00, 0x01, 0x00, 0x4F, 0x42, 0x00, 0x00, 0x02, 0x00, 0x00, 0x00, 0x00, 0x01, 0x02, 0x00,
        0x02, 0x00, 0x55, 0x49, 0x1A, 0x00, 0x31, 0x2E, 0x32, 0x2E, 0x38, 0x34, 0x30, 0x2E, 0x31, 0x30,
        0x30, 0x30, 0x38, 0x2E, 0x35, 0x2E, 0x31, 0x2E, 0x34, 0x2E, 0x31, 0x2E, 0x31, 0x2E, 0x32, 0x00,
        0x02, 0x00, 0x03, 0x00, 0x55, 0x49, 0x2C, 0x00, 0x32, 0x2E, 0x32, 0x35, 0x2E, 0x31, 0x30, 0x31,
        0x34, 0x38, 0x35, 0x34, 0x34, 0x36, 0x33, 0x34, 0x35, 0x31, 0x36, 0x30, 0x35, 0x32, 0x31, 0x30,
        0x38, 0x30, 0x30, 0x31, 0x34, 0x30, 0x36, 0x30, 0x31, 0x34, 0x36, 0x30, 0x34, 0x39, 0x31, 0x34,
        0x34, 0x32, 0x34, 0x32, 0x02, 0x00, 0x10, 0x00, 0x55, 0x49, 0x12, 0x00, 0x31, 0x2E, 0x32, 0x2E,
        0x38, 0x34, 0x30, 0x2E, 0x31, 0x30, 0x30, 0x30, 0x38, 0x2E, 0x31, 0x2E, 0x32, 0x00, 0x02, 0x00,
        0x12, 0x00, 0x55, 0x49, 0x40, 0x00, 0x32, 0x2E, 0x32, 0x35, 0x2E, 0x38, 0x30, 0x33, 0x30, 0x32,
        0x38, 0x31, 0x33, 0x31, 0x33, 0x37, 0x37, 0x38, 0x36, 0x33, 0x39, 0x38, 0x35, 0x35, 0x34, 0x37,
        0x34, 0x32, 0x30, 0x35, 0x30, 0x39, 0x32, 0x36, 0x37, 0x33, 0x34, 0x36, 0x33, 0x30, 0x39, 0x32,
        0x31, 0x36, 0x30, 0x33, 0x33, 0x36, 0x36, 0x36, 0x34, 0x38, 0x32, 0x32, 0x35, 0x32, 0x31, 0x32,
        0x31, 0x34, 0x35, 0x34, 0x30, 0x34, 0x02, 0x00, 0x13, 0x00, 0x53, 0x48, 0x0A, 0x00, 0x64, 0x63,
        0x6D, 0x6A, 0x73, 0x2D, 0x30, 0x2E, 0x30, 0x20, //0x08, 0x00, 0x05, 0x00, 0x0A, 0x00, 0x00, 0x00
    ]);

    const combined = new Uint8Array([
        ...emptyPreamble,
        ...metaheader,
        ...originalFile
    ]);

    return combined.buffer;
}

/** Returns a copy of the supplied ArrayBuffer. Source: https://stackoverflow.com/a/22114687 */
export function copyArrayBuffer(arrayBuffer: ArrayBuffer): ArrayBuffer {
    var copy = new ArrayBuffer(arrayBuffer.byteLength);
    new Uint8Array(copy).set(new Uint8Array(arrayBuffer));
    return copy;
}
