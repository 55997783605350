import { Matrix4f } from "../../../../math/Matrix4f";
import { Uniform } from "./Uniform";

export class UniformMatrix extends Uniform {
    
    constructor(gl: WebGL2RenderingContext, name: string){
        super(gl, name);
    }

    loadMatrix(matrix: Matrix4f | Array<number>, transpose?: boolean){
        if(this.location){
            if(matrix instanceof Array){
                this.gl.uniformMatrix4fv(this.location, transpose || false, Float32Array.from(matrix), 0, matrix.length);
            } else {
                this.gl.uniformMatrix4fv(this.location, transpose || false, matrix.getFloat32Array(), 0, 16);
            }
        }
    }
}