import _ from 'lodash';
export type AccessRights = {
    isInitialized: boolean;
    applauncher: string[];
    configui: string[];
    dashboard: string[];
    guide: string[];
    verify: string[];
    web_ui: string[];
};

export const ACCESS_RIGHTS_VALID = 'access';

function isValidAccessRightsJSON(json: any): json is AccessRights {
    // Validate JSON structure according to AccessRights type using lodash
    return (
        _.isArray(json.applauncher) &&
        _.isArray(json.configui) &&
        _.isArray(json.dashboard) &&
        _.isArray(json.guide) &&
        _.isArray(json.verify) &&
        _.isArray(json.web_ui)
    );
}

export function parseAccessRights(accessRightsJSON: any): AccessRights {
    if (!isValidAccessRightsJSON(accessRightsJSON)) {
        throw new Error('Invalid access rights JSON format');
    }

    const parsedAccessRights = accessRightsJSON as AccessRights;
    parsedAccessRights.isInitialized = true;
    return parsedAccessRights;
}

export function getInitialUserAccessRights(): AccessRights {
    return {
        isInitialized: false,
        applauncher: [],
        configui: [],
        dashboard: [],
        guide: [],
        verify: [],
        web_ui: [],
    };
}

export function isInitialAccessRights(accessRights: AccessRights): boolean {
    return !accessRights.isInitialized;
}

export const bypassedAccessRights: AccessRights = {
    ...getInitialUserAccessRights(),
    isInitialized: true,
    web_ui: [ACCESS_RIGHTS_VALID]
};
