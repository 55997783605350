import { AccessRights, parseAccessRights } from './access-rights';
import BackendClient from './backend-client';
import _ from 'lodash';


export type LicenseQuota = {
    totalQuota: number | undefined;
    quotaLeft: number | undefined;
};

/** API client object for calling the backend's config API endpoints. The actual endpoints are 
 * abstracted away under methods that are more contextually useful in this application. */
export class ConfigClient {

    private backendClient: BackendClient;

    private urlRoot = '/config';
    private getLicensesUrl = this.urlRoot + '/get-licenses';
    private getSegmentationConfigUrl = this.urlRoot + '/contouring';
    private getAccessRightsUrl = '/access-rights';


    constructor(backendClient: BackendClient) {
        this.backendClient = backendClient;
    }

    /** Return license quotas for selected user */
    public async getLicenseQuotas(userId: string): Promise<LicenseQuota[]> {

        const options = {
            headers: {
                'userId': userId,
            },
        };

        const response = await this.backendClient.get(this.getLicensesUrl, options);
        if (!response.ok) {
            console.error(`Fetching licenses for user ${userId} failed:`);
            console.error(await response.text());
            throw new Error(`Fetching licenses for user failed`);
        }

        const result = await response.json();
        if (!_.isArray(result) || result.length === 0) {
            return [];
        }

        const quotas: LicenseQuota[] = [];
        for (const licenseJson of result) {
            if (_.has(licenseJson, 'quota') && _.has(licenseJson, 'quota_left')) {
                quotas.push({ totalQuota: licenseJson['quota'], quotaLeft: licenseJson['quota_left'] });
            }
        }

        return quotas;
    }

    /** Return segmentation configuration */
    public async getSegmentationConfig(userId: string): Promise<any> {
        const options = {
            headers: {
                'userId': userId,
            },
        };

        const response = await this.backendClient.get(this.getSegmentationConfigUrl, options);
        if (!response.ok) {
            console.error(`Fetching segmentation config for user ${userId} failed:`);
            console.error(await response.text());
            throw new Error(`Fetching segmentation config for user failed`);
        }

        return await response.json();
    }


    // fetch access rights from the backend
    public async fetchAndParseAccessRights(): Promise<AccessRights> {
        try {
            const response = await this.backendClient.get(this.getAccessRightsUrl);
            return parseAccessRights(await response.json());
        } catch (error) {
            console.error(error);
            throw new Error('Failed to fetch access rights.');
        }
    }

}
