//@ts-ignore
import * as dcmjs from 'dcmjs';
import * as mathjs from 'mathjs';
import {deepCopy, saveFileOnUserDevice, sleep} from '../util';
import * as guid from './guid';
import {Sdf} from '../rtviewer-core/webgl/sdf/sdf';
import {SdfOperations} from '../rtviewer-core/webgl/sdf/boolean/sdf-operations';
import {ViewManager} from '../rtviewer-core/view-manager';
import {ViewerState} from '../rtviewer-core/viewer-state';
import {Coordinates, transformPoint, transformPoint2} from './coords'
import {Image, Modality} from "./image";
import {getDICOMDate, getDICOMTime} from "./utils";
import { AzureFileInfo } from '../web-apis/azure-files';
import { supportedRoiTypes } from '../components/rtviewer/RoiTypeMenu';
import _ from 'lodash';
import { ALLOW_DICOM_MODIFICATIONS } from '../environments';


export const AnnotationManufacturer = "MVision AI Oy";
export const AnnotationManufacturerModelName = "Annotation";
export const RoiInterpretedTypeOrgan = "ORGAN";
export const RoiInterpretedTypeBody = "EXTERNAL";
const DefaultRoiInterpretedType = RoiInterpretedTypeOrgan;

export type Point = number[];
export type Polygon = Point[];
export type RoiContours = {[sliceId: string]: {polygons?: Polygon[], points?: Point[] }}
export type StructureSetContours = { [roiNr: string]: RoiContours };

export class Roi {
    public structureSet: StructureSet;
    public roiNumber: string;
    public name: string;
    public color: number[];
    public interpretedType: string;
    public sdf?: Sdf | null;

    public unsaved: boolean;
    public contoursChangedInSlices: string[]; // slice ids where ss.contourData has become out of date.
    public contoursChangedInAllSlices: boolean; // when true, all contours need to be found from sdf when saving.

    constructor(structureSet: StructureSet, roiNumber: any, name: string, color: number[], interpretedType: string) {
        this.structureSet = structureSet;
        this.roiNumber = roiNumber.toString();
        this.name = name.trim();
        this.color = color;
        this.interpretedType = interpretedType;
        this.unsaved = false;
        this.contoursChangedInSlices = [];
        this.contoursChangedInAllSlices = false;
    }

    rgb() {
        return "rgb(" + this.color[0] + "," + this.color[1] + "," + this.color[2] + ")";
    }

    isBody(): boolean {
        return this.interpretedType.toUpperCase() === RoiInterpretedTypeBody;
    }

    setContoursChanged(sliceIds: string[] | null) {
        if(sliceIds ) {
            sliceIds.forEach(sId => {
                if(!this.contoursChangedInSlices.includes(sId)) {
                    this.contoursChangedInSlices.push(sId);
                }
            });
            if(sliceIds.length) this.unsaved = true;
        }
        else {
            this.contoursChangedInAllSlices = true;
            this.unsaved = true;
        }
        
    }
}

export const StructureSetModalMessages = {
    LoadingStructures: "Loading structures...",
    Saving: "Saving...",
    Duplicating: "Duplicating structure set...",
    Exporting: "Exporting structure set...",
    Interpolating: "Interpolating...",
    CalculatingMargin: "Calculating margin...",
}

export class StructureSet {
    public coordType: Coordinates;
    public arrayBuffer: any;
    public dataset: any;
    public scanId: string;
    public patientId: string;
    public seriesUid: string;
    public structureSetId: string;
    public sopClassId: string;
    public frameOfReferenceUid: string;
    public studyId: string | undefined;
    public structureSetDescription: string | undefined;
    public label: string;
    public rois: { [roiNumber: string]: Roi };
    public contourData: StructureSetContours;
    public azureFileInfo: AzureFileInfo | null;
    public modalMessage: string | null; // When set, a modal message dialog is displayed, blocking UI action.
    public unsaved: boolean; // This must be set to true as soon as structureset has changes not saved to Azure fileshare
    public deleted: boolean; // Delete pending, will be deleted in Azure fileshare in the next save
    public existsInAzure: boolean;
    public isOriginal: boolean;

    constructor(arrayBuffer: any, dataset: any, scanId: string, patientId: string, seriesUid: string, sopUid: string, sopClassId: string, frameOfReferenceUid: string) {
            this.coordType = Coordinates.Patient
            this.arrayBuffer = arrayBuffer;
            this.dataset = dataset;
            this.scanId = scanId;
            this.patientId = patientId;
            this.seriesUid = seriesUid;
            this.structureSetId = sopUid;
            if (sopClassId == null) {
                sopClassId = this.dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[0].ReferencedSOPClassUID
                console.log("provided sopClassId was null, trying to read it from dataset: " + sopClassId)
            }
            this.sopClassId = sopClassId; // CT or MR
            this.frameOfReferenceUid = frameOfReferenceUid;
            this.studyId = _.get(dataset, 'StudyID', undefined);
            this.structureSetDescription = _.get(dataset, 'StructureSetDescription', undefined);
            this.label = dataset.StructureSetLabel || 'Structure Set';
            this.rois = {};
            this.contourData = {};
            this.azureFileInfo = null // This must be assigned for structure sets except in local file mode
            this.modalMessage = null;
            this.unsaved = false; // This must be set to true as soon as structureset has changes not saved to Azure fileshare
            this.deleted = false; // Delete pending, will be deleted in Azure fileshare in the next save
            this.existsInAzure = true;
            this.isOriginal = dataset.Manufacturer !== AnnotationManufacturer || dataset.ManufacturerModelName !== AnnotationManufacturerModelName;
    }

    toIM(img: any) {
        // convert contours from patient coordinate to image/scan coordinates (scaled in mm)
        if (this.coordType === Coordinates.Image) {
            return
        }

        this.coordType = Coordinates.Image
        for (const roiNum in this.contourData) {
            if (!this.contourData.hasOwnProperty(roiNum)) {
                continue
            }
            // console.log([roiNum])
            for (const sliceUID in this.contourData[roiNum]) {
                if (!img) {
                    continue
                }
                if (!this.contourData[roiNum].hasOwnProperty(sliceUID)) {
                    continue
                }
                let ar = this.contourData[roiNum][sliceUID].polygons
                // console.log([roiNum, sliceUID])
                if (ar === undefined) {
                } else {
                    let v: number[]
                    for (let i=0; i<ar.length;i++) {
                        for (let j=0; j<ar[i].length;j++) {
                            v = transformPoint(ar[i][j], img.T)
                            ar[i][j] = [v[0] * img.iSpacing, v[1] * img.jSpacing, v[2] * img.kSpacing]
                        }
                    }
                }
                this.contourData[roiNum][sliceUID].polygons = ar
                // .polygons
                // let ar = this.contourData[roiNum].polygons[sliceUID]
                // console.log(ar.length)
                // console.log(`${roiNum}: ${object[roiNum]}`);
            }
        }
    }

    toPatient() {
        // convert contours from image/scan coordinate (scaled in mm) to patient coordinates
        // todo: is implemented below in structureSetToDicom. maybe move it to this method as well
        // this.coordType = Coordinates.Patient
    }

    clearSdfs(): void {
        this.getRois().forEach(roi => roi.sdf = undefined);
    }

    getRois(): Roi[] {
        return Object.values(this.rois);
    }

    addRoi(name: string, colorArray: number[], roiType: string = RoiInterpretedTypeOrgan): Roi {
        const roiNumber = this.findUniqueRoiNumber();
        const matchingSupportedRoiType = supportedRoiTypes.find(r => r.dicomValue.toUpperCase() === roiType.toUpperCase());
        const roiTypeDicomValue = matchingSupportedRoiType ? matchingSupportedRoiType.dicomValue : DefaultRoiInterpretedType;
        const roi = new Roi(this, roiNumber, name.trim(), colorArray, roiTypeDicomValue);
        this.contourData[roiNumber] = {};
        roi.setContoursChanged(null);

        this.rois[roiNumber] = roi;
        this.unsaved = true;
        return roi;
    }

    duplicateRoi(vm: ViewManager, roi: Roi): Roi {
        let name = roi.name +  " copy";
        let colorArray = [ guid.getRandomInt(0,255), guid.getRandomInt(0,255), guid.getRandomInt(0,255)];
        let newRoi = new Roi(this, this.findUniqueRoiNumber(), name, colorArray, roi.interpretedType);
        if(roi.sdf) newRoi.sdf = new SdfOperations(vm).copy(roi.sdf, null, false);
        this.rois[newRoi.roiNumber] = newRoi;
        
        this.unsaved = true;
        newRoi.unsaved = true;
        this.contourData[newRoi.roiNumber] = deepCopy(this.contourData[roi.roiNumber]);
        newRoi.contoursChangedInSlices = deepCopy(roi.contoursChangedInSlices);
        newRoi.contoursChangedInAllSlices = roi.contoursChangedInAllSlices;
        
        return newRoi;
    }

    duplicateRoiFromOtherStructureSet(vm: ViewManager, sourceRoi: Roi, overwrite: boolean) {
        const vs = vm.viewerState;
        if(this === sourceRoi.structureSet) return;
        let targetRoi = new Roi(this, this.findUniqueRoiNumber(), sourceRoi.name, sourceRoi.color, sourceRoi.interpretedType);
        const roisWithSameName = this.getRois().filter(r => r.name === sourceRoi.name);
        if(roisWithSameName.length) {
            if(overwrite) {
                targetRoi = roisWithSameName[0];
                vs.undoStack.pushRoiStateBeforeEdit(targetRoi);
            }
            else {
                targetRoi.name = targetRoi.name + " copied";
            }
        }

        if(sourceRoi.sdf) targetRoi.sdf = new SdfOperations(vm).copy(sourceRoi.sdf, null, false);
        this.rois[targetRoi.roiNumber] = targetRoi;
        
        this.unsaved = true;
        targetRoi.unsaved = true;
        this.contourData[targetRoi.roiNumber] = deepCopy(sourceRoi.structureSet.contourData[sourceRoi.roiNumber]);
        targetRoi.contoursChangedInSlices = deepCopy(sourceRoi.contoursChangedInSlices);
        targetRoi.contoursChangedInAllSlices = sourceRoi.contoursChangedInAllSlices;
        
        return targetRoi;
    }

    clearSdf(roi: Roi, sliceToClear: string | null) {
        if(!roi.sdf) return;
        if(sliceToClear) {
            roi.sdf.clearSlice(sliceToClear);
            roi.setContoursChanged([sliceToClear]);
        }
        else {
            roi.sdf.clearTexture();
            roi.setContoursChanged(null);
        }
        
        this.unsaved = true;
    }

    // Recreate contourData for edited ROIs
    updateContours() {
        this.getRois().forEach((roi: Roi) => {
            if(roi.sdf ) {
                this.contourData[roi.roiNumber] = roi.sdf.getContours(roi, this.contourData[roi.roiNumber]);
            }
        });
    }

    deleteRois(deletedRoiNumbers: string[]): void{
        let newRois: { [roiNumber: string]: Roi } = {};
        Object.keys(this.rois).forEach(roiNr => {
            if(!deletedRoiNumbers.includes(roiNr)) {
                newRois[roiNr] = this.rois[roiNr];
            }
        })
        this.rois = newRois;
        deletedRoiNumbers.forEach(roiNr => delete this.contourData[roiNr]);
        this.unsaved = true;
    }

    findUniqueRoiNumber(): string {
        let roiNumber = 1;
        let roiNumbers = Object.keys(this.rois);
        function isUnique(n: number) {return !roiNumbers.some(nr => nr.toString() === n.toString())}
        while(!isUnique(roiNumber)) roiNumber++;
        return roiNumber.toString();
    }

    canEdit(): boolean {
        return !this.isOriginal && this.dataset.ApprovalStatus !== "APPROVED";
    }
}

export function dicomToStructureSet(arrayBuffer: ArrayBuffer): StructureSet | null {
    let dicomDict = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    let dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomDict.dict);
    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomDict.meta);
    if(!dataset.ReferencedFrameOfReferenceSequence
        || !dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence
        || !dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence
        || !dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID ) {
        return null;
    }

    dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID = 
    dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID.substring(0,64);
    dataset.SOPInstanceUID = dataset.SOPInstanceUID.substring(0,64);
    let contourData: StructureSetContours = {};
    let roiColors: any = {};
    let frameOfReferenceUid = dataset.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID;
    let scanId = dataset.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID;
    let patientId = dataset.PatientID;
    let seriesId = dataset.SeriesInstanceUID;
    let sopInstanceUID = dataset.SOPInstanceUID;
    let sopClassId: any = null;
    dataset.ROIContourSequence = Array.isArray(dataset.ROIContourSequence) ? dataset.ROIContourSequence : [dataset.ROIContourSequence];
    dataset.ROIContourSequence.forEach((roi: any) => {
        let roiNumber = roi.ReferencedROINumber;
        contourData[roiNumber] = {};
        let color = roi.ROIDisplayColor;
        roiColors[roiNumber] = color ? color.map((x: any) => parseInt(x)) : [128, 128, 128];
        if(!Array.isArray(roi.ContourSequence)) {
            console.log(roi.ContourSequence);
        }
        roi.ContourSequence = Array.isArray(roi.ContourSequence) ? roi.ContourSequence : [roi.ContourSequence];

        for(let c=0; roi.ContourSequence && c<roi.ContourSequence.length; ++c)
        {
            let contour = roi.ContourSequence[c];
            if(!contour) continue;
            if(!contour.ContourImageSequence){
                return null;
            } 
            let geomType = contour.ContourGeometricType;
            let sliceId = contour.ContourImageSequence.ReferencedSOPInstanceUID.substring(0,64);

            // todo: HERE COMPUTE SLICE_ID based on slice position for supporting the visualization of contours on other scans
            sopClassId = contour.ContourImageSequence.ReferencedSOPClassUID;
            let nrPts = parseInt(contour.NumberOfContourPoints);
            let data = contour.ContourData;

            let points = []
            for(let i = 0; i < nrPts; ++i)
            {
                let x = parseFloat(data[i*3]);
                let y = parseFloat(data[i*3+1]);
                let z = parseFloat(data[i*3+2]);
                points.push( [x, y, z] );
                // todo: maybe do the point transformation here
            }

            if(geomType === "POINT") {
                contourData[roiNumber][sliceId] = {points: points};
            }
            else if(geomType === "CLOSED_PLANAR") 
            {
                contourData[roiNumber][sliceId] = contourData[roiNumber][sliceId] || {};
                let polygons = contourData[roiNumber][sliceId].polygons || [];
                if(points.length > 0) {
                    polygons.push(points);
                }
                contourData[roiNumber][sliceId].polygons = polygons;
            }
            else {
                alert("Ignoring contours with geometric type: " + geomType + ", not supported by the viewer");
            }
        }
    });

    const ss = new StructureSet( arrayBuffer, dataset, scanId, patientId, seriesId, sopInstanceUID, sopClassId, frameOfReferenceUid );
    ss.contourData = contourData;

    ss.rois = {};
    const structureSetROISequence = Array.isArray(dataset.StructureSetROISequence) ? dataset.StructureSetROISequence : [dataset.StructureSetROISequence];
    structureSetROISequence.forEach((roi: any) => {
        const roiNumber = roi.ROINumber;
        const roiObservation = _.has(dataset, 'RTROIObservationsSequence.find') ? dataset.RTROIObservationsSequence.find((o: any) => o.ReferencedROINumber === roiNumber) : undefined;
        const interpretedType = roiObservation ? roiObservation.RTROIInterpretedType : "";
        const name = roi.ROIName;
        const colorArray = roiColors[roiNumber] || [127,127,127];
        ss.rois[roiNumber] = new Roi(ss, roiNumber, name, colorArray, interpretedType);
    });

    return ss;
}


export function structureSetToDicom(structureSet: StructureSet, img: Image, postOperations?: (dataset: any) => void, roiNrsToExport?: string[]): ArrayBuffer {
    structureSet.updateContours();
    let dicomDict = dcmjs.data.DicomMessage.readFile(structureSet.arrayBuffer);
    let dataset = structureSet.dataset;
    dataset.SeriesInstanceUID = structureSet.seriesUid;
    dataset.PatientID = structureSet.patientId;
    dataset.SOPInstanceUID = structureSet.structureSetId;
    dataset.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID = structureSet.frameOfReferenceUid;
    dataset.StructureSetROISequence = [];
    dataset.ROIContourSequence = [];
    dataset.RTROIObservationsSequence = [];

    let contours: any = {};
    let tIM2patient = mathjs.inv(img.T)
    // structureSet.toPatient() // maybe convert contours here
    const contourDataRoiNrs = roiNrsToExport ? roiNrsToExport : Object.keys(structureSet.contourData);
    contourDataRoiNrs.forEach(roiNr => {
        let roiContours = structureSet.contourData[roiNr];
        contours[roiNr] = {};
        
        Object.keys(roiContours).forEach(sliceId => {
            let sliceContours = roiContours[sliceId];
            if(sliceContours.polygons) {
                contours[roiNr].polygons = contours[roiNr].polygons || [];
                for(let k = 0; k < sliceContours.polygons.length; ++k) {
                    // let pts = sliceContours.polygons[k]
                    let pts = sliceContours.polygons[k].map(a => ({...a}));  // deep copy

                    for (let a=0; a<pts.length; ++a) {
                        pts[a] = transformPoint2([pts[a][1] / img.jSpacing, pts[a][0] / img.iSpacing, pts[a][2] / img.kSpacing], tIM2patient)
                    }
                    let poly = {pointArray: pts, sliceId: sliceId}
                    contours[roiNr].polygons.push(poly);
                }
            }
            else if(sliceContours.points) {
                contours[roiNr].points = contours[roiNr].points || [];
                for(let k = 0; k < sliceContours.points.length; ++k) {
                    let points = {points: sliceContours.points[k], sliceId: sliceId}
                    contours[roiNr].points.push(points);
                }
            }
        });
    });

    // Populate dataset.ROIContourSequence
    const roiNrs = roiNrsToExport ? roiNrsToExport : Object.keys(structureSet.rois);
    for(let i = 0; i < roiNrs.length; ++i) {
        let roiNr = roiNrs[i];
        let roi = structureSet.rois[roiNr];
        let item: any = {
            ContourSequence: [],
            ROIDisplayColor: roi.color,
            ReferencedROINumber: roiNr.toString()
        };
        if(contours[roiNr]) {
            let polygons = contours[roiNr].polygons;
            let points = contours[roiNr].points;
            if(polygons) {
                for( let j = 0; j < polygons.length; ++j) {
                    let poly = polygons[j];
                    let contourItem: any = {
                        ContourData: [],
                        ContourGeometricType: "CLOSED_PLANAR",
                        ContourImageSequence: {
                            ReferencedSOPClassUID: structureSet.sopClassId,
                            ReferencedSOPInstanceUID: poly.sliceId
                        },
                        ContourNumber: j.toString(),
                        NumberOfContourPoints: poly.pointArray.length
                    };
                    for(let k = 0; k < poly.pointArray.length; ++k) {
                        let point = poly.pointArray[k];
                        contourItem.ContourData.push(clampPoint(point[0]));
                        contourItem.ContourData.push(clampPoint(point[1]));
                        contourItem.ContourData.push(clampPoint(point[2]));
                    }
                    item.ContourSequence.push(contourItem);
                }
            }
            else if(points) {
                for( let j = 0; j < points.length; ++j) {
                    let pts = points[j];
                    let contourItem: any = {
                        ContourData: [],
                        ContourGeometricType: "POINT",
                        ContourImageSequence: {
                            ReferencedSOPClassUID: structureSet.sopClassId,
                            ReferencedSOPInstanceUID: pts.sliceId
                        },
                        ContourNumber: j.toString(),
                        NumberOfContourPoints: pts.points.length / 3
                    };
                    for(let k = 0; k < pts.points.length; ++k) {
                        let point = pts.points[k];
                        contourItem.ContourData.push(clampPoint(point));
                    }
                    item.ContourSequence.push(contourItem);
                }
            }
            item.ContourSequence = item.ContourSequence.length === 1 ? item.ContourSequence[0] : item.ContourSequence;
        }
        dataset.ROIContourSequence.push(item);
    }

    // Populate StructureSetROISequence
    for(let i = 0; i < roiNrs.length; ++i) {
        let roi: any = structureSet.rois[roiNrs[i]];
        dataset.StructureSetROISequence.push({
            ROINumber: roi.roiNumber.toString(),
            ReferencedFrameOfReferenceUID: dataset.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID,
            ROIName: roi.name,
            ROIGenerationAlgorithm: "SEMIAUTOMATIC"
        });
        // https://dicom.innolitics.com/ciods/rt-structure-set/rt-roi-observations/30060080
        dataset.RTROIObservationsSequence.push({
            ObservationNumber: roi.roiNumber,
            ReferencedROINumber: roi.roiNumber,
            ROIObservationLabel: roi.name,
            RTROIInterpretedType: roi.interpretedType,
            ROIInterpreter: ''});
    }

    // do any custom post-ops to the DICOM dataset here
    if (postOperations !== undefined) {
        postOperations(dataset);
    }

    dicomDict.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(dataset);
    // structureSet.toIM(img)  // maybe convert contours back here
    return dicomDict.write();
}

export function printDicomToConsole(structureSet: StructureSet) {
    sleep(50).then(async () => {
        // let arrayBuffer = structureSetToDicom(structureSet);
        // let dicomDict = dcmjs.data.DicomMessage.readFile(arrayBuffer);
        // let dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomDict.dict);
        // dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomDict.meta);
        // console.log(dataset);
        
        console.log(structureSet.dataset);
    });
}

export async function saveStructureSetOnUserDevice(ss: StructureSet, vs: ViewerState, originalDicomFile?: { file: ArrayBuffer, filename: string }, onlyCheckedStructures?: boolean) {
    if (onlyCheckedStructures && !ALLOW_DICOM_MODIFICATIONS) {
        throw new Error('Invalid operation');
    }
    
    const useOriginal = originalDicomFile !== undefined;

    if (!useOriginal && !ALLOW_DICOM_MODIFICATIONS) { throw new Error('Invalid operation: application only supports export of original received DICOM files'); }
    if (!useOriginal) { ss.patientId = vs.image.dicomTags.PatientID; }

    ss.modalMessage = StructureSetModalMessages.Exporting;
    vs.notifyListeners();

    await sleep(500); // This must be really long sleep, otherwise the modal dialog shows as almost transparent (slow fade-in animation)
    const roiNrsToExport = onlyCheckedStructures && vs.hiddenRois[ss.structureSetId] !== undefined ? Object.keys(ss.rois).filter(roiNr => !vs.hiddenRois[ss.structureSetId].has(roiNr)) : undefined;
    const arrayBuffer = useOriginal && !onlyCheckedStructures ? originalDicomFile!.file : structureSetToDicom(ss, vs.image, undefined, roiNrsToExport);
    const filename = useOriginal ? originalDicomFile!.filename : (ss.dataset.StructureSetLabel || ss.structureSetId) + ".dcm";
    const blob = new Blob([arrayBuffer], {type: ""});
    saveFileOnUserDevice(filename, blob);

    // ss.getRois().forEach(roi => {
    //     roi.sdf = null;
    // })
    // vs.generateSdfsIfNeeded(ss);

    ss.modalMessage = null;
    vs.notifyListeners();
    
}

// TODO: this function is NOT supported outside of Import/Export UI use cases!
export async function generateAndSaveMonacoRtDirFileOnUserDevice(ss: StructureSet, vs: ViewerState) {
    const { image } = vs;
    if (image.scanId !== ss.scanId) {
        throw new Error('Current image and structure set image do not match!');
    }

    const { dicomTags } = image;

    const modality = image.modality === Modality.CT ? 'CT' : image.modality === Modality.MR ? 'MR' : undefined;
    if (!modality) {
        throw new Error(`Unsupported modality: ${image.modality}`);
    }

    const rtDirFileLines = [
        '!Version=2',
        modality,
        dicomTags.SOPClassUID,
        dicomTags.SeriesInstanceUID,
        dicomTags.StudyInstanceUID,
        _.get(dicomTags, 'StudyID', '') || 'NO_ID',
        dicomTags.PatientName,
        dicomTags.FrameOfReferenceUID,
        ...dicomTags.ImagePositionPatient,
        ...dicomTags.ImageOrientationPatient,
        'RTSTRUCT',
        '1.2.840.10008.5.1.4.1.1.481.3',
        ss.structureSetId,
        ss.seriesUid,
        ss.studyId || 'NO_ID',
        ss.structureSetDescription || 'NO_DESCRIPTION',
        '', '', '', '',
        0, 0, 0, 0, 0, 0
    ];

    const rtDirFile = rtDirFileLines.join('\n') + '\n';
    const filename = 'RTDIR.dir';
    const blob = new Blob([rtDirFile], {type: ""});
    saveFileOnUserDevice(filename, blob);
}


export function addTime2Rtstruct(arrayBuffer: ArrayBuffer): ArrayBuffer {
    let dicomDict = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    let ds = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomDict.dict);
    ds._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomDict.meta);
    ds.StructureSetDate = getDICOMDate();
    ds.StructureSetTime = getDICOMTime();

    dicomDict.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(ds);
    return dicomDict.write();
}

function clampPoint(point: any): string {
    return Number.parseFloat(point.toString()).toFixed(3);
}
