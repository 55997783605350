import React from 'react';
import { RouteComponentProps, Route, withRouter, Switch } from 'react-router-dom';

import LocalPage from './components/local-file-page/LocalPage';
import Layout from './components/Layout';
import AuthenticatedPage from './components/AuthenticatedPage';

import './App.css'
import './Animations.css'
import './branding.css';
import LoggedOutPage from './components/logged-out-page/LoggedOutPage';

interface IProps extends RouteComponentProps<any> {
}


class App extends React.Component<IProps> {
  public render() {
    return (
      <>
        <Switch>
          <Route exact path='/logged-out' component={LoggedOutPage} />
          <Route path='/'>
            <AuthenticatedPage>
              <Layout>
                <LocalPage />
              </Layout>
            </AuthenticatedPage>
          </Route>
        </Switch>
      </>
    );
  }
}

export default withRouter<IProps, any>(App);
