import React from 'react';
import { Modal, Button, Alert, SplitButton, Dropdown } from 'react-bootstrap';
import { StoreState } from '../../../store/store';
import { connect } from 'react-redux';
import { ContouringTask, ContouringTaskState } from '../../../store/contouring-task';
import { differenceInSeconds } from 'date-fns';
import { getHumanReadableDurationString, notEmpty } from '../../../util';
import { StructureSet, saveStructureSetOnUserDevice, generateAndSaveMonacoRtDirFileOnUserDevice } from '../../../dicom/structure-set';
import { isImportExport, areDebugFeaturesEnabled } from '../../../environments';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import { BsDownload } from 'react-icons/bs';


import './FinishedAutoContouringDialog.css';
import { IconContext } from 'react-icons';
import { getIsMonacoDirFileSupportEnabled } from '../../../local-storage';
import { DirFileSupport } from '../../../store/deployment-config-info';


type OwnProps = {
    show: boolean,
    onHide: () => void,
    scanId: string,
    structureSets: StructureSet[];
    vs: ViewerState;
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

class FinishedAutoContouringDialog extends React.Component<AllProps> {

    constructor(props: AllProps) {
        super(props);
    }

    render() {
        const { show, onHide, scanId, contouringTasks, structureSets, vs } = this.props;

        const task: ContouringTask | undefined = contouringTasks[scanId];
        if (task === undefined) {
            // nothing to show if we have no contouring task
            return null;
        }

        const finishedStructureSets = task.matchingStructureSetIds !== null ? task.matchingStructureSetIds.map(id => structureSets.find(ss => ss.structureSetId === id)) : undefined;
        const originalDicomFiles: { file: ArrayBuffer, filename: string, ssId: string }[] | undefined = finishedStructureSets ? finishedStructureSets.filter(notEmpty).map(ss => ({ ssId: ss.structureSetId, ...(this.props.originalStructureSets[ss.structureSetId]) })) : undefined;
        const areOriginalDicomsAvailable = originalDicomFiles !== undefined && finishedStructureSets !== undefined && originalDicomFiles.length === finishedStructureSets.length;
        const allowStructureSetDownload = areOriginalDicomsAvailable && (isImportExport() || areDebugFeaturesEnabled()) && this.props.applicationPermissions && this.props.applicationPermissions.allowStructureSetDownload;

        const taskFailed = task.contouringState !== ContouringTaskState.Success;
        const taskSucceeded = task.contouringState === ContouringTaskState.Success && finishedStructureSets !== undefined;

        const taskTimeInSeconds = task.taskFinishTime !== null && task.uploadFinishTime !== null ? getHumanReadableDurationString(differenceInSeconds(task.taskFinishTime, task.uploadFinishTime)) : undefined;
        const totalTaskTimeInSeconds = task.taskFinishTime !== null && task.uploadStartTime !== null ? getHumanReadableDurationString(differenceInSeconds(task.taskFinishTime, task.uploadStartTime)) : undefined;
        const timesAreAvailable = taskTimeInSeconds !== undefined && totalTaskTimeInSeconds !== undefined;

        const areDirFilesSupported = this.props.deploymentConfigInfo && this.props.deploymentConfigInfo.dirFileSupport !== DirFileSupport.Disabled && getIsMonacoDirFileSupportEnabled();

        return (
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Segmentation finished</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {taskFailed && (
                            <div>
                                <Alert variant="danger" className="file-load-errors-alert">
                                    <Alert.Heading>Segmentation was unsuccessful:</Alert.Heading>
                                    <div className="file-load-errors">
                                        <div>{task.errorMessage}</div>
                                    </div>
                                </Alert>
                                <div>See more information about this error by opening the detailed view from the left-side panel.</div>
                            </div>
                        )}
                        {taskSucceeded && (
                            <div className="segmentation-succeeded">
                                {timesAreAvailable && (<div>Segmentation was completed in {taskTimeInSeconds} (total process time {totalTaskTimeInSeconds}).</div>)}
                                {allowStructureSetDownload && finishedStructureSets && originalDicomFiles && finishedStructureSets.map(ss => ss && (
                                    <div key={ss.structureSetId}>
                                        <IconContext.Provider value={{ size: '1.3em', style: { marginLeft: '5px' } }}>
                                            {areDirFilesSupported ? (
                                                <SplitButton
                                                    variant="outline-primary"
                                                    id={`finished-auto-contouring-split-button-for-${ss.structureSetId}`}
                                                    onClick={() => saveStructureSetOnUserDevice(ss, vs, originalDicomFiles.find(o => o.ssId === ss.structureSetId))}
                                                    title={(<span><span className="structure-set-download-button-icon clickable-glyph-btn"><BsDownload /></span> Save structure set <b>{ss.label}</b> to disk</span>)}
                                                >
                                                    <Dropdown.Item onClick={() => generateAndSaveMonacoRtDirFileOnUserDevice(ss, vs)} className="save-rtdir">Save RTDIR file to disk</Dropdown.Item>
                                                </SplitButton>
                                            ) : (
                                                    <Button className="structure-set-download-button" onClick={() => saveStructureSetOnUserDevice(ss, vs, originalDicomFiles.find(o => o.ssId === ss.structureSetId))}><span className="structure-set-download-button-icon clickable-glyph-btn"><BsDownload /></span> Save structure set <b>{ss.label}</b> to disk</Button>
                                                )}
                                        </IconContext.Provider>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-info" onClick={onHide}>Close</Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    // sagas.mapDispatchToProps
)(FinishedAutoContouringDialog);
