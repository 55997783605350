// *** ENVIRONMENT TYPES ***

import { getFilenameSafeTimestamp } from "./util";
import { newGuid } from "./dicom/guid";
import { DeploymentConfigInfo } from "./store/deployment-config-info";

/** Returns true if this is an Import/Export UI application. Should be TRUE for production builds. */
export const isImportExport = () => true;

/** Returns true if this is a development version of the application that should enable debug features. Should be FALSE for production builds. */
export const areDebugFeaturesEnabled = () => false;

/// *** APP REGISTRATIONS, AUTHORIZATIONS ***

export const MVAPI_AUTH = 'MVDAPIPROD_AUTH';

export const MVISION_AUTHORITY = 'https://login.microsoftonline.com/mvision.ai';

export const mvapiClientId = '49dd79c5-077f-4fa1-a055-4752e20025bd';

// default auths are needed for rtviewer to work -- these must be logged into on app start
export const defaultAuths = [MVAPI_AUTH];


// *** APP NAME & OTHER GLOBALS ***

const DEFAULT_APP_NAME = 'MVision Contour+ web';
const DEMO_APP_NAME = 'MVision Contour+ web Demo';

const APP_NAME_SHORT = 'importexport';
const DEMO_APP_NAME_SHORT = 'delineationdemo';

let currentAppName = DEFAULT_APP_NAME;
let currentShortAppName = APP_NAME_SHORT;

export const updateAppName = (deploymentConfigInfo: DeploymentConfigInfo) => {
    currentAppName = deploymentConfigInfo.isDemo ? DEMO_APP_NAME : DEFAULT_APP_NAME;
    currentShortAppName = deploymentConfigInfo && deploymentConfigInfo.isDemo ? DEMO_APP_NAME_SHORT : APP_NAME_SHORT;
}

export const getAppName = () => currentAppName;
export const getAppNameShort = () => currentShortAppName;

/** Contour+ web version displayed to users. (The "actual" version of Contour+ web / RTViewer is the Git commit hash.) */
export const DISPLAY_VERSION = '1.2.7';

export const NOT_FOR_CLINICAL_USE_LABELING = 'Disclaimer: This user interface is not for clinical approval of generated structures';

export const SECURITY_DISCLAIMER = 'It is recommended to log out and close the browser tab or use a Lock Screen if you are away from the computer for any significant length of time.';

export const ALLOW_DICOM_MODIFICATIONS = false;


// *** SESSION ID ***

/** Session ID is used to identify this client/session in API calls. */
let sessionId: string;

/** (Re-)generates and sets a new session ID. */
export function generateNewSessionId() {
    sessionId = `${getAppNameShort()}-${getFilenameSafeTimestamp()}-${newGuid()}`.substring(0, 64);
    console.info(`Session ID: ${sessionId}`);
}

/** Returns the current session ID. */
export function getSessionId(): string {
    if (!sessionId) {
        generateNewSessionId();
    }
    
    return sessionId;
}
