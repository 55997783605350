import Immerable from "../store/immerable";
import { RoiMapping } from "./dataset-files";

export class DatasetStructureSet extends Immerable {
    public seriesId: string;
    public sopId: string;
    public scanId: string;
    public approvalStatus: string;
    public label: string;
    public bestMatch: boolean;
    public roiMappings: RoiMapping[];
    public imageSeriesId: string;
    public datasetId: string;

    constructor(datasetId: string, imageSeriesId: string, seriesId: string, sopId: string, scanId: string, approvalStatus: string, label: string, bestMatch: boolean, roiMappings: RoiMapping[]) {
        super();

        this.seriesId = seriesId;
        this.sopId = sopId;
        this.scanId = scanId;
        this.approvalStatus = approvalStatus;
        this.label = label;
        this.bestMatch = bestMatch;
        this.roiMappings = roiMappings;
        this.datasetId = datasetId;
        this.imageSeriesId = imageSeriesId;
    }
}
