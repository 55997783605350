import React from "react";
import { NOT_FOR_CLINICAL_USE_LABELING } from "../environments";


class Footer extends React.Component {
    render() {
        return (
            <div className="sticky-footer">
                <div className="disclaimer-not-for-clinical-use">{NOT_FOR_CLINICAL_USE_LABELING}.</div>
            </div>
        );
    }
}

export default Footer;
