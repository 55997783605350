import React from 'react';
import { OptionsType } from 'react-select';
import { PredictionModel, AvailableModels, MVSEG_PREDICTION_MODEL, getFreeTextEntryModel, updateAvailableModelsWithCustomizationData, FREE_TEXT_ENTRY_LABEL, MVSEG_MODEL } from '../../web-apis/contouring-options';

import './ModelSelect.css';
import { MdAccessTime } from 'react-icons/md';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import { ContouringClient } from '../../web-apis/contouring-client';
import { getBackendClient, getDefaultBackend } from '../../web-apis/auth';
import { Form, InputGroup, ListGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import ModelItem from './ModelItem';
import { ConfigClient } from '../../web-apis/config-client';


type OwnProps = {
    onChange: (model: PredictionModel | undefined) => void,
    onMenuOpen?: () => void,
    currentPredictionModel: PredictionModel | undefined,
    isDisabled?: boolean,
    bodyPartFilter?: string,
    showDeprecatedModels: boolean,
    showHiddenModels: boolean,
}

type AllProps = OwnProps & StoreState;

type OwnState = {
    options: OptionsType<any> | undefined,
    availableModels: AvailableModels | undefined,
    modelFilter: string,
    scrollToModelName?: string,
}

const ALL_BODYPARTS_LABEL = 'All';

const MVSEG_OPTION = { label: (<span>mvseg</span>), action: MVSEG_MODEL, value: MVSEG_MODEL };
const FREE_TEXT_ENTRY_OPTION = { label: (<span>{FREE_TEXT_ENTRY_LABEL}</span>), action: FREE_TEXT_ENTRY_LABEL, value: FREE_TEXT_ENTRY_LABEL };

class ModelSelect extends React.Component<AllProps, OwnState> {

    // override some styles of react-select
    static selectStyles = {
        input: (provided: any) => ({
            ...provided,
            color: "#ffffff"
        }),
    };

    constructor(props: AllProps) {
        super(props);
        this.state = {
            options: undefined,
            availableModels: undefined,
            modelFilter: '',
            scrollToModelName: undefined
        };
    }

    componentDidMount() {
        this.fetchModelsAsync();
    }

    componentDidUpdate(prevProps: AllProps, prevState: OwnState) {
        if (prevProps.bodyPartFilter !== this.props.bodyPartFilter || prevState.modelFilter !== this.state.modelFilter ||
            prevProps.showDeprecatedModels !== this.props.showDeprecatedModels || prevProps.showHiddenModels !== this.props.showHiddenModels) {
            const options = this.getOptions(this.props.bodyPartFilter || "");
            this.setState({ options: options });
        }
    }

    fetchModelsAsync = async () => {
        const { currentPredictionModel, showDeprecatedModels, showHiddenModels } = this.props;

        let availableModels: AvailableModels | undefined = undefined;

        const backend = getDefaultBackend();
        const contouringClient = new ContouringClient(getBackendClient(backend));

        availableModels = await contouringClient.getAvailableModels();

        if (availableModels !== undefined) {
            this.setState({ availableModels: availableModels });
            const options = this.getOptions(this.props.bodyPartFilter || "");
            this.setState({ options: options });

            // fetch customizations only after we already have models
            this.fetchCustomizationAsync();
        }

        if (currentPredictionModel && currentPredictionModel.isDeprecated && !showDeprecatedModels) {
            this.props.onChange(undefined);
        } else if (currentPredictionModel && currentPredictionModel.isHidden && !showHiddenModels) {
            this.props.onChange(undefined);
        } else if (currentPredictionModel) {
            // scroll to correct component
            this.setState({ scrollToModelName: currentPredictionModel.id });
        }
    }

    fetchCustomizationAsync = async () => {
        const { user } = this.props;

        // Check if user is valid
        if (!user || !user.userId) {
            throw new Error('Invalid login');
        }

        const backend = getDefaultBackend();
        const configClient = new ConfigClient(getBackendClient(backend));

        const config = await configClient.getSegmentationConfig(user.userId);

        const { availableModels } = this.state;
        if (availableModels) {
            const updatedModels = updateAvailableModelsWithCustomizationData(availableModels, config);

            this.setState({ availableModels: updatedModels });
        }
    }

    handleScrollToModel = (ref: HTMLDivElement) => {
        // scroll once to the component, then reset scrollToModelName
        ref.scrollIntoView({ block: "center" });
        this.setState({ scrollToModelName: undefined });
    }

    handleChange = (opt: any) => {
        const selectedPredictionModel = opt.value === FREE_TEXT_ENTRY_OPTION.value ? getFreeTextEntryModel('') : opt.value === MVSEG_OPTION.action ? MVSEG_PREDICTION_MODEL : this.state.availableModels ? this.state.availableModels.models.find(m => m.id === opt.value) : undefined;
        this.props.onChange(selectedPredictionModel);
    }

    handleMenuOpen = () => {
        if (this.props.onMenuOpen) {
            this.props.onMenuOpen();
        }
    }

    handleModelFilterChange = (e: any) => {
        this.setState({ modelFilter: e.target.value });
    }

    /** Prettify the list of model select options and add an 'mvseg' entry to it */
    getOptions = (bodyPartFilter: string) => {
        const { availableModels, modelFilter } = this.state;

        // models have not yet loaded in
        if (availableModels === undefined) {
            return [{ label: (<span>Loading available models</span>), action: '', value: '', isDisabled: true }];
        }

        const extraOptions = [];
        if (this.props.applicationPermissions && this.props.applicationPermissions.allowMvsegModel) {
            // show mvseg model if it's enabled
            extraOptions.push(MVSEG_OPTION);
        }
        if (this.props.deploymentConfigInfo && this.props.deploymentConfigInfo.allowModelSelectionTextEntry) {
            // show free text entry option if it's enabled
            extraOptions.push(FREE_TEXT_ENTRY_OPTION);
        }

        // pre-filter options using visibility settings before text filter
        const visibilityFilteredModels = availableModels && availableModels.models
            ? availableModels.models.filter(m => {
                if (m.isAlwaysShown) { return true; }
                if (m.isHidden && !this.props.showHiddenModels) { return false; }
                if (m.isDeprecated && !this.props.showDeprecatedModels) { return false; }
                return true;
            })
            : [];

        // Escape special characters in modelFilter string
        const escapedModelFilter = modelFilter.replace(/[^\w\s]/g, '\\$&');
        // Use availableModels to filter by body part, and then by possible text filter
        const textFilterRegEx = new RegExp(escapedModelFilter, 'i');  // case insensitive regex match
        const filteredModels = (bodyPartFilter && bodyPartFilter !== ALL_BODYPARTS_LABEL
            ? visibilityFilteredModels.filter(m => m.bodyPart === bodyPartFilter.toString())
            : visibilityFilteredModels).filter(o => !!modelFilter ? o.label.match(textFilterRegEx) : o);

        // Create options based on the filtered models
        let options: OptionsType<any> = filteredModels
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(m => ({ label: (<span>{m.label} {!m.isOnline && <MdAccessTime />}</span>), action: m.bodyPart, value: m.id }))

        // Add extra options if bodyPartFilter is "All"
        if (bodyPartFilter && (bodyPartFilter === ALL_BODYPARTS_LABEL || bodyPartFilter === "unknown")) {
            options = options.concat(extraOptions);
        }

        return options;
    }

    render() {
        const { options, modelFilter } = this.state;
        const model = this.props.currentPredictionModel;

        return (
            <div>
                <div className="filter-container">
                    <InputGroup className="mb-2 filter-search-box filter-models-field">
                        <InputGroup.Prepend>
                            <InputGroup.Text className='filter-models-field-icon'><FaSearch /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" placeholder="Filter models..." className="filter-models-field" value={modelFilter || ''} onChange={this.handleModelFilterChange} />
                    </InputGroup>
                </div>


                <ListGroup className='model-customization-list'>
                    {options && options.map((option: any) => (
                        <ModelItem
                            key={option.value}
                            option={option}
                            onSelect={() => this.handleChange(option)}
                            scrollToModelName={this.state.scrollToModelName}
                            handleScrollToModel={this.handleScrollToModel}
                            isActive={model && ((model.isFreeTextEntryModel && option.action === FREE_TEXT_ENTRY_LABEL) || model.id === option.value)}
                        />
                    ))}
                </ListGroup>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    null
)(ModelSelect);
