import React, { Component } from 'react'
import { Tab, Row, Col, Nav, Form } from 'react-bootstrap'
import ModelSelect from '../common/ModelSelect'
import { PredictionModel, getFreeTextEntryModel, MVSEG_MODEL } from '../../web-apis/contouring-options';
import './PredictionModelSelect.css'
import { StoreState } from '../../store/store';
import { connect } from 'react-redux';
import { getBodyPartImageLink } from '../../helpers/getBodyPartIcon';
import RetainedAttributesSelector from './RetainedAttributesSelector';
import * as sagas from '../../store/sagas';

type OwnProps = {
    isAutoContouringInProgress: boolean,
    isModelOptionValid: boolean,
    currentPredictionModel: PredictionModel | undefined,
    autoContouringInProgressWarningStyle: string,
    handleModelChange: (selectedPredictionModel: PredictionModel | undefined) => void,
    selectAutoContouring: () => void,
}

type DispatchProps = {
    setAreDeprecatedModelsVisible: (areVisible: boolean) => void,
    setAreHiddenModelsVisible: (areVisible: boolean) => void,
}

type OwnState = {
    eventKey: string,
}

type AllProps = OwnProps & StoreState & DispatchProps;

const FREE_TEXT_ENTRY_INSTRUCTIONS = 'You can use the free text entry option if you know the exact name of the segmentation model you\'re calling (e.g. its AE Title). \
 You should only use this option if you know what you are doing.';

class PredictionModelSelect extends Component<AllProps, OwnState> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            eventKey: 'All', // Set the default active key
        };
    }

    handleSelect = (selectedKey: string) => {
        this.setState({
            eventKey: selectedKey,
        });
    };

    handleShowDeprecatedModelsChange = () => {
        this.props.setAreDeprecatedModelsVisible(!this.props.showDeprecatedModels);
    }

    handleShowHiddenModelsChange = () => {
        this.props.setAreHiddenModelsVisible(!this.props.showHiddenModels);
    }

    handleTextEntryChange = (evt: any) => {
        const val = evt.target.value;
        this.props.handleModelChange(getFreeTextEntryModel(val));
    }

    render() {
        const { isAutoContouringInProgress,
            isModelOptionValid,
            handleModelChange,
            selectAutoContouring,
            currentPredictionModel,
            autoContouringInProgressWarningStyle,
            showDeprecatedModels,
            showHiddenModels } = this.props;
        const { eventKey } = this.state;

        return (
            <Tab.Container id="left-tabs" defaultActiveKey={eventKey}>
                <Row>
                    <Col sm={3}>
                        <div className="model-select-nav">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="All" onSelect={this.handleSelect} className='nav-link-image'>All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Head" onSelect={this.handleSelect}><img className='nav-link-image' src={getBodyPartImageLink("Head")} alt="head" width={20} />  Head</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Torso" onSelect={this.handleSelect}><img className='nav-link-image' src={getBodyPartImageLink("Torso")} alt="torso" width={20} />  Torso</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Pelvis" onSelect={this.handleSelect}><img className='nav-link-image' src={getBodyPartImageLink("Pelvis")} alt="pelvis" width={20} />  Pelvis</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Combination" onSelect={this.handleSelect}><img className='nav-link-image' src={getBodyPartImageLink("Combination")} alt="combination" width={20} />  Combination</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="WholeBody" onSelect={this.handleSelect}><img className='nav-link-image' src={getBodyPartImageLink("WholeBody")} alt="whole_body" width={20} />  Whole Body</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="unknown" onSelect={this.handleSelect}>Other</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="model-select-filters">
                            <Form>
                                <Form.Group controlId="show-deprecated-models">
                                    <Form.Check type="checkbox" label="Show deprecated models" onChange={this.handleShowDeprecatedModelsChange} checked={showDeprecatedModels || false} />
                                </Form.Group>
                                <Form.Group controlId="show-hidden-models">
                                    <Form.Check type="checkbox" label="Show hidden models" onChange={this.handleShowHiddenModelsChange} checked={showHiddenModels || false} />
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey={eventKey} style={{ display: 'flex' }}>
                                <Col className="model-select-list" lg={5}>
                                    <ModelSelect
                                        onChange={handleModelChange}
                                        onMenuOpen={selectAutoContouring}
                                        currentPredictionModel={currentPredictionModel}
                                        isDisabled={isAutoContouringInProgress}
                                        bodyPartFilter={eventKey}
                                        showDeprecatedModels={showDeprecatedModels || false}
                                        showHiddenModels={showHiddenModels || false}
                                    />
                                </Col>
                                <Col lg={7}>
                                    <div className="model-select-details">
                                        {currentPredictionModel && (
                                            <div className="model-select-details-box">
                                                <div className="model-description">
                                                    {currentPredictionModel.isFreeTextEntryModel ? (<h5>Free text entry</h5>) :(<h5>{currentPredictionModel.label}</h5>)}
                                                    {currentPredictionModel && !currentPredictionModel.isFreeTextEntryModel && currentPredictionModel.id !== MVSEG_MODEL && <h6>({currentPredictionModel.id})</h6>}
                                                    {currentPredictionModel.isFreeTextEntryModel ? (<p>{FREE_TEXT_ENTRY_INSTRUCTIONS}</p>) : currentPredictionModel.modelDescription && <p>{currentPredictionModel.modelDescription}</p>}
                                                    {currentPredictionModel.customizationDescription && <p>{currentPredictionModel.customizationDescription}</p>}
                                                </div>
                                            </div>
                                        )}

                                        {this.props.deploymentConfigInfo &&
                                            this.props.deploymentConfigInfo.allowModelSelectionTextEntry &&
                                            currentPredictionModel &&
                                            currentPredictionModel.isFreeTextEntryModel && (
                                                <Form.Control
                                                    className="filter-models-field model-text-entry flash-on-reveal"
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Enter model name"
                                                    value={currentPredictionModel.modelName}
                                                    onChange={this.handleTextEntryChange}
                                                    disabled={isAutoContouringInProgress}
                                                />
                                            )}

                                        {this.props.currentPredictionModel && !this.props.currentPredictionModel.isOnline && (
                                            <div className="model-select-details-box">
                                                <div className="worker-is-sleeping-message">The worker offering this model is currently offline, so it will take a little longer for the segmentation results to get generated as the worker is being brought back online.</div>
                                            </div>
                                        )}

                                        {(!isModelOptionValid) && (<span className="invalid-prediction-model model-select-details-box">Please select a prediction model.</span>)}
                                        {(this.props.isAutoContouringInProgress) && (<span className={`model-select-details-box ${autoContouringInProgressWarningStyle}`}>Auto-contouring is already in progress. Please wait for the current one to finish.</span>)}

                                        {currentPredictionModel && currentPredictionModel.includedRois.length > 0 && (
                                            <div className="model-select-details-box included-rois">
                                                <div>Following structures are included in this model:</div>
                                                <div>
                                                    <Form.Group controlId="included-rois-box">
                                                        <Form.Control as="textarea" rows={10} disabled={true} value={currentPredictionModel.includedRois.join('\n')}></Form.Control>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        )}

                                        <div className="model-select-details-box">
                                            <RetainedAttributesSelector />
                                        </div>
                                    </div>
                                </Col>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(PredictionModelSelect);
