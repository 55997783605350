import { BackendTier } from "../web-apis/backends";
import _ from "lodash";

export enum DirFileSupport {
    Enabled = 'Enabled',
    Disabled = 'Disabled',
    Experimental = 'Experimental',
}

export type DocumentLink = {
    label: string;
    url: string;
}

export enum DisabledFeature {
    AccessRights = 'AccessRights',
}

export type DeploymentConfigInfo = {
    backendUrl: string;
    backendTier: BackendTier;
    backendName?: string;
    regionInfo?: string;
    regionLicenseUrl?: string;
    allowModelSelectionTextEntry: boolean;
    supportContactInformation?: string;
    isDemo: boolean;
    allowMissingDicomMetaheaderFix: boolean;
    dirFileSupport: DirFileSupport;
    configUIUrl?: string;
    dashboardUrl?: string;
    documentLinks?: DocumentLink[];
    disabledFeatures: DisabledFeature[];
}

/** Default values for "non-optional" optional settings. */
const defaultOptions = {
    allowModelSelectionTextEntry: false,
    isDemo: false,
    allowMissingDicomMetaheaderFix: false,
    dirFileSupport: DirFileSupport.Enabled,
    disabledFeatures: [],
};


// Function to validate if an object is a valid DocumentLink
function isValidDocumentLink(link: any): link is DocumentLink {
    return (
        typeof link === 'object' &&
        'label' in link && typeof link.label === 'string' &&
        'url' in link && typeof link.url === 'string'
    );
}

/** Returns true if queried feature is DISABLED in current deployment, false if it is ENABLED. */
export const isFeatureDisabled = (config: DeploymentConfigInfo, feature: DisabledFeature): boolean => {
    return config.disabledFeatures.includes(feature);
}

export const generateDeploymentConfigInfo = (jsonObject: any): DeploymentConfigInfo | undefined => {
    if ('backendUrl' in jsonObject && 'backendTier' in jsonObject && Object.values(BackendTier).includes(jsonObject['backendTier'])) {
        const deploymentConfigInfo: DeploymentConfigInfo = { backendUrl: jsonObject['backendUrl'], backendTier: jsonObject['backendTier'], ...defaultOptions }
        if ('backendName' in jsonObject) {
            deploymentConfigInfo.backendName = jsonObject['backendName'];
        }
        if ('regionInfo' in jsonObject) {
            deploymentConfigInfo.regionInfo = jsonObject['regionInfo'];
        }
        if ('regionLicenseUrl' in jsonObject) {
            deploymentConfigInfo.regionLicenseUrl = jsonObject['regionLicenseUrl'];
        }
        if ('allowModelSelectionTextEntry' in jsonObject && _.isBoolean(jsonObject['allowModelSelectionTextEntry'])) {
            deploymentConfigInfo.allowModelSelectionTextEntry = jsonObject['allowModelSelectionTextEntry'];
        }
        if ('supportContactInformationMarkdown' in jsonObject) {
            deploymentConfigInfo.supportContactInformation = jsonObject['supportContactInformationMarkdown'];
        }
        if ('isDemo' in jsonObject) {
            deploymentConfigInfo.isDemo = jsonObject['isDemo'];
        }
        if ('documentLinks' in jsonObject && Array.isArray(jsonObject['documentLinks'])) {
            const validLinks = [];

            for (const link of jsonObject['documentLinks']) {
                if (isValidDocumentLink(link)) {
                    validLinks.push(link);
                } 
            }
            // Assign only valid links to deploymentConfigInfo.documentLinks
            deploymentConfigInfo.documentLinks = validLinks;
        }

        if ('allowMissingDicomMetaheaderFix' in jsonObject) {
            deploymentConfigInfo.allowMissingDicomMetaheaderFix = jsonObject['allowMissingDicomMetaheaderFix'];
        }
        if ('dirFileSupport' in jsonObject && Object.values(DirFileSupport).includes(jsonObject['dirFileSupport'])) {
            deploymentConfigInfo.dirFileSupport = jsonObject['dirFileSupport'];
        }
        if ('configUIUrl' in jsonObject) {
            deploymentConfigInfo.configUIUrl = jsonObject['configUIUrl'];
        }
        if ('dashboardUrl' in jsonObject) {
            deploymentConfigInfo.dashboardUrl = jsonObject['dashboardUrl'];
        }

        // check for features that have been disabled in config file
        if ('disabledFeatures' in jsonObject && _.isArray(jsonObject['disabledFeatures'])) {
            const disabledFeatures: DisabledFeature[] = [];
            for (const disabledFeature of jsonObject['disabledFeatures']) {
                if (Object.values(DisabledFeature).includes(disabledFeature)) {
                    disabledFeatures.push(disabledFeature);
                }
            }
            deploymentConfigInfo.disabledFeatures = disabledFeatures;
        }

        return deploymentConfigInfo;
    }

    return undefined;
}
