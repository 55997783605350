//@ts-ignore
import * as dcmjs from 'dcmjs';
import { generateUid } from "./guid";
import { getDICOMDate, getDICOMTime } from "./utils";
import _ from 'lodash';


export class DicomMapAnonReal {
    real2anonUidMap: { [name: string]: string } = {};
    anon2realUidMap: { [name: string]: string } = {};
    PatientID: string = "";
    PatientName: string = "";
    PatientBirthDate: string = "";
    PatientSex: string = "";
    StudyID: string = "";
    isInitialized: boolean = false
}

export function anonymizeSlice(arrayBuffer: ArrayBuffer, dicomMapAnonReal: DicomMapAnonReal, nonAnonymisedDicomAttributesMap: string[]): ArrayBuffer {
    const original = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    
    // convert original DICOM file into a naturalized dataset for easy reference
    const originalDataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(original.dict);
    originalDataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(original.meta);

    // Clone the original dataset at the beginning
    const allOriginalValues = _.cloneDeep(originalDataset);

    // store pseudonymization data so we can link the returned structure set back to this
    // image later on
    if (!dicomMapAnonReal.isInitialized) {
        dicomMapAnonReal.PatientID = originalDataset.PatientID;
        dicomMapAnonReal.PatientName = originalDataset.PatientName;
        dicomMapAnonReal.PatientSex = (originalDataset.PatientSex === undefined) ? "" : originalDataset.PatientSex;
        dicomMapAnonReal.PatientBirthDate = (originalDataset.PatientBirthDate === undefined) ? "" : originalDataset.PatientBirthDate;
        dicomMapAnonReal.StudyID = (originalDataset.StudyID === undefined) ? "" : originalDataset.StudyID;  // may not work as intended when multiple scans are uploaded
        dicomMapAnonReal.isInitialized = true;
    }

    originalDataset.PatientID = "MV-ANON-ID";
    originalDataset.PatientName = "MV-ANON-NAME";
    originalDataset.PatientIdentityRemoved = "YES";
    for (const attr of ['FrameOfReferenceUID', 'StudyInstanceUID', 'SeriesInstanceUID', 'SOPInstanceUID']) {
        if (!(originalDataset[attr] in dicomMapAnonReal.real2anonUidMap)) {
            const anonUid = generateUid()
            dicomMapAnonReal.anon2realUidMap[anonUid] = originalDataset[attr]
            dicomMapAnonReal.real2anonUidMap[originalDataset[attr]] = anonUid
        }
        originalDataset[attr] = dicomMapAnonReal.real2anonUidMap[originalDataset[attr]]
    }

    // pseudo/anonymize and retain specific values from the original dicom, any other
    // tags from the original dicom will get skipped
    const ds: { [name: string]: any } = {
        'PatientName': originalDataset.PatientName,
        'PatientID': originalDataset.PatientID,
        'PatientBirthDate': "",
        'PatientSex': "",

        'StudyInstanceUID': originalDataset.StudyInstanceUID,
        'StudyDate': '',
        'StudyTime': '',
        'ReferringPhysicianName': '',
        'StudyID': '',
        'AccessionNumber': '',

        'ImageType': originalDataset.ImageType,

        'Modality': originalDataset.Modality,
        'SeriesInstanceUID': originalDataset.SeriesInstanceUID,
        'SeriesNumber': (originalDataset.SeriesNumber === undefined) ? "" : originalDataset.SeriesNumber,
        'SeriesDescription': '',
        'PatientPosition': (originalDataset.PatientPosition === undefined) ? "" : originalDataset.PatientPosition,

        'FrameOfReferenceUID': originalDataset.FrameOfReferenceUID,
        'PositionReferenceIndicator': (originalDataset.PositionReferenceIndicator === undefined) ? "" : originalDataset.PositionReferenceIndicator,

        'Manufacturer': (originalDataset.Manufacturer === undefined) ? "" : originalDataset.Manufacturer,
        'ManufacturerModelName': (originalDataset.ManufacturerModelName === undefined) ? "" : originalDataset.ManufacturerModelName,

        'InstanceNumber': (originalDataset.InstanceNumber === undefined) ? "" : originalDataset.InstanceNumber,
        'SamplesPerPixel': (originalDataset.SamplesPerPixel === undefined) ? "" : originalDataset.SamplesPerPixel,
        'PhotometricInterpretation': originalDataset.PhotometricInterpretation,
        'Rows': originalDataset.Rows,
        'Columns': originalDataset.Columns,
        'BitsAllocated': originalDataset.BitsAllocated,
        'BitsStored': originalDataset.BitsStored,
        'HighBit': originalDataset.HighBit,
        'PixelRepresentation': originalDataset.PixelRepresentation,

        'ImageOrientationPatient': originalDataset.ImageOrientationPatient,
        'ImagePositionPatient': originalDataset.ImagePositionPatient,
        'PixelSpacing': originalDataset.PixelSpacing,
        'SliceThickness': originalDataset.SliceThickness,
        'SOPClassUID': originalDataset.SOPClassUID,
        'SOPInstanceUID': originalDataset.SOPInstanceUID,

        'PatientIdentityRemoved': originalDataset.PatientIdentityRemoved,
        'PixelData': originalDataset.PixelData,

        // DAEMON saves them
        'ContentDate': '',
        'ContentTime': '',
        'DeidentificationMethod': 'Confidential attributes removed or replaced by MVision Web Uploader',
        'DeidentificationMethodCodeSequence': [{'CodeValue': '113100', 'CodingSchemeDesignator': 'DCM', 'CodeMeaning': 'Basic Application Confidentiality Profile'}],

        "_meta": {
            "FileMetaInformationVersion": { "Value": [{ "0": 0, "1": 1 }], "vr": "OB" },
            "ImplementationClassUID": { "Value": ["1.2.840.113819.7.1.1997.1.0"], "vr": "UI" },
            "ImplementationVersionName": { "Value": ["MVision AI Oy"], "vr": "SH" },
            "MediaStorageSOPClassUID": { "Value": [originalDataset.SOPClassUID], "vr": "UI" },
            "MediaStorageSOPInstanceUID": { "Value": [originalDataset.SOPInstanceUID], "vr": "UI" },
            "TransferSyntaxUID": originalDataset._meta.TransferSyntaxUID
        },

        "_vrMap": {
            // value representation map will be filled in later
        }
    }
    if ('SpecificCharacterSet' in originalDataset) {
        ds.SpecificCharacterSet = originalDataset.SpecificCharacterSet
    }
    if ('PixelPaddingValue' in originalDataset) {
        ds.PixelPaddingValue = originalDataset.PixelPaddingValue
    }
    if ('Laterality' in originalDataset) {
        ds.Laterality = originalDataset.Laterality
    }
    if (ds.Modality === 'CT') {
        ds.RescaleIntercept = originalDataset.RescaleIntercept
        ds.RescaleSlope = originalDataset.RescaleSlope
        if ('RescaleType' in originalDataset) {
            ds.RescaleType = originalDataset.RescaleType
        }
        ds.KVP = ('KVP' in originalDataset) ? originalDataset.KVP : ""
        // WARNING: DAEMON does not set AcquisitionNumber but it is a required attribute, so setting it here: https://dicom.innolitics.com/ciods/ct-image/ct-image/00200012
        ds.AcquisitionNumber = ""  // ('AcquisitionNumber' in dataset) ? dataset.AcquisitionNumber : ""
    } else if (ds.Modality === 'MR') {
        ds.ScanningSequence = ('ScanningSequence' in originalDataset) ? originalDataset.ScanningSequence : ""
        ds.SequenceVariant = ('SequenceVariant' in originalDataset) ? originalDataset.SequenceVariant : ""
        ds.ScanOptions = ""  // ('ScanOptions' in originalDataset) ? originalDataset.ScanOptions : ""
        ds.MRAcquisitionType = ""  // ('MRAcquisitionType' in originalDataset) ? originalDataset.MRAcquisitionType : ""
        ds.EchoTime = ""  // ('EchoTime' in originalDataset) ? originalDataset.EchoTime : ""
        ds.EchoTrainLength = ""  // ('EchoTrainLength' in originalDataset) ? originalDataset.EchoTrainLength : ""
    }

    // set value representations
    if (_.has(originalDataset, '_vrMap.PixelData')) {
        ds._vrMap.PixelData = originalDataset._vrMap.PixelData;
    }
    if (_.has(originalDataset, '_vrMap.RescaleIntercept')) {
        ds._vrMap.RescaleIntercept = originalDataset._vrMap.RescaleIntercept;
    }
    
    // Restore original values for non-anonymized DICOM attributes
    nonAnonymisedDicomAttributesMap.forEach(dicomAttribute => {
        if (_.has(allOriginalValues, dicomAttribute)) {
            ds[dicomAttribute] = allOriginalValues[dicomAttribute];
        }
    });

    // convert anonymized dataset object to a dcmjs object
    const anonymized = dcmjs.data.datasetToDict(ds);

    // de-naturalize the core dictionary entries
    anonymized.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(ds);

    // write and return the dicom file
    return anonymized.write();
}

export function unAnonymizeRtstruct(arrayBuffer: ArrayBuffer, dicomMapAnonReal: DicomMapAnonReal): ArrayBuffer {
    let dicomDict = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    let ds = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomDict.dict);
    ds._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomDict.meta);
    ds.PatientName = dicomMapAnonReal.PatientName
    ds.PatientID = dicomMapAnonReal.PatientID
    ds.PatientBirthDate = dicomMapAnonReal.PatientBirthDate
    ds.PatientSex = dicomMapAnonReal.PatientSex
    ds.StudyID = dicomMapAnonReal.StudyID
    ds.StudyInstanceUID = dicomMapAnonReal.anon2realUidMap[ds.StudyInstanceUID]
    // daemon (1.4.0) does not save StructureSetDate/StructureSetTime
    ds.StructureSetDate = getDICOMDate();
    ds.StructureSetTime = getDICOMTime();
    ds.InstanceCreationDate = getDICOMDate();
    ds.InstanceCreationTime = getDICOMTime();

    ds.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID = dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.FrameOfReferenceUID]
    ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID = dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.SeriesInstanceUID]
    ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.ReferencedSOPInstanceUID = dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.ReferencedSOPInstanceUID]

    if (ds.StructureSetROISequence !== undefined) {
        for (let i=0; i<ds.StructureSetROISequence.length;i++){
            if (ds.StructureSetROISequence[i].ReferencedFrameOfReferenceUID !== undefined) {
                ds.StructureSetROISequence[i].ReferencedFrameOfReferenceUID = dicomMapAnonReal.anon2realUidMap[ds.StructureSetROISequence[i].ReferencedFrameOfReferenceUID]
            }
        }
    }
    if (ds.ROIContourSequence !== undefined) {
        for (let i = 0; i < ds.ROIContourSequence.length; i++) {
            if (ds.ROIContourSequence[i].ContourSequence !== undefined) {
                for (let j = 0; j < ds.ROIContourSequence[i].ContourSequence.length; j++) {
                    if (ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence !== undefined
                        && ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence.ReferencedSOPInstanceUID) {
                        ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence.ReferencedSOPInstanceUID =
                            dicomMapAnonReal.anon2realUidMap[ds.ROIContourSequence[i].ContourSequence[j].ContourImageSequence.ReferencedSOPInstanceUID]
                    }
                }
            }
        }
    }
    if (ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence !== undefined) {
        for (let i = 0; i < ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence.length; i++) {
            if (ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[i].ReferencedSOPInstanceUID !== undefined) {
                ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[i].ReferencedSOPInstanceUID =
                    dicomMapAnonReal.anon2realUidMap[ds.ReferencedFrameOfReferenceSequence.RTReferencedStudySequence.RTReferencedSeriesSequence.ContourImageSequence[i].ReferencedSOPInstanceUID]
            }
        }
    }

    dicomDict.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(ds);
    return dicomDict.write();
}
