/* Structure set table */

import './StructureSetTable.css';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Menu, Item, contextMenu } from 'react-contexify';
import { BsDownload } from 'react-icons/bs';
import { IconContext } from 'react-icons';

import * as sagas from '../../store/sagas';
import { NewItemGlyph } from '../glyphs';
import * as structureSet from '../../dicom/structure-set';
import { ContouringTask, ContouringTaskState } from '../../store/contouring-task';
import { ViewerState } from '../../rtviewer-core/viewer-state';
import * as grading from '../../datasets/roi-grading'
import { ApprovalIndicator } from '../misc-components';
import ContouringRequest from './ContouringRequest';
import { StoreState } from '../../store/store';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import WorkState from '../../store/work-state';
import { areDebugFeaturesEnabled, isImportExport } from '../../environments';
import ModalDialog from '../common/ModalDialog';
import { getIsMonacoDirFileSupportEnabled } from '../../local-storage';
import { DirFileSupport } from '../../store/deployment-config-info';

type OwnProps = {
    viewerState: ViewerState,
    structureSets: structureSet.StructureSet[],
    datasetImage?: DatasetImage,
    newStructureSets: string[],
    allDatasetGradings: grading.DatasetGradings | null,
    openAddStructuresFromTemplateDialog: () => void,
    onAddRoiClick: (ss: structureSet.StructureSet) => void,
    throwError: (msg: string) => void,
    doGradingSync: (ss: structureSet.StructureSet) => void,
}

type DispatchProps = {
    undoStructureSetChanges(ss: structureSet.StructureSet): structureSet.StructureSet | null,
    deleteStructureSet(structureSet: structureSet.StructureSet): void,
    storeNewStructureSet(structureSet: structureSet.StructureSet): void,
    seenStructureSet(structureSetId: string): void,
    dismissContouringTask: (scanId: string) => void,
    handleUndoStructureSetChangesClick: (ss: structureSet.StructureSet) => void,
    handleNewStructureSetClick: () => void,
    saveGrading: (structureSetId: string, ssGrading: grading.StructureSetGrading | null, dataset: Dataset) => void,
    updateCurrentWorkState: (updatedWorkStateProps: Partial<WorkState>) => void,
}

type OwnState = {
    refreshSwitch?: any,
    renameStructureSetId?: string | null,
    newLabel?: string,
    showDeleteRoiDialog: boolean,
    originalDicomFile: { file: ArrayBuffer, filename: string } | null,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class StructureSetTable extends React.Component<AllProps, OwnState> {


    constructor(props: AllProps) {
        super(props);
        this.state = {
            showDeleteRoiDialog: false,
            originalDicomFile: null,
        };
    }

    componentDidMount() {
        const vs = this.props.viewerState;
        vs.addListener(this.updateView);
        this.updateView();
    }

    componentWillUnmount() {
        const vs = this.props.viewerState;
        vs.removeListener(this.updateView);
    }

    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });
    }


    handleViewDicomClick = (ss: structureSet.StructureSet, viewImage: boolean) => {
        let vs = this.props.viewerState;
        if (viewImage) {
            console.log(vs.image.dicomTags);
        }
        else {
            structureSet.printDicomToConsole(ss);
        }
    }

    setStructureSetAsSeen = (structureSetId: string) => {
        this.props.seenStructureSet(structureSetId);
    }

    getContouringTasks = (): ContouringTask[] => {
        const { contouringTasks } = this.props;
        const inProgressTasks: ContouringTask[] = [];
        Object.keys(contouringTasks).forEach(k => {
            const task = contouringTasks[k];
            if (task.contouringState !== ContouringTaskState.Success && !task.isDismissed) {
                inProgressTasks.push(task);
            }
        });
        return inProgressTasks;
    }

    handleSelectStructureSet(ss: structureSet.StructureSet) {
        const vs = this.props.viewerState;
        const image = vs.image;
        vs.setSelectedStructureSet(ss, image);

        // update current work state
        this.props.updateCurrentWorkState({ structureSetId: ss.structureSetId });
    }

    handleShowStructureSetContextMenu = (structureSetMenuId: string, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: structureSetMenuId, event: evt });
    }

    renderStructureSetRow = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        const ssId = ss.structureSetId;
        const selected = ss === vs.selectedStructureSet;
        const label = ss.dataset.StructureSetLabel;

        let t = this;
        function renderName() {
            return ss.unsaved ? (<b>{label}</b>) : label;
        }
        if (ss.deleted) { return null; }

        const isNew = this.props.newStructureSets.indexOf(ssId) !== -1;
        const originalDicomFile: { file: ArrayBuffer, filename: string } | undefined = this.props.originalStructureSets[ssId];
        const isOriginalDicomAvailable = originalDicomFile !== undefined;
        const allowStructureSetDownload = isOriginalDicomAvailable && (isImportExport() || areDebugFeaturesEnabled()) && this.props.applicationPermissions && this.props.applicationPermissions.allowStructureSetDownload;
        const allowRtDirFileDownload = allowStructureSetDownload && this.props.deploymentConfigInfo && this.props.deploymentConfigInfo.dirFileSupport !== DirFileSupport.Disabled && getIsMonacoDirFileSupportEnabled();

        let rowStyles = 'structure-set-row';
        if (selected) { rowStyles += ' structure-set-selected' }
        if (isNew) { rowStyles += ' structure-set-is-new' }

        const downloadColumnWidth = 0 + (allowStructureSetDownload ? 20 : 0) + (allowRtDirFileDownload ? 60 : 0);

        return (
            <tr className={rowStyles} key={ssId}
                onClick={(evt) => { this.handleSelectStructureSet(ss) }}
                onMouseOver={(evt) => { if (isNew) this.setStructureSetAsSeen(ssId) }}  >
                <td>
                    <ApprovalIndicator approvalStatus={ss.dataset.ApprovalStatus} />
                    <Menu id={ssId} style={{ zIndex: 1000 }} animation={false} className="structure-set-context-menu">
                        {areDebugFeaturesEnabled() && <Item onClick={(event) => this.handleViewDicomClick(ss, true)}>View DICOM tags in console (image)</Item>}
                        {areDebugFeaturesEnabled() && <Item onClick={(event) => this.handleViewDicomClick(ss, false)}>View DICOM tags in console (structure set)</Item>}
                        {allowStructureSetDownload && <Item onClick={() => structureSet.saveStructureSetOnUserDevice(ss, vs, originalDicomFile)}>Save to disk as DICOM file</Item>}
                        {allowRtDirFileDownload && <Item onClick={() => structureSet.generateAndSaveMonacoRtDirFileOnUserDevice(ss, vs)}>Save RTDIR file to disk</Item>}
                    </Menu>
                </td>
                <td className="structure-set-name">
                    <div onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.handleShowStructureSetContextMenu(ssId, evt); }}>
                        {renderName()}
                    </div>
                </td>
                <td>
                    <div style={{ width: downloadColumnWidth !== 0 ? `${downloadColumnWidth}px`: 0 }}>
                        {allowStructureSetDownload &&
                            (<IconContext.Provider value={{ size: '1.0em' }}>
                                <span className="clickable-glyph emphasis" title="Save to disk as DICOM file" onClick={() => structureSet.saveStructureSetOnUserDevice(ss, vs, originalDicomFile)}>
                                    <BsDownload />
                                </span>
                            </IconContext.Provider>)}
                        {allowRtDirFileDownload &&
                            (<IconContext.Provider value={{ size: '1.0em' }}>
                                <span style={{ marginLeft: '20px' }} className="clickable-glyph emphasis" title="Save RTDIR file to disk" onClick={() => structureSet.generateAndSaveMonacoRtDirFileOnUserDevice(ss, vs)}>
                                    <BsDownload /> <span style={{ fontSize: '12px' }}> DIR</span>
                                </span>
                            </IconContext.Provider>)}
                    </div>
                </td>

            </tr>
        );
    }

    render() {
        const { structureSets } = this.props;
        const ssRequiringModal = structureSets.find(ss => ss.modalMessage);
        const modalText = ssRequiringModal ? ssRequiringModal.modalMessage : null;
        const vs = this.props.viewerState;

        return (
            <>
                <table className="structure-set-table">
                    <thead>
                        <tr>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {structureSets.map(ss => this.renderStructureSetRow(ss))}
                        {this.getContouringTasks().map(ct => (<ContouringRequest contouringTask={ct} key={ct.scanId} dismissContouringTask={this.props.dismissContouringTask} />))}
                        <tr className="new-structure-set-button">
                            <td colSpan={3}>
                                <Button variant="info" className="btn btn-default btn-sm" onClick={this.props.handleNewStructureSetClick} disabled={!vs.canCreateRtstruct}>
                                    <NewItemGlyph /> New structure set...
                            </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ModalDialog show={!!modalText} onHide={() => { }}>
                    <Modal.Header>
                        <Modal.Title>Please wait</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalText}</Modal.Body>
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(StructureSetTable);
