import _ from "lodash";
import { AppAuthStatesCollection } from "./auth-state";
import { liveReviewDownloadKey, StoreState } from "./store";
import { User } from "./user";

export function getHasUploadFailed(scanId: string) {
    return (store: StoreState) => store.uploads[scanId].failed;
}

export function getAuthState(authAppName: string) {
    return (store: StoreState) => (store.appAuthStatesCollection as AppAuthStatesCollection).getAppAuthState(authAppName);
}

export function getAuthStatesThatNeedLogin() {
    return (store: StoreState) => (store.appAuthStatesCollection as AppAuthStatesCollection).getAppsThatNeedLogin();
}

export function getAuthStatesAreLoginsNeeded() {
    return (store: StoreState) => (store.appAuthStatesCollection as AppAuthStatesCollection).isWaitingForLogins();
}

export function isLiveReviewDownloadInitialized() {
    return (store: StoreState) => _.has(store.downloads, liveReviewDownloadKey) && store.downloads[liveReviewDownloadKey];
}

export function getUser() {
    return (store: StoreState) => store.user as User;
}

export function getDataset(datasetId: string) {
    return (store: StoreState) => store.datasets[datasetId];
}

export function getLocksForDataset(datasetId: string) {
    return (store: StoreState) => store.datasetLocks[datasetId];
}

export function getModifiedDatasetGradings(datasetId: string) {
    return (store: StoreState) => store.modifiedDatasetGradings[datasetId] || [];
}

export function getLoginError() {
    return (store: StoreState) => store.loginError;
}

export function getWasLoginSuccessful() {
    return (store: StoreState) => store.loginError === null;
}

/** Returns current user's access rights. */
export function getUserAccessRights() {
    return (state: StoreState) => state.accessRights;
}

export function getDeploymentConfig() {
    return (state: StoreState) => state.deploymentConfigInfo;
}
