import { DeploymentConfigInfo } from "./deployment-config-info";

export type ApplicationPermissions = {

    /** Enable (and in some cases show) the web demo service agreement in the UI. */
    showServiceAgreement: boolean;

    /** Allow user to download structure sets through the UI. */
    allowStructureSetDownload: boolean;

    /** Allow the use of the special 'mvseg' segmentation model. */
    allowMvsegModel: boolean;

    // allowEditFeatures: boolean;
    // showDicomInfoOnMainPlane: boolean;
    // allowDebugOperations: boolean;
    // alwaysAnonymizeSentDicoms: boolean;
}

/** Default app permissions for regular production Import/Export use */
const DEFAULT_PERMISSIONS: ApplicationPermissions = {
    showServiceAgreement: false,
    allowStructureSetDownload: true,
    allowMvsegModel: true,

    // allowEditFeatures: false,
    // showDicomInfoOnMainPlane: true,
    // allowDebugOperations: false,
    // alwaysAnonymizeSentDicoms: true,
};

/** App permissions for demo deployments */
const DEMO_PERMISSIONS: ApplicationPermissions = {
    showServiceAgreement: true,
    allowStructureSetDownload: true,
    allowMvsegModel: false,

    // allowEditFeatures: false,
    // showDicomInfoOnMainPlane: false,
    // allowDebugOperations: false,
    // alwaysAnonymizeSentDicoms: true,
};

export const createApplicationPermissions = (deploymentConfig: DeploymentConfigInfo): ApplicationPermissions => {
    const { isDemo } = deploymentConfig;

    if (isDemo) {
        return DEMO_PERMISSIONS;
    } else {
        return DEFAULT_PERMISSIONS;
    }
}
