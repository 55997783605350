import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '../store/store';
import { AppAuthStatesCollection } from '../store/auth-state';
import { Alert } from 'react-bootstrap';
import { getAppName } from '../environments';
import * as sagas from '../store/sagas';
import { isInitialAccessRights } from '../web-apis/access-rights';


type OwnProps = {}

type DispatchProps = {
    initializeApp: () => void,
}


type AllProps = OwnProps & DispatchProps & StoreState;

// Wrapper page for all page components
class AuthenticatedPage extends React.Component<PropsWithChildren<AllProps>> {
    displayName = AuthenticatedPage.name

    componentDidMount = () => {
        document.title = getAppName();

        // initialize the application
        this.props.initializeApp();
    }

    render() {
        const appAuthStatesCollection = this.props.appAuthStatesCollection as AppAuthStatesCollection;
        const loginError: Error | null = this.props.loginError;
        const accessRights = this.props.accessRights;
        const accessRightsError = this.props.accessRightsError;
        const wasLoginSuccessful = loginError === null && accessRightsError === null;
        const isWaitingForLogin = appAuthStatesCollection.areAppStartLoginsPending();
        const hasAccessRights = wasLoginSuccessful && accessRights && accessRights.web_ui.includes('access');
        const isLoadingAccessRights = wasLoginSuccessful && accessRights && isInitialAccessRights(accessRights);

        const loginErrorMessage = accessRightsError !== null ? accessRightsError : !wasLoginSuccessful && loginError && loginError.message;

        return (
            <>
                {!wasLoginSuccessful && loginErrorMessage && (<Alert variant="warning">
                    <Alert.Heading>An error occurred during login</Alert.Heading>
                    <p>
                        An error occurred when trying to log you in:
                    </p>
                    <p>{loginErrorMessage}</p>
                    <p>
                        If this error persists, please contact a system administrator.
                    </p>
                </Alert>)}
                {!isWaitingForLogin && !hasAccessRights && !isLoadingAccessRights && (<Alert variant='danger' style={{ paddingTop: '1.5rem' }}>
                    <Alert.Heading>Access Forbidden</Alert.Heading>
                    <p>
                        You do not have access rights to this application. If you think this is in error please contact a system administrator.
                    </p>
                </Alert>
                )}
                {isWaitingForLogin && !loginErrorMessage && (<div style={{ color: 'white' }}>Waiting for login...</div>)}
                {!isWaitingForLogin && hasAccessRights && this.props.children}
            </>
        );
    }
}


export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(AuthenticatedPage);

