import React from "react";
import { Button, Modal } from "react-bootstrap";
import "react-contexify/dist/ReactContexify.min.css";

import { ViewerState } from "../../../rtviewer-core/viewer-state";
import ModalDialog from "../../common/ModalDialog";

type OwnProps = {
    viewerState: ViewerState;
    isVisible: boolean;
    onClose: () => void;
    handleUndoClick: () => void;
};


type OwnState = {};

class UndoAllDialog extends React.Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);

        this.state = {};
    }

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        return (
            <ModalDialog
                size='lg'
                show={this.props.isVisible}
                onHide={this.handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Undo All Changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to undo all changes?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={this.props.handleUndoClick}
                    >
                        Undo all changes
                    </Button>
                    <Button
                        
                        onClick={this.handleClose}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default UndoAllDialog
