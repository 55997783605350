// Annotation Query class models a direct link to a specific annotation item (i.e. a DICOM image in a specific file share).
// It's generally used for interacting with URL query parameters.

const QUERY_PARAM_STORAGE_ACCOUNT = 'sa';
const QUERY_PARAM_FILE_SHARE = 'fs';
const QUERY_PARAM_PATIENT_NAME = 'pn';
const QUERY_PARAM_FRAME_OF_REFERENCE = 'for';
const QUERY_PARAM_SERIES_INSTANCE = 'si';
const QUERY_PARAM_DEFAULT_STRUCTURE_SET = 'dss';

/** Query parameters that are required to identify a specific annotation work (a scan and optionally a structure set).
 * Optional parameters are not included in this list.
 */
const REQUIRED_QUERY_PARAMS_FOR_WORK = [
    QUERY_PARAM_STORAGE_ACCOUNT, QUERY_PARAM_FILE_SHARE, QUERY_PARAM_PATIENT_NAME, QUERY_PARAM_FRAME_OF_REFERENCE, QUERY_PARAM_SERIES_INSTANCE,
];

/** Query parameters that are required to identify a specific annotation storage account (and optionally a file share).
 * Optional parameters are not included in this list.
 */
const REQUIRED_QUERY_PARAMS_FOR_INDEX = [
    QUERY_PARAM_STORAGE_ACCOUNT,
];

/** a regex of all characters that are valid in query parameters */
const validCharacters = /^[- a-zA-Z0-9._]*$/;

export default class AnnotationQuery {
    public storageAccount: string;
    public fileShare?: string;
    /** this property is set, but is currently unused */
    public patientName?: string;
    /** this property is set, but is currently unused */
    public frameOfReferenceUid?: string;
    public imageSeriesInstanceUid?: string;
    public defaultStructureSetUid?: string;

    constructor(storageAccount: string, fileShare?: string);
    constructor(storageAccount: string, fileShare: string, patientName: string, frameOfReferenceUid: string, imageSeriesInstanceUid: string, defaultStructureSetUid?: string);
    constructor(storageAccount: string, fileShare?: string, patientName?: string, frameOfReferenceUid?: string, imageSeriesInstanceUid?: string, defaultStructureSetUid?: string) {
        this.storageAccount = storageAccount;
        this.fileShare = fileShare;
        this.patientName = patientName;
        this.frameOfReferenceUid = frameOfReferenceUid;
        this.imageSeriesInstanceUid = imageSeriesInstanceUid;
        this.defaultStructureSetUid = defaultStructureSetUid;

        // work-specific arguments have to be either all set (except defaultStructureSetUid which is optional) or none of them 
        if (![patientName, frameOfReferenceUid, imageSeriesInstanceUid, defaultStructureSetUid].every(arg => arg === undefined)
            && ![patientName, frameOfReferenceUid, imageSeriesInstanceUid].every(arg => arg !== undefined)) {
            throw new Error('Invalid set of annotation query parameters');
        }
    }

    getQueryParameters(): string {
        const qp = new URLSearchParams();

        qp.set(QUERY_PARAM_STORAGE_ACCOUNT, this.storageAccount);
        if (!!this.fileShare) { qp.set(QUERY_PARAM_FILE_SHARE, this.fileShare); }
        if (!!this.patientName) { qp.set(QUERY_PARAM_PATIENT_NAME, this.patientName); }
        if (!!this.frameOfReferenceUid) { qp.set(QUERY_PARAM_FRAME_OF_REFERENCE, this.frameOfReferenceUid); }
        if (!!this.imageSeriesInstanceUid) { qp.set(QUERY_PARAM_SERIES_INSTANCE, this.imageSeriesInstanceUid); }
        if (!!this.defaultStructureSetUid) { qp.set(QUERY_PARAM_DEFAULT_STRUCTURE_SET, this.defaultStructureSetUid); }

        return qp.toString();
    }

    /** Returns true if this annotation query identifies a specific annotation work, false otherwise. */
    hasWork(): boolean {
        return !!this.fileShare && !!this.patientName && !!this.frameOfReferenceUid && !!this.imageSeriesInstanceUid;
    }

    /** Returns true if this annotation query identifies a specific annotation page index, false otherwise. */
    hasDatasetIndex(): boolean {
        // technically always true unless a truly wrong value (such as an empty string) is entered
        return !!this.storageAccount;
    }

    private static getSupportedQueryParameter(qp: URLSearchParams, queryParameter: string): string | null {
        const value = qp.get(queryParameter);
        if (value === null) { return null; }

        if (!validCharacters.test(value)) {
            throw new Error(`URL contains unsupported characters: ${queryParameter}=${value}`);
        }

        return value;
    }

    static fromQueryParameter(queryParameter: URLSearchParams | string): AnnotationQuery | null {
        let qp: URLSearchParams;
        if (!(queryParameter instanceof URLSearchParams)) {
            qp = new URLSearchParams(queryParameter);
        } else {
            qp = queryParameter;
        }

        // detect if given query parameters are valid for linking directly to work, or annotation
        // page index, or neither
        if (REQUIRED_QUERY_PARAMS_FOR_WORK.every(workParam => qp.get(workParam) !== null)) {
            return new AnnotationQuery(
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_STORAGE_ACCOUNT) || '',
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_FILE_SHARE) || '',
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_PATIENT_NAME) || '',
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_FRAME_OF_REFERENCE) || '',
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_SERIES_INSTANCE) || '',
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_DEFAULT_STRUCTURE_SET) || undefined)
        } else if (REQUIRED_QUERY_PARAMS_FOR_INDEX.every(indexParam => qp.get(indexParam) !== null)) {
            return new AnnotationQuery(
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_STORAGE_ACCOUNT) || '',
                AnnotationQuery.getSupportedQueryParameter(qp, QUERY_PARAM_FILE_SHARE) || undefined,
            );
        } else {
            return null;
        }
    }

}
