export class UserPermissions {
    canApproveRTStructsForTraining: boolean;
    canSetRTStructsAsObsolete: boolean;
    canSetRTStructsAsExpertApproved: boolean;
    canChangeBackend: boolean;
    allowDebugMode: boolean;
    allowForceUnlock: boolean;
    allowStructureSetDownload: boolean;

    constructor(basePermissions: any = {}) {
        // default values
        this.canApproveRTStructsForTraining = false;
        this.canSetRTStructsAsObsolete = false;
        this.canSetRTStructsAsExpertApproved = false;
        this.canChangeBackend = false;
        this.allowDebugMode = false;
        this.allowForceUnlock = false;
        this.allowStructureSetDownload = false;

        // overrides
        if (Array.isArray(basePermissions)) {
            if (basePermissions.includes('canApproveRTStructsForTraining')) {
                this.canApproveRTStructsForTraining = true;
                this.canSetRTStructsAsObsolete = true;
            }

            if (basePermissions.includes('canSetExpertApproved')) {
                this.canSetRTStructsAsExpertApproved = true;
            }

            if (basePermissions.includes('canChangeBackend')) {
                this.canChangeBackend = true;
            }

            if (basePermissions.includes('allowDebugMode')) {
                this.allowDebugMode = true;
            }

            if (basePermissions.includes('allowForceUnlock')) {
                this.allowForceUnlock = true;
            }

            if (basePermissions.includes('allowStructureSetDownload')) {
                this.allowStructureSetDownload = true;
            }
        }
    }

}