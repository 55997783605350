import React from 'react';

import './glyphs.css';

export const NewItemGlyph = () => {
  return (
    <span className="new-item-glyph">
      <svg height="12" width="12">
        <rect x="5" y="1" width="2" height="10" rx="1" ry="1" fill="#ffffff" />
        <rect x="1" y="5" width="10" height="2" rx="1" ry="1" fill="#ffffff" />
      </svg>
    </span>
  );
}
