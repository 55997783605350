import { formatISO, parse } from "date-fns";

export const axisAlignedOrientation: number[] = [1, 0, 0, 0, 1, 0, 0, 0, 1]

export function sleep(ms: number) {
     return new Promise(resolve => setTimeout(resolve, ms));
}

export function equal(x1: number, x2: number, epsilon: number = 0.0001) {
     return Math.abs(x1 - x2) < epsilon;
}

export function num2str(num: number, fractionDigits: number): string {
     return num.toFixed(fractionDigits).toString();
}

export function generateRandomString(length: number): string {
     let result = [];
     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     for (let i = 0; i < length; i++) {
          result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
     }
     return result.join('');
}

export function convertToFileSafe(str: string) {
     return str.replace(/[^a-z0-9]/gi, '_');
}

export const TEMP_FILENAME_DATE_USERNAME_DELIMITER = '__';

// Temporary name format: y_MM_dd_HH_mm_ss_SSS__<username> (note
// the two underscores between date and <username>). Putting the
// date before username ensures a bit better alphanumerical sorting.
// '__<username>' suffix is omitted if username param is not defined.
// Current time and date is used if not supplied.
export function getTemporaryFilename(username?: string, date?: Date) {
     const dateString = (getDateTimeString(date || new Date()));
     const fileSuffix = username ? `${TEMP_FILENAME_DATE_USERNAME_DELIMITER}${username}` : '';
     return convertToFileSafe(`${dateString}${fileSuffix}`);
}

// Get date time string that can be used in filenames
export function getDateTimeString(date: Date): string {
     let year = date.getFullYear().toString();
     let month = (date.getMonth() + 1).toString();
     let day = date.getDate().toString();
     let hour = date.getHours().toString();
     let minute = date.getMinutes().toString();
     let second = date.getSeconds().toString();
     let millis = date.getMilliseconds().toString();
     if (month.length === 1) {
          month = '0' + month;
     }
     if (day.length === 1) {
          day = '0' + day;
     }
     if (hour.length === 1) {
          hour = '0' + hour;
     }
     if (minute.length === 1) {
          minute = '0' + minute;
     }
     if (second.length === 1) {
          second = '0' + second;
     }
     if (millis.length === 1) {
          millis = '00' + millis;
     }
     else if (millis.length === 2) {
          millis = '0' + millis;
     }
     return year + '.' + month + '.' + day + '-' + hour + '.' + minute + '.' + second + "." + millis;
}

export function getDateFromTempFileString(tempFilenameDateString: string): Date | null {
     // remove the possible user name from the string
     let dateString = tempFilenameDateString;
     const split = tempFilenameDateString.split(TEMP_FILENAME_DATE_USERNAME_DELIMITER);
     if (split.length > 1) {
          dateString = split[0];
     }

     const date = parse(dateString, 'y_MM_dd_HH_mm_ss_SSS', new Date(0));

     if (Number.isNaN(date.getTime())) {
          return null;
     }

     return date;
}

// Get a duration string that looks like "1:02:03" or "3:41".
// Numbers and seconds are always present, hours are displayed only if
// the duration is longer than 59:59. Seconds will always have leading
// zeroes, minutes only if hours are displayed.
// Note that since this presentation format does not display units it's
// best used only for actively updating information (so the user can see
// that the duration is increased every second).
export function getDurationString(durationInSeconds: number): string {
     const hours = Math.floor(durationInSeconds / (60 * 60));
     const minutes = Math.floor((durationInSeconds - hours * 60 * 60) / 60);
     const seconds = durationInSeconds - hours * 60 * 60 - minutes * 60;

     const hoursAsString = hours > 0 ? `${hours}:` : '';
     const minutesAsString = `${hours > 0 && 0 <= minutes && minutes <= 9 ? '0' : ''}${minutes}`;
     const secondsAsString = `${0 <= seconds && seconds <= 9 ? '0' : ''}${seconds}`;
     return `${hoursAsString}${minutesAsString}:${secondsAsString}`;
}

export function getHumanReadableDurationString(durationInSeconds: number): string {
     const hours = Math.floor(durationInSeconds / (60 * 60));
     const minutes = Math.floor((durationInSeconds - hours * 60 * 60) / 60);
     const seconds = durationInSeconds - hours * 60 * 60 - minutes * 60;

     const hoursAsString = hours > 0 ? hours : '';
     const minutesAsString = minutes > 0 ? minutes : '';
     const secondsAsString = seconds > 0 ? seconds : '';

     let time = '';
     if (hours > 0) { time += `${hoursAsString} hour${hours === 1 ? '' : 's'}`; }
     if (minutes > 0) { time += `${time ? ', ' : ''}${minutesAsString} minute${minutes === 1 ? '' : 's'}`; }
     if (seconds > 0) { time += `${time ? ', ' : ''}${secondsAsString} second${seconds === 1 ? '' : 's'}`; }

     return time;
}

export function getFilenameSafeTimestamp(): string {
     return (formatISO(new Date(), { format: 'basic' })).replace(':', '').replace('+', '_');
}

export function clamp(val: number, minVal: number, maxVal: number) {
     if (val < minVal) return minVal;
     if (val > maxVal) return maxVal;
     return val;
}

export function deepCopy(obj: any): any {
     return JSON.parse(JSON.stringify(obj));
}

// Because Edge doesn't support Array.prototype.flat()
export function flattenArray(arr: any) {
     return [].concat.apply([], arr);
}

export function saveFileOnUserDevice(filename: string, blob: any) {
     if (navigator.msSaveBlob) { // For ie and Edge
          return navigator.msSaveBlob(blob, filename);
     }
     else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          link.remove();
          window.URL.revokeObjectURL(link.href);
     }
}

export class UrlSafeBase64 {
     public static decode(s: string) {
          const base64 = s.split('_').join('/').split('-').join('+');
          return atob(base64);
     }
}

/* removes a SINGLE trailing forward slash */
export function removeTrailingForwardSlash(str: string): string {
     if (str.length === 0) { return str; }

     if (str.charAt(str.length - 1) === '/') {
          return str.slice(0, str.length - 1);
     }

     return str;
}

/** Type guard for filtering out null and undefined values, e.g. ['a', 'b', undefined, 'c'].filter(notEmpty) */
export function notEmpty<T>(value: T | null | undefined): value is T {
     return value !== null && value !== undefined;
}

// workaround for current RTViewer TypeScript version not including AbortSignal.timeout
type AbortSignalWithTimeout = AbortSignal & { timeout(milliseconds: number): AbortSignal }

/** Workaround for current RTViewer TypeScript version not including AbortSignal.timeout. */
export const timeoutSignal = (timeoutInMs: number) => (AbortSignal as unknown as AbortSignalWithTimeout).timeout(timeoutInMs);
