type BodyPart = "Head" | "Torso" | "WholeBody" | "Pelvis" | "Combination";

export function isBodyPart(value: string): value is BodyPart {
    return ['Head', 'Torso', 'WholeBody', 'Pelvis', 'Combination'].includes(value);
}
export function getBodyPartImageLink(bodyPart: BodyPart): string {
    switch (bodyPart) {
        case "Head":
            return "/img/head-outline.svg";
        case "Torso":
            return "/img/torso-outline.svg";
        case "WholeBody":
            return "/img/body-outline.svg";
        case "Pelvis":
            return "/img/female-pelvis-outline.svg";
        case "Combination":
            return "/img/combination-outline.svg";
        default:
            return "";
    }
}
