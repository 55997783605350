// Common state class for all viewer components. (components under components/rtviewer)
// Also contains functions used by several components.

import { Image } from '../dicom/image';
import { StructureSet, Roi, StructureSetModalMessages } from '../dicom/structure-set';
import { ViewManager } from './view-manager';
import { WindowLevel } from './window-level';
import { deepCopy } from '../util';
import { MouseToolBase } from './mouse-tools/mouse-tool-base';
import * as storage from '../local-storage';
import { Sdf, getRoiSdfResolution } from './webgl/sdf/sdf';
import { MainMenu } from '../components/rtviewer/toolbars/MainToolbar';
import { ContouringMenu } from '../components/rtviewer/toolbars/ContouringToolbar';
import { BooleanOperatorUi, SimpleBooleanOperationSelections, SimpleBooleanOperand } from '../components/rtviewer/toolbars/contouring-toolbars/BooleanOperationsToolbar';
import { mouseTools } from './mouse-tools/mouse-tools';
import * as util from '../util';
import { SdfOperations, BooleanOperator } from './webgl/sdf/boolean/sdf-operations';
import { Propagation } from './webgl/sdf/propagation/sdf-propagation';
import { Axis } from './view';
import { BoundingBox } from '../math/bounding-box';
import { UndoStack } from './undo-stack';
import { Clipboard } from './clipboard';
import { InfoMenu } from "../components/rtviewer/toolbars/InfoToolbar";
import { DatasetImage } from '../datasets/dataset-image';
import { Dataset } from '../datasets/dataset';

export class ViewerState {

    private listeners: (() => void)[];
    public viewManager: ViewManager;
    public undoStack: UndoStack;
    public clipboard: Clipboard;
    public canEdit: boolean;
    public canCreateRtstruct: boolean;
    public image: Image;
    public dataset: Dataset | null;
    public datasetImage: DatasetImage | null;
    public selectedStructureSet: StructureSet | null;
    public selectedRoi: Roi | null;
    public hoveredRoi: Roi | null;
    public copiedRois: Roi[] | null; // Rois to be copy/pasted to different structure set
    public hiddenRois: {[structureSetId: string]: Set<string>}; // structureSetId -> roiNumber
    public mainMenuSelection: MainMenu;
    public contouringMenuSelection: ContouringMenu;
    public infoMenuSelection: InfoMenu;
    public simpleBooleanOperationSelections: SimpleBooleanOperationSelections;
    public activeMouseTools: MouseToolBase[];
    public windowLevel: WindowLevel;
    public lineWidth: number;
    public lineWidthSelected: number;
    public focusWhenMouseZooming: boolean;
    public debugMode: boolean;

    // Contouring tool state
    public brushWidthMm: number;
    public erase: boolean;

    constructor(image: Image, structureSet: StructureSet | undefined, dataset: Dataset | null, datasetImage: DatasetImage | null, canEdit: boolean, canCreateRtstruct: boolean) {
        this.listeners = [];
        this.viewManager = new ViewManager(this, 100, 100, image);
        this.undoStack = new UndoStack(this);
        this.clipboard = new Clipboard();
        this.canEdit = canEdit;
        this.canCreateRtstruct = canCreateRtstruct;
        this.image = image;
        this.dataset = dataset;
        this.datasetImage = datasetImage;
        this.selectedStructureSet = null;
        this.selectedRoi = null;
        this.hoveredRoi = null;
        this.copiedRois = null;
        this.hiddenRois = {};
        this.mainMenuSelection = MainMenu.Select;
        this.contouringMenuSelection = ContouringMenu.Line;
        this.infoMenuSelection = InfoMenu.MeasureLength
        this.simpleBooleanOperationSelections = new SimpleBooleanOperationSelections();
        this.activeMouseTools = [mouseTools.pan, mouseTools.select];
        this.windowLevel = deepCopy(image.defaultWindowLevel);
        this.debugMode = false;

        let lineWidthStr = localStorage.getItem(storage.LineWidth);
        this.lineWidth = lineWidthStr ? parseFloat(lineWidthStr) : 0.8;

        let lineWidthSelStr = localStorage.getItem(storage.LineWidthSelected);
        this.lineWidthSelected = lineWidthSelStr ? parseFloat(lineWidthSelStr) : 1.2;

        let focusWhenMouseZooming = localStorage.getItem(storage.FocusWhenMouseZooming);
        this.focusWhenMouseZooming = focusWhenMouseZooming ? focusWhenMouseZooming === "true" : false;

        this.brushWidthMm = 10;
        this.erase = false;
        
        if(structureSet) {
            this.setSelectedStructureSet(structureSet, this.image);
        }
    }

    public addListener(f: () => void) {
        this.listeners.push(f);
    }

    public removeListener(f: () => void) {
        this.listeners = this.listeners.filter(ff => ff !== f);
    }

    public notifyListeners() {
        this.listeners.forEach(f => f());
    }

    public setSelectedStructureSet(ss: StructureSet | null, img: any | null) {
        if (ss) ss.toIM(img);
        if(this.selectedRoi) this.propagateIfNeeded(this.selectedRoi);
        if(ss === this.selectedStructureSet) return;
        let prevSelectedRoi = this.selectedRoi;
        if(ss && ss.deleted) return; // Delete context menu also creates a click event for the structureset, ignore.
        this.setSelectedRoi(null);
        this.selectedStructureSet = ss;
        if(ss && prevSelectedRoi) {
            let roiNumbers = Object.keys(ss.rois);
            for(let i = 0; i < roiNumbers.length; ++i){
                let roi = ss.rois[roiNumbers[i]];
                if(roi && roi.name === prevSelectedRoi.name) {
                    this.setSelectedRoi(roi);
                }
            }
        }

        if(ss) this.generateSdfsIfNeeded(ss);
        this.notifyListeners();
        this.sizeChanged();

        if(this.mainMenuSelection === MainMenu.Contouring && ss && !ss.canEdit()) {
            this.setMainMenuSelection(MainMenu.Select);
        }
    }

    public async generateSdfsIfNeeded(ss: StructureSet) {
        let vm = this.viewManager;
        const img = vm.image;
        const rois = ss.getRois().filter(roi => !roi.sdf);
        if(!rois.length) return;

        ss.modalMessage = StructureSetModalMessages.LoadingStructures;
        this.notifyListeners();
        await util.sleep(2);
        
        for(let i = 0; i < rois.length; ++i) {

            const roi = rois[i];

            try{
                const resolution = getRoiSdfResolution(img, roi);
                const roiContours = ss.contourData[roi.roiNumber];
                if(!roiContours) continue;
                let bb = new BoundingBox();
                Object.keys(roiContours).forEach(sliceId => {
                    let sliceContours = roiContours[sliceId];
                    if(sliceContours.polygons) {
                        sliceContours.polygons.forEach(polygon => {
                            polygon.forEach(point => {
                                bb.expandWithPoint(point[0], point[1], point[2]);
                            })
                        })
                    }
                });
                if(bb.getXSize() === 0 || bb.getYSize() === 0) continue;
                
                let sdf = new Sdf(vm, resolution);
                sdf.createTexture(bb, true, false);

                sdf.addContours(roiContours);
                roi.sdf = sdf;
            }
            catch(error) {
                console.log("Error in sdf generation!", error);
            }

            if(this.activeMouseTools.includes(mouseTools.brush) && mouseTools.brush.roi === roi){
                mouseTools.brush.createDrawBuffer();
            }
            
            this.notifyListeners();
            await util.sleep(2); // Stop this blocking thread for a while and let ui show newly created contours
        }

        ss.modalMessage = null;
        this.notifyListeners();
    }


// Works with patient "Mirada_Prostate1"
private createDummyBooleanStructures(ss: StructureSet) {
    let vm = this.viewManager;

    let bladder = ss.rois['1'];
    let body = ss.rois['0'];
    let ctv = ss.rois['2'];
    let LN = ss.rois['6'];

    if(!bladder || !bladder.sdf || !body || !body.sdf || !ctv || !ctv.sdf || !LN || !LN.sdf) return;

    let sdfOps = new SdfOperations(vm);

    let roiOr = ss.duplicateRoi(vm, bladder);
    roiOr.name = "Bladder OR LN";
    roiOr.sdf = sdfOps.applyBoolean(bladder.sdf, LN.sdf, BooleanOperator.OR, false);

    let roiAnd = ss.duplicateRoi(vm, LN);
    roiAnd.name = "LN AND CTV";
    roiAnd.sdf = sdfOps.applyBoolean(LN.sdf, ctv.sdf, BooleanOperator.AND, false);

    let roiAndNot = ss.duplicateRoi(vm, LN);
    roiAndNot.name = "LN AND NOT CTV";
    roiAndNot.sdf = sdfOps.applyBoolean(LN.sdf, ctv.sdf, BooleanOperator.AND_NOT, false);

    let roiXor = ss.duplicateRoi(vm, LN);
    roiXor.name = "LN XOR CTV";
    roiXor.sdf = sdfOps.applyBoolean(LN.sdf, ctv.sdf, BooleanOperator.XOR, false);

    let roi3 = ss.duplicateRoi(vm, bladder);
    roi3.name = "BLADDER COPY";
    roi3.sdf = sdfOps.copy(bladder.sdf, bladder.sdf.boundingBox, false);



    // operations with body are inaccurate due to different sdf resolution

    
    // let roi1 = ss.duplicateRoi(roiBody);
    // roi1.name = "BODY AND NOT CTV";
    // ssSdf.roiSdfs[roi1.roiNumber] = sdfOps.applyBoolean(sdfBody, sdfCtv, BooleanOperator.AND_NOT, roi1);

    // let roi2 = ss.duplicateRoi(roiCtv);
    // roi2.name = "CTV XOR BODY";
    // ssSdf.roiSdfs[roi2.roiNumber] = sdfOps.applyBoolean(sdfCtv, sdfBody, BooleanOperator.XOR, roi2);
    // console.log("body size: " + sdfBody.size)
    // console.log("xor size: " + ssSdf.roiSdfs[roi2.roiNumber].size)

    // let roi4 = ss.duplicateRoi(roiBody);
    // roi4.name = "BODY XOR CTV";
    // ssSdf.roiSdfs[roi4.roiNumber] = sdfOps.applyBoolean(sdfBody, sdfCtv, BooleanOperator.XOR, roi4);

    this.notifyListeners();
}


    // Todo: should be polygon-based when we have the round trip??
    public findPointedRoi(ptMm: number[]): Roi | null {
        let ss = this.selectedStructureSet as StructureSet;
        if(!ss) return null;

        let result: Roi | null = null;
        let minDist = 9999;
        let tresholdMm = 15;
        ss.getRois().forEach(roi => {
            let dist = 9999;
            if( this.hiddenRois[ss.structureSetId] && this.hiddenRois[ss.structureSetId].has(roi.roiNumber)) return;
            if( roi.sdf ) {
                dist = roi.sdf.distanceToContour(ptMm);
            }
            else { // Check for markers
                if( ss.contourData[roi.roiNumber] ) {
                    const sliceId = this.image.sliceIds[this.viewManager.getScrolledSlice(Axis.Z)];
                    if(ss.contourData[roi.roiNumber] && ss.contourData[roi.roiNumber][sliceId]){
                        const points = ss.contourData[roi.roiNumber][sliceId].points;
                        if(points && points.length) {
                            const p = points[0];
                            dist = Math.sqrt(Math.pow((p[0] - ptMm[0]), 2) + (Math.pow(p[1] - ptMm[1], 2)) + (Math.pow(p[2] - ptMm[2], 2)));
                        }
                    }
                    
                }
            }
            if(dist < tresholdMm && (!result || dist < minDist) ) {
                result = roi;
                minDist = dist;
            }
        });
        return result;
    }

    public setSelectedRoi(roi: Roi | null) {
        if(roi) this.setSelectedStructureSet(roi.structureSet, null);
        if(this.selectedRoi) this.propagateIfNeeded(this.selectedRoi);
        if(this.selectedRoi !== roi) {
            if(roi && !roi.structureSet.getRois().includes(roi)){
                // Roi is being deleted, this gets called after delete context menu click
                return;
            }
            this.selectedRoi = roi;
            this.activeMouseTools.forEach(t => t.handleRoiSelected(roi));
            this.notifyListeners();
        }
    }

    public setHoveredRoi(roi: Roi | null) {
        if(this.hoveredRoi !== roi) {
            this.hoveredRoi = roi;
            this.notifyListeners();
        }
    }

    public setCopiedRois(roi: Roi[] | null) {
        this.copiedRois = roi;

        this.notifyListeners();
    }

    public setRoiHidden(roi: Roi, hidden: boolean) {
        const ss = roi.structureSet;
        this.hiddenRois[ss.structureSetId] = this.hiddenRois[ss.structureSetId] || new Set<string>();
        if(hidden) 
            this.hiddenRois[ss.structureSetId].add(roi.roiNumber); 
        else 
            this.hiddenRois[ss.structureSetId].delete(roi.roiNumber);
        this.notifyListeners();
    } 

    public setAllRoisHidden(hidden: boolean) {
        let ss = this.selectedStructureSet as StructureSet;
        if(!ss) return;
        this.hiddenRois[ss.structureSetId] = this.hiddenRois[ss.structureSetId] || new Set<string>();
        if(hidden)
            ss.getRois().forEach(roi => this.hiddenRois[ss.structureSetId].add(roi.roiNumber));
        else 
            this.hiddenRois[ss.structureSetId] = new Set<string>();
        this.notifyListeners();
    }

    public getAreAllRoisVisible() {
        let ss = this.selectedStructureSet as StructureSet;
        if (!ss) { return false; }
        if (!this.hiddenRois[ss.structureSetId]) { return true; }
        return ss.getRois().every(r => !this.hiddenRois[ss.structureSetId].has(r.roiNumber));
    }

    public setMainMenuSelection(menu: MainMenu) {
        switch(menu) {
            case MainMenu.Contouring:
                this.setContouringMenuSelection(this.contouringMenuSelection);
                break;
            case MainMenu.Info:
                this.setInfoMenuSelection(this.infoMenuSelection);
                break;
            case MainMenu.Preferences:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                break;
            case MainMenu.Select:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                break;
            case MainMenu.WindowLevel:
                this.setActiveMouseTools([mouseTools.windowLevel, mouseTools.select]);
                break;
            default:
        }
        this.mainMenuSelection = menu;
        this.notifyListeners();
        this.sizeChanged();
    }

    public setContouringMenuSelection(menu: ContouringMenu) {
        switch(menu) {
            case ContouringMenu.Line:
                this.setActiveMouseTools([mouseTools.lineDraw]);
                break;
            case ContouringMenu.Brush:
                this.setActiveMouseTools([mouseTools.brush]);
                break;
            case ContouringMenu.Boolean:
                this.setActiveMouseTools([mouseTools.pan, mouseTools.select]);
                this.simpleBooleanOperationSelections = new SimpleBooleanOperationSelections();
                break;
            case ContouringMenu.Crop:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.BorderMove:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Deform:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Margin:
                this.setActiveMouseTools([]);
                break;
            case ContouringMenu.Smoothing:
                this.setActiveMouseTools([]);
                break;
            default:
        }

        this.contouringMenuSelection = menu;
        this.notifyListeners();
        this.sizeChanged();
    }

    public setInfoMenuSelection(menu: InfoMenu) {
      switch (menu) {
        case InfoMenu.MeasureLength:
          this.setActiveMouseTools([mouseTools.measureLength]);
          break;
        case InfoMenu.IntensityProfile:
          this.setActiveMouseTools([mouseTools.intensityProfile]);
          break;
        default:
      }

      this.infoMenuSelection = menu;
      this.notifyListeners();
      this.sizeChanged();
    }


    public setSimpleBooleanOperator(operator: BooleanOperatorUi) {
        this.simpleBooleanOperationSelections.setOperator(operator);
        this.notifyListeners();
    }

    public setSimpleBooleanOperand(operandIndex: SimpleBooleanOperand, roi: Roi | null) {
        this.simpleBooleanOperationSelections.setOperand(operandIndex, roi);
        this.notifyListeners();
    }

    public setActiveMouseTools(tools: MouseToolBase[]) {
        let toolsToDeactivate = this.activeMouseTools.filter(t => !tools.includes(t));
        let toolsToActivate = tools.filter(t => !this.activeMouseTools.includes(t));
        toolsToDeactivate.forEach(t => t.handleDeactivate());
        toolsToActivate.forEach(t => t.handleActivate(this.viewManager));
        this.activeMouseTools = tools;
        if(this.selectedRoi) this.propagateIfNeeded(this.selectedRoi);
    }

    public setWindowLevel(windowLevel: WindowLevel) {
        this.windowLevel = windowLevel;
        this.notifyListeners();
    }

    public resetWindowLevel() {
        this.windowLevel = deepCopy(this.image.defaultWindowLevel);
        this.notifyListeners();
    }

    public setLineWidth(w: number) {
        w = Math.min(w, 10);
        this.lineWidth = w;
        localStorage.setItem(storage.LineWidth, w.toString());
        this.notifyListeners();
    }

    public setLineWidthSelected(w: number) {
        w = Math.min(w, 10);
        this.lineWidthSelected = w;
        localStorage.setItem(storage.LineWidthSelected, w.toString());
        this.notifyListeners();
    }

    public setBrushWidth(w: number) {
        this.brushWidthMm = util.clamp(w, 1, 50);
        this.notifyListeners();
    }

    public setFocusWhenMouseZooming(b: boolean) {
        localStorage.setItem(storage.FocusWhenMouseZooming, b ? "true" : "false");
        this.focusWhenMouseZooming = b;
        this.notifyListeners();
    }

    public setErase(erase: boolean) {
        this.erase = erase;
        this.notifyListeners();
    }

    public roisChanged(ss: StructureSet) {
        ss.unsaved = true;
        this.notifyListeners();
    }

    public contoursChanged(ss: StructureSet) {
        ss.unsaved = true;
        this.notifyListeners();
    }

    public sizeChanged() {
        // Trigger window.resize so that the view grid knows to update its dimensions.
        setTimeout(function(){ window.dispatchEvent(new Event('resize')); }, 50);
    }

    public setDebugMode(debug: boolean) {
        this.debugMode = debug;
        this.notifyListeners();
    }

    // TODO: Maybe remove this and only propagate when needed (before interpolation etc.)
    private propagateIfNeeded(roi: Roi) {
        const pg = new Propagation(this.viewManager);
        setTimeout(function(){ 
            if(roi && roi.sdf && roi.sdf.propagationPending) {
                pg.propagate(roi);
            }
         }, 100);
    }
}