import { Configuration, PublicClientApplication, BrowserCacheLocation, AuthenticationResult, SilentRequest, CacheLookupPolicy } from "@azure/msal-browser";
import _ from "lodash";
import { MVISION_AUTHORITY, getSessionId, getAppName, DISPLAY_VERSION } from "../environments";
import { AppVersionInfo } from "../store/app-version-info";
import { sleep, timeoutSignal } from "../util";
import { getLatestActiveMsalAccount, setLatestActiveMsalAccount } from "../local-storage";

export const QUERY_PARAM_SESSION_ID = 'sessionId';

const VERBOSE_LOGGING = true;

const LOGIN_REDIRECT_URI = window.location.origin;
const LOGOUT_REDIRECT_URI = window.location.origin + '/logged-out';

/** Details of a user after they have authenticated through MSAL. */
export type LoggedInMsalUser = {
    userId: string;
    name?: string;
    email?: string;
};

export class BackendFetchOptions {
    /** Allow caching of request response. Default: false. */
    allowCache: boolean;

    /** Return quickFetch response as text instead of json. Default: false. Only applicable to quickFetch. */
    asText: boolean;

    /** Don't append session ID to URL query parameters. Default: false. */
    noSessionId: boolean;

    /** The time to wait during a fetch before timing it out. Using undefined uses browser default. Default: undefined. */
    fetchTimeoutInMs: number | undefined;

    /** Maximum number of retries to perform if a call fails. */
    maxRetries: number;

    /** The time in milliseconds to wait before a retry. */
    retryWaitInMs: number;

    constructor(values: Partial<BackendFetchOptions>) {
        this.allowCache = _.get(values, 'allowCache', false);
        this.asText = _.get(values, 'asText', false);
        this.noSessionId = _.get(values, 'noSessionId', false);
        this.fetchTimeoutInMs = _.get(values, 'fetchTimeoutInMs', undefined);
        this.maxRetries = _.get(values, 'maxRetries', 20);
        this.retryWaitInMs = _.get(values, 'retryWaitInMs', 10 * 1000); // 10 seconds
    }
}

// this class models authentication to an azure app registration
export default class AppAuth {

    // name of the app registration
    appName: string;

    // client ID matching the azure app registration
    clientId: string;

    config: Configuration;

    // the set of scopes that we ask for when requesting tokens
    request: any;

    msalInstance: PublicClientApplication | null;

    // true if user has logged into this app registration, false otherwise
    isLoggedIn: boolean;

    /** The version of the app that this app auth is sending requests for. */
    appVersion: AppVersionInfo | undefined;

    constructor(appName: string, clientId: string) {
        this.appName = appName;
        this.clientId = clientId;

        this.isLoggedIn = false;
        this.appVersion = undefined;

        this.request = {
            scopes: [`${clientId}/.default`],
        };

        this.config = {
            auth: {
                clientId: this.clientId,
                authority: MVISION_AUTHORITY,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
            },
            // system: {
            //         // uncomment this if needed
            //         loggerOptions: {
            //             loggerCallback: (logLevel: LogLevel, message: string, containsPii: boolean) => console.log(`${this.appName}: ${message}`),
            //             logLevel: LogLevel.Info,
            //             // change this to true if needed -- don't commit or deploy into production
            //             piiLoggingEnabled: false,
            //         },
            //     // tokenRenewalOffsetSeconds: 60 * 60
            // },
        };

        this.msalInstance = null;
    }

    setAppVersion(appVersion: AppVersionInfo) {
        this.appVersion = appVersion;
    }

    private verboseLog(message: string) {
        if (VERBOSE_LOGGING) { console.log(message); }
    }

    private setLatestActiveAccount() {
        if (this.msalInstance) {
            const account = this.msalInstance.getActiveAccount();
            if (account) {
                this.verboseLog('Setting latest active MSAL account');
                setLatestActiveMsalAccount(account.homeAccountId);
                return;
            }
        }

        throw new Error('Could not set latest MSAL account');
    }

    /** Log in using REDIRECT flow. */
    async logIn() {
        if (this.msalInstance === null) { this.msalInstance = new PublicClientApplication(this.config); }

        // we need to handle redirect promise here for redirect log-in and log-out processes
        await this.msalInstance.handleRedirectPromise();

        if (!this.isLoggedIn) {

            /** Try getting an access token to check that we're ACTUALLY logged in */
            const tryGettingAccessTokenAndSetLoggedIn = async () => {
                this.verboseLog('Getting an access token with cached credentials');
                await this.getAccessToken();
                try {
                    this.setLatestActiveAccount();
                }
                catch (err) {
                    // reset cache if we have localStorage auth trash left from a previous version
                    if ((_.get(err, 'errorCode') as string).includes('multiple_matching_tokens')) {
                        await this.getAccessToken(true);
                    } else {
                        throw err;
                    }
                }

                this.isLoggedIn = true;
            };

            // First see if we already have exactly one set of cached credentials
            const accounts = this.msalInstance.getAllAccounts();
            if (accounts.length === 1) {
                this.verboseLog('Already logged in, using cached credentials');
                this.msalInstance.setActiveAccount(accounts[0]);

                try {
                    await tryGettingAccessTokenAndSetLoggedIn();
                    return;
                } catch (err) {
                    this.verboseLog('Did not get access token with cached credentials');
                    console.error(err);
                }
            }

            // If not, try to see if we already have an active account
            const latestAccount = getLatestActiveMsalAccount();
            if (latestAccount) {
                this.verboseLog('Found latest active MSAL account entry -- checking if that\'s still logged in');

                try {
                    const account = this.msalInstance.getAccountByHomeId(latestAccount);
                    if (account) {
                        this.verboseLog('Trying to use an existing active account');
                        this.msalInstance.setActiveAccount(account);
                        await tryGettingAccessTokenAndSetLoggedIn();
                        return;
                    }
                }
                catch (err) {
                    this.verboseLog('Did not get access token with existing active account');
                    console.log(err);
                }
            }

            // Fall back to regular redirect auth login flow
            try {
                // this will (on success) always redirect user away from current page and stop
                // any of the rest of the code from being run
                this.verboseLog('Falling back to regular redirect auth login');
                await this.msalInstance.loginRedirect({
                    scopes: this.request.scopes,
                    prompt: 'select_account',
                    redirectUri: LOGIN_REDIRECT_URI,
                });
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }

        this.isLoggedIn = true;
    }

    /** Log out using REDIRECT flow. */
    async logOut() {
        if (this.msalInstance && this.isLoggedIn) {
            // No user signed in
            try {
                // this will (on success) always redirect user away from current page and stop
                // any of the rest of the code from being run
                await this.msalInstance.logoutRedirect({
                    postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
                });
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        }

        this.isLoggedIn = false;
    }

    private async getAccessToken(forceRefresh: boolean = false): Promise<string> {
        if (this.msalInstance === null) {
            throw new Error(`No MSAL instance for ${this.appName} -- log in before trying to get an access token!`);
        }

        // throw if an active account has not been set
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw new Error('No active account has been set');
        }

        let response: AuthenticationResult | undefined = undefined;
        let interactionRequired = false;

        // try to get the token silently, but fall back to redirect flow if it fails
        //
        // NOTE/TODO: asynchronous parallel operations (such as sending dicoms for auto-contouring)
        // msal has to fall back to acquireTokenRedirect as this will essentially reset the current
        // page during the redirect re-login. This may also cause loss of unsaved data user
        // has in their current session should this happen during user interaction. For the latter there's no real
        // fix possible -- if these turn out to be actual problem then the code must be changed so
        // that current work-in-progress operations are stored in browser memory and can be continued
        // after a login redirect has completed.
        //
        // see also: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
        try {
            const forceRefreshConfig: Partial<SilentRequest> = forceRefresh ? { cacheLookupPolicy: CacheLookupPolicy.Skip } : {};
            response = await this.msalInstance.acquireTokenSilent({ ...this.request, account, ...forceRefreshConfig });
            if (!response) {
                interactionRequired = true;
            } else {
                this.verboseLog('Got an access token silently');
            }
        } catch (err) {
            console.error(err);
            interactionRequired = true;
        }

        if (interactionRequired) {
            // this will (on success) always redirect user away from current page and stop
            // any of the rest of the code from being run -- this could in theory result
            // in loss of unsaved data if this happens in the middle of user interacting
            // with the application
            this.verboseLog('Getting token silently failed -- trying through redirect');
            await this.msalInstance.acquireTokenRedirect({ ...this.request, redirectUri: LOGIN_REDIRECT_URI });
        }

        if (!response) {
            throw new Error('Could not get access token');
        }

        return response.accessToken as string;
    }

    getLoggedInUserDetails(): LoggedInMsalUser | undefined {
        if (!this.msalInstance) { return undefined; }

        const account = this.msalInstance.getActiveAccount();
        if (!account) { return undefined; }

        return {
            userId: account.localAccountId,
            email: account.username,
            // name: account.name
            name: account.idTokenClaims ? account.idTokenClaims.preferred_username ? account.idTokenClaims.preferred_username : account.name : account.name
        };
    }

    async fetch(url: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {

        const backendOptions = new BackendFetchOptions(backendFetchOptions);

        if (!this.isLoggedIn) {
            throw new Error(`Must be logged into ${this.appName} before calling any MSAL APIs!`);
        }

        const token = await this.getAccessToken();

        const fetchOptions = httpRequestOptions || {};
        const bearer = `Bearer ${token}`;
        _.set(fetchOptions, 'headers.Authorization', bearer);

        if (!backendOptions.allowCache) {
            _.set(fetchOptions, 'headers.Cache-Control', 'no-store');
            _.set(fetchOptions, 'cache', 'no-store');
            _.set(fetchOptions, 'headers.pragma', 'no-cache');
        }

        if (backendOptions.fetchTimeoutInMs !== undefined) {
            _.set(fetchOptions, 'signal', timeoutSignal(backendOptions.fetchTimeoutInMs));
        }

        // set app version so backend knows which app and which version of it is sending the request
        _.set(fetchOptions, 'headers.appVersion', `${getAppName()}/${DISPLAY_VERSION}/${this.appVersion ? this.appVersion.commit : 'N/A'}`);

        const fullUrl = new URL(url);

        if (!backendOptions.noSessionId) {
            // append session ID to query parameters
            const sessionIdQueryParam = `${QUERY_PARAM_SESSION_ID}=${getSessionId()}`;
            fullUrl.search = fullUrl.search ? `${fullUrl.search}&${sessionIdQueryParam}` : sessionIdQueryParam;
        }

        let currentRetry = 0;
        while (true) {
            try {
                return await fetch(fullUrl.toString(), fetchOptions);
            } catch (err) {
                console.log(`An error occurred when trying to fetch from ${url}`);
                console.log('Fetch options:');
                console.log(fetchOptions);
                console.log('Fetch error:');
                console.log(err);

                if (backendOptions.maxRetries > currentRetry) {
                    await sleep(backendOptions.retryWaitInMs);
                    currentRetry++;
                    console.warn(`Attempting retry ${currentRetry}/${backendOptions.maxRetries} to ${url}`)
                } else {
                    if (fetchOptions && _.get(fetchOptions, 'signal.reason.name', undefined) === 'TimeoutError') {
                        throw new Error('Request timed out.');
                    }

                    throw err;
                }
            }
        }
    }
}
