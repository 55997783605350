// Toolbar for boolean operations

import React from 'react';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';

import { ViewerState } from '../../../../rtviewer-core/viewer-state';
import RoiSelect, { RoiOption } from './RoiSelect';
import { Roi } from '../../../../dicom/structure-set';
import { roiCompare } from '../../../../helpers/compare';
import { BooleanOperator, SdfOperations } from '../../../../rtviewer-core/webgl/sdf/boolean/sdf-operations';

import './BooleanOperationsToolbar.css';
import { Propagation } from '../../../../rtviewer-core/webgl/sdf/propagation/sdf-propagation';

type OwnProps = {
  viewerState: ViewerState,
}

type DispatchProps = {
}

type AllProps = OwnProps & DispatchProps;

type OwnState = {
  refreshSwitch?: any,
}

export enum BooleanOperatorUi {
  NoSelection, And, Or, AndNot, Xor
}

export enum SimpleBooleanOperand {
  First, Second, Target
}

export class SimpleBooleanOperationSelections {
  public firstRoi: Roi | null;
  public secondRoi: Roi | null;
  public targetRoi: Roi | null;
  public operator: BooleanOperatorUi;

  constructor() {
    this.firstRoi = null;
    this.secondRoi = null;
    this.targetRoi = null;
    this.operator = BooleanOperatorUi.NoSelection;
  }

  public setOperator(operator: BooleanOperatorUi) {
    this.operator = operator;
  }

  public setOperand(operand: SimpleBooleanOperand, roi: Roi | null) {
    switch (operand) {
      case SimpleBooleanOperand.First:
        this.firstRoi = roi;
        break;
      case SimpleBooleanOperand.Second:
        this.secondRoi = roi;
        break;
      case SimpleBooleanOperand.Target:
        this.targetRoi = roi;
        break;
      default:
    }
  }
}

class BooleanOperationsToolbar extends React.Component<AllProps, OwnState> {

  constructor(props: AllProps) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.viewerState.addListener(this.updateView);
  }

  componentWillUnmount() {
    this.props.viewerState.removeListener(this.updateView);
  }

  updateView = () => {
    this.setState({ refreshSwitch: !this.state.refreshSwitch });
  }

  getRoiFromOption = (option: RoiOption) => {
    const vs = this.props.viewerState;
    const selectedStructureSet = vs.selectedStructureSet;

    if (selectedStructureSet && selectedStructureSet.rois && option) {
      return selectedStructureSet.rois[option.value];
    }

    return null;
  }

  firstRoiHandleChange = (option: RoiOption) => {
    this.setOperandForSimpleBooleanOperation(option, SimpleBooleanOperand.First);
  }

  secondRoiHandleChange = (option: RoiOption) => {
    this.setOperandForSimpleBooleanOperation(option, SimpleBooleanOperand.Second);
  }

  targetRoiHandleChange = (option: RoiOption) => {
    this.setOperandForSimpleBooleanOperation(option, SimpleBooleanOperand.Target);
  }

  setOperandForSimpleBooleanOperation = (option: RoiOption, operandIndex: SimpleBooleanOperand) => {
    const vs = this.props.viewerState;
    const roi = this.getRoiFromOption(option);
    vs.setSimpleBooleanOperand(operandIndex, roi);
  }

  handleApplyOperationClick = () => {
    const vs = this.props.viewerState;
    const vm = vs.viewManager;
    const ss = vs.selectedStructureSet;
    if(!ss) return;
    const boolState = vs.simpleBooleanOperationSelections;

    if(boolState.operator === BooleanOperatorUi.NoSelection) {
      alert("Select boolean operator!");
    }
    else if (!boolState.firstRoi || !boolState.secondRoi || !boolState.targetRoi) {
      alert("Select first, second and target structures!");
    }
    else if(!boolState.firstRoi.sdf) {
      alert(boolState.firstRoi.name + " does not have contours.");
    }
    else if(!boolState.secondRoi.sdf) {
      alert(boolState.secondRoi.name + " does not have contours.");
    }
    else {
      const operatorMapping = { 
        [BooleanOperatorUi.And] : BooleanOperator.AND,
        [BooleanOperatorUi.AndNot]: BooleanOperator.AND_NOT,
        [BooleanOperatorUi.Or]: BooleanOperator.OR,
        [BooleanOperatorUi.Xor]: BooleanOperator.XOR,
      }
      const operator = operatorMapping[boolState.operator];
      const sdfOps = new SdfOperations(vm);
      if(boolState.targetRoi.sdf) vs.undoStack.pushRoiStateBeforeEdit(boolState.targetRoi);
      boolState.targetRoi.sdf = sdfOps.applyBoolean(boolState.firstRoi.sdf, boolState.secondRoi.sdf, operator, false);
      new Propagation(vm).propagate(boolState.targetRoi);
      ss.unsaved = true;
      boolState.targetRoi.setContoursChanged(null);
      vs.notifyListeners();
    }
    
    console.log(`Selected boolean operation:\nFirst structure: ${boolState.firstRoi ? boolState.firstRoi.name : 'null'}\nOperator: ${BooleanOperatorUi[boolState.operator]}\nSecond structure: ${boolState.secondRoi ? boolState.secondRoi.name : 'null'}\nTarget structure: ${boolState.targetRoi ? boolState.targetRoi.name : 'null'}\n`);
  }

  render() {
    const vs = this.props.viewerState;
    const selectedStructureSet = vs.selectedStructureSet;

    // sort ROIs and convert them into options for the RoiSelect dropdown
    if (!selectedStructureSet) {
      return null;
    }
    const rois = selectedStructureSet.rois;
    const sortedRois = Object.keys(rois).map(roiNr => rois[roiNr]).sort(roiCompare);
    const options = sortedRois.map(r => new RoiOption(r.name, r.roiNumber));

    return (
      <div className="boolean-operations-toolbar">
        <RoiSelect label="First structure" options={options} onChange={this.firstRoiHandleChange} />

        <label>
          <span onClick={(e) => { e.preventDefault(); }}>Boolean operator:</span>
          <ToggleButtonGroup className="mr-2" type="radio" name="booleanOperationOptions"
            value={vs.simpleBooleanOperationSelections.operator} onChange={(val: BooleanOperatorUi) => vs.setSimpleBooleanOperator(val)}>
            <ToggleButton  className="btn btn-default btn-sm" value={BooleanOperatorUi.And}>AND</ToggleButton>
            <ToggleButton  className="btn btn-default btn-sm" value={BooleanOperatorUi.Or}>OR</ToggleButton>
            <ToggleButton  className="btn btn-default btn-sm" value={BooleanOperatorUi.AndNot}>AND NOT</ToggleButton>
            <ToggleButton  className="btn btn-default btn-sm" value={BooleanOperatorUi.Xor}>XOR</ToggleButton>
          </ToggleButtonGroup>
        </label>

        <RoiSelect label="Second structure" options={options} onChange={this.secondRoiHandleChange} />
        <RoiSelect label="Target structure" options={options} onChange={this.targetRoiHandleChange} />

        <Button  className="btn btn-default btn-sm" onClick={this.handleApplyOperationClick}>Apply operation</Button>
      </div>
    );
  }
}

export default BooleanOperationsToolbar;